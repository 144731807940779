.invitationLanding {
    font-size: 14px;
    background: #f5f4ef;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;

    &__mainWrap {
        width: 448px;
        max-width: 100%;
        background: #fff;
        padding: 60px 60px 40px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01), 0px 5px 8px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        max-height: 400px;
        height: 100vh;
    }

    &__logo {
        margin-bottom: 50px;
    }

    &__message {
        line-height: 1.7em;
        white-space: pre;
    }
}
