.specialFeature {
    &__mainWrap {
        width: 664px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
        margin-top: 8px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
    }

    &__mainTitle-num {
        display: inline-block;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.02em;
        margin-left: 15px;
    }

    &__mainTitle-unit {
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__list {
        gap: 8px;
        display: flex;
        flex-direction: column;
    }

    &__item {
        color: #000;
        background: #fff;
        padding: 16px 24px;
        gap: 20px;
        display: flex;
        border-radius: 8px;
        text-decoration: none;
        transition-duration: 0.3s;
        &-ttl {
            font-size: 16px !important;
            font-weight: 700 !important;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
    }

    &__item:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
    }

    &__item-txtWrap {
        gap: 12px;
        display: flex;
        flex-direction: column;
    }

    &__item-detail {
        font-size: 14px;
        line-height: 20px;
        color: #8a8883;
    }

    &__item-status {
        font-size: 14px;
        gap: 12px;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 50%;
            box-sizing: border-box;
        }

        &.publish::before {
            background: #52d276;
        }

        &.draft::before {
            background: #fff;
            border: 1.5px solid #8a8883;
        }

        &.reserve::before {
            background: #ddce47;
        }
    }

    &__item-imgWrap {
        flex-shrink: 0;
    }
}
