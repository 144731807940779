.question {
    background: #f5f4ef;
    padding: 24px 0;

    &__inner {
        width: 824px;
        max-width: 100%;
        margin: 0 auto;
        background: #fff;
        border-radius: 16px;
    }

    &__header {
        width: 100%;
        padding: 12px 5px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__headerUl {
        width: 100%;
        display: flex;
        list-style: none;
        position: relative;
    }

    &__headerLi {
        margin-right: 12px;
    }

    &__headerLink {
        display: block;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 1;
        padding: 0 24px;
        box-sizing: border-box;
        letter-spacing: 0.011em;
        border-radius: 20px;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;

            .question__headerLink-num {
                color: #fff;
            }
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;

            .question__headerLink-num {
                color: #fff;
            }
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            .question__headerLink-num {
                color: #fff;
                background: #000;
            }
        }

        &:active {
            color: #8a8883;
            text-decoration: none;

            .question__headerLink-num {
                color: #fff;
            }
        }

        &-num {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #8a8883;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #fff;
            line-height: 14px;
            letter-spacing: 0.011em;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            &:link {
                color: #000;
                text-decoration: none;

                .question__headerLink-num {
                    color: #fff;
                    background: #000;
                }
            }

            &:visited {
                color: #000;
                text-decoration: none;

                .question__headerLink-num {
                    color: #fff;
                    background: #000;
                }
            }

            &:hover {
                color: #000;
                background: #ffcc1d;
                text-decoration: none;

                .question__headerLink-num {
                    color: #fff;
                    background: #000;
                }
            }

            &:active {
                color: #000;
                text-decoration: none;

                .question__headerLink-num {
                    color: #fff;
                    background: #000;
                }
            }
        }
    }

    &__cont {
        width: 100%;
        padding: 0 24px;
        box-sizing: border-box;
    }

    &__titleArea {
        margin: 24px 0 32px 0;
    }

    &__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
        display: flex;

        &-num {
            display: inline-block;
            margin-left: 16px;
        }
    }

    &__contInner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__contSide {
        width: 156px;
        max-width: 20%;
    }

    &__contSideInner {
        width: 100%;
        position: sticky;
        top: 82px;
        left: 0;
    }

    &__contMain {
        width: 588px;
        max-width: calc(80% - 24px);
        padding-bottom: 24px;
    }

    &__mainUl {
        list-style: none;
    }

    &__mainLi {
        margin-top: 24px;

        &-mgt0 {
            margin-top: 0;
        }
    }

    &__mainLiBox {
        width: 100%;
        background: #f8f7f5;
        border-radius: 8px;
        padding: 16px;
        box-sizing: border-box;
    }

    &__mainLiHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__mainLiTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 0 12px;
        background: #ffcc1d;
        border-radius: 12px;
    }

    &__mainLiDate {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainLiTextArea {
        width: 100%;
        margin-top: 8px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e8e8e8;
    }

    &__mainLiText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainLiToJob {
        padding: 12px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__mainLiToJobFin {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 4px 8px;
        background: #f0f0f0;
        border-radius: 4px;
        display: flex;
        align-items: center;
    }

    &__mainLiToJobFinIcon {
        margin-right: 8px;
    }

    &__mainLiToJobLink {
        transition-duration: 0.2s;
        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #d7a600;
            text-decoration: none;
            opacity: 0.7;
            .question__mainLiToJobName,
            .question__mainLiToJobComp {
                opacity: 0.7;
            }
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }

    &__mainLiToJobTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        transition-duration: 0.2s;
    }

    &__mainLiToJobName {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        transition-duration: 0.2s;
    }

    &__mainLiToJobComp {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 16px;
        letter-spacing: 0.017em;
        transition-duration: 0.2s;
    }

    &__mainLiReplyArea {
        width: 100%;
        padding: 12px 0 8px 0;
    }

    &__mainLiReply {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;

        &-link {
            color: #d7a600;

            &:link {
                color: #d7a600;
                text-decoration: none;
            }

            &:visited {
                color: #d7a600;
                text-decoration: none;
            }

            &:hover {
                color: #d7a600;
                text-decoration: none;
                opacity: 0.7;
            }

            &:active {
                color: #d7a600;
                text-decoration: none;
            }
        }
    }
}
