// recommendation

.recommendation {
    width: 100%;
    background: #f5f4ef;
    padding: 24px 0 100px 0;
    box-sizing: border-box;
    position: relative;

    &__inner {
        width: 824px;
        max-width: 100%;
        margin: 0 auto;
        padding: 18px 24px 30px 24px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #fff;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 26px;
        &-mgb32 {
            margin-bottom: 32px;
        }
    }

    &__secHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__headerWrap {
        display: unset;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
        background: #fff;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
    }

    &__headerBackButtonDiv {
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 56px;
        background: #fff;
    }

    &__headerBtn {
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        border: none;
        border-radius: 18px;
        background: #ffcc1d;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        padding: 7px 24px;
        box-sizing: border-box;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            box-shadow: none;
        }
    }

    &__footer {
        width: 824px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &-jcsb {
            justify-content: space-between;
        }
    }

    &__footerBtn {
    }

    &__modal {
        width: 400px;
        height: 462px;
        max-width: 100%;
        max-height: 90vh;
        border-radius: 8px;
        background: #fff;
        overflow-y: scroll;
        z-index: 1202;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
    }

    &__modalSearchArea {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 13px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__modalSearchText {
        width: 2.5em;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 10px;
    }

    &__modalSearch {
        width: calc(100% - 2.5em - 10px - 30px);
        background: #f5f4ef;
        padding: 12px;
        box-sizing: border-box;
        border: none;
        border-radius: 4px;
    }

    &__modalClose {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 14px;
        border: 1px solid transparent;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        transition-duration: 0.2s;

        &::before {
            content: '';
            display: block;
            width: 2px;
            height: 20px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-135deg);
        }

        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 20px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-45deg);
        }
    }

    &__modalUl {
        width: 100%;
        list-style: none;
        padding: 0 24px;
        box-sizing: border-box;
    }

    &__modalLi {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__modalLiName {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalLiBtn {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 6px 24px;
        background: #ffcc1d;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 18px;
        border: none;
        transition-duration: 0.2s;
        cursor: pointer;

        &:hover {
            box-shadow: none;
        }
    }

    &__dl {
        &-bdt {
            padding-top: 30px;
            border-top: 1px solid #e8e8e8;
        }
    }

    &__dt {
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: left;
        margin-bottom: 10px;
    }

    &__dd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: left;
        margin-bottom: 30px;
        word-break: break-word;

        &-mgt {
            margin-top: 10px;
        }

        &-mgb10 {
            margin-bottom: 15px;
        }
    }

    &__ddFile {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        text-decoration: none;
        line-height: 24px;
        letter-spacing: 0.014em;

        &-pdf {
            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background-image: url(../../img/recom-file-icon.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
        }

        &-url {
            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background-image: url(../../img/recom-url-icon.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
        }

        &-mgb {
            margin-bottom: 8px;
        }
    }

    &__ddText {
        width: 593px;
        max-width: 100%;
    }

    &__ddBox {
        width: 593px;
        max-width: 100%;
        padding: 17px 13px;
        box-sizing: border-box;
        border: 1px solid #e1e1e1;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    &__ddBoxTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__ddBoxText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 10px;

        &-mgt0 {
            margin-top: 0;
        }
    }

    &__footerText {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__footerRight {
        display: flex;
        justify-content: flex-end;
    }

    &__duplicateEntryError {
        display: flex;
        width: 100%;
        height: 56px;
        left: 0px;
        top: 0px;
        background: #fdd6cd;
        align-items: center;
        justify-content: space-between;
    }

    &__duplicateEntryErrorRight {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 30px;
    }

    &__duplicateEntryErrorMessage {
        margin: 30px;
        justify-content: flex-end;
        height: 24px;
        left: 32px;
        top: 73px;

        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        letter-spacing: 0.014em;
        color: #cd1818;
        align-items: center;
    }

    &__duplicateEntryErrorLink {
        color: #cd1818;
    }
}

@media screen and (max-width: 768px) {
    .recommendation {
        padding-top: 0;
        background: #fff;
        &__inner {
            padding: 18px 16px 30px 16px;
        }
        &-spPdb0 {
            padding-bottom: 0;
        }
        &__modal {
            height: 80vh;
        }
        &__footer {
            &-jcsb {
                flex-flow: column;
                align-items: flex-start;
            }
        }
        &__footerRight {
            width: 100%;
            margin-top: 12px;
        }
        &__dd {
            margin-bottom: 24px;
        }
        &__modalSearchArea {
            padding: 12px 8px 11px 16px;
        }
        &__modalSearch {
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #8a8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            padding: 4px 12px;
            width: 100%;
        }
        &__modalSearchText {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            white-space: nowrap;
        }
        &__modalUl {
            padding: 0 16px;
        }
        &__modalLi {
            padding: 8px 0;
            &-top {
                padding-top: 8px;
            }
            .btn__shadowBtn {
                height: 36px;
            }
        }
        &__infoDl {
            margin: 12px 0 0 12px;
        }
    }
}
