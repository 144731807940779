.candidate {
    background: #f5f4ef;
    height: calc(100vh - 56px);
    min-height: initial;

    &__inner {
        width: 1152px;
        max-width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__side {
        width: 312px;
        height: 100%;
        max-width: 27%;
        overflow-y: auto;
        border-right: 1px solid #e8e8e8;
        background: #fff;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
    }

    &__sideSec {
        width: 100%;
        padding: 25px 16px;
        box-sizing: border-box;
    }

    &__label1 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        margin-bottom: 8px;
        display: block;
    }

    &__keepBtn {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 16px;
        cursor: pointer;
    }

    &__sideSecTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 24px;
    }

    &__label2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 8px;
        display: block;
    }

    &__conditon {
        width: 100%;
        margin-top: 20px;

        &-mgb {
            margin-bottom: 20px;
        }
    }

    &__conditonTitleArea {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__conditonTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 1em;
    }

    &__conditonBtn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #d7a600;
        cursor: pointer;
    }

    &__conditonCheckArea {
    }

    &__conditonCheckItemWrap {
        // display: none;
    }

    &__conditonCheckTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 4px;
    }

    &__conditonCheckItem {
        margin-top: 8px;

        &-dpn {
            display: none;
        }
    }

    &__sideSecTitle2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 20px;
    }

    &__sideSecGroup {
        padding-top: 14px;

        &-top {
            padding-top: 0;
        }
    }

    &__sideSecGroupTitle {
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__sideSecGroupInner {
    }

    &__sideSecGroupCheckWrap {
        margin-top: 14px;
    }

    &__sideBtnArea {
        padding: 14px 16px;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        border-top: 1px solid #e8e8e8;
    }

    &__main {
        width: 840px;
        max-width: 73%;
        height: 100%;
        overflow-y: auto;
        padding: 0 25px 24px 25px;
        box-sizing: border-box;
        position: relative;
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
    }

    &__mainHeader {
        padding: 26px 29px 26px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__headerTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-small {
            font-size: 14px;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
    }

    &__mainLi {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        background: #fff;
        transition-duration: 0.2s;

        &-noBg {
            background: transparent;
            color: #8a8883;
        }

        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        }

        &:nth-child(1) {
            box-shadow: none;
            cursor: default;

            &:hover {
                box-shadow: none;
            }
        }
    }

    &__mainName {
        width: 16%;
        padding: 20px 0 20px 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainName2 {
        width: 16%;
        padding: 20px 0 20px 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainAge {
        width: 8%;
        padding: 20px 0 20px 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
    }

    &__mainSituation {
        width: 14%;
        padding: 20px 0 20px 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
    }

    &__mainApplications {
        width: 10%;
        padding: 20px 0 20px 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
    }

    &__mainMemo {
        width: 36%;
        padding: 20px 0 20px 20px;
        // display: flex;
        box-sizing: border-box;
        align-items: center;
        max-width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    &-pdb8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &__modalTabs {
        padding: 12px 24px;
        box-sizing: border-box;

        display: flex;
        list-style: none;
    }

    &__modalTab {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.011em;
        padding: 8px 24px;
        border-radius: 20px;
        margin-right: 24px;
        transition-duration: 0.2s;
        cursor: pointer;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &:hover {
            background: #ffcc1d;
            font-weight: bold;
            color: #151208;
        }

        &-active {
            background: #ffcc1d;
            font-weight: bold;
            color: #151208;
            cursor: default;
        }
    }

    &__modalTabContArea {
        width: 100%;
        padding: 0 24px 24px 24px;
        box-sizing: border-box;
        border-top: 1px solid #e8e8e8;
    }

    &__modalTabCont {
        display: none;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.2s;

        &-active {
            display: block;
            visibility: visible;
            opacity: 1;
        }
    }

    &__modalTabContInner {
        border-top: 1px solid #e8e8e8;
        padding-top: 32px;
        padding-bottom: 16px;

        &-top {
            border-top: none;
        }
        &-w582 {
            width: 582px;
            max-width: 100%;
        }
    }

    &__modalTabContTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 32px;

        &-mgb24 {
            margin-bottom: 24px;
        }
    }

    &__modalTabTable {
        max-width: 100%;
    }

    &__modalTabTr {
    }

    &__modalTabTh {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding-bottom: 8px;
        padding-right: 72px;
        text-align: left;

        &:nth-child(2) {
            padding-right: 0;
        }
    }

    &__modalTabTd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding-bottom: 16px;
        padding-right: 72px;
        text-align: left;

        &:nth-child(2) {
            padding-right: 0;
        }
    }

    &__modalTabMemoArea {
        padding: 16px;
        box-sizing: border-box;
        width: 582px;
        max-width: 100%;
        border-radius: 8px;
        border: 1px solid #e1e1e1;
        margin-bottom: 8px;
    }

    &__modalTabMemo {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalTabMemoFooter {
        width: 100%;
        padding: 8px 0 0 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }

    &__modalTabMemoFooterL {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalTabMemoFooterR {
        display: flex;
    }

    &__modalTabMemoText1 {
        margin-right: 23px;
    }

    &__modalTabMemoIconWrap {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        transition-duration: 0.2s;
        cursor: pointer;
        position: relative;

        &:hover {
            background: #fff4d1;
        }
    }

    &__modalTabMemoIcon {
        width: 17px;
        height: auto;
    }

    &__modalTabMemoBtnArea {
        margin-top: 26px;
        margin-bottom: 18px;
    }

    &__modalTabMemoTextarea {
        width: 582px;
        max-width: 100%;
        position: relative;
        display: none;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.3s;
        // z-index: 101;

        &-open {
            display: block;
            visibility: visible;
            opacity: 1;
        }

        textarea {
            height: 108px;
        }
    }

    &__modalTabMemoTextareaBtn {
        position: absolute;
        bottom: 20px;
        right: -16px;
        transform: translateX(100%);
    }

    &__modalTabTestimonial {
        width: 550px;
        max-width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalTabMemoDelArea {
        min-width: 326px;
        padding: 10px 24px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        background: #3e3104;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        position: absolute;
        bottom: 0;
        left: 25px;
        transform: translate(-50%, 100%);
        display: none;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.2s;
        z-index: 101;

        &-open {
            display: flex;
            visibility: visible;
            opacity: 1;
        }
    }

    &__modalTabMemoText {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalTabMemoDelLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalTabContBoxUl {
        width: 582px;
        max-width: 100%;
        list-style: none;
        margin-bottom: 18px;
        overflow-y: hidden;
        transition-duration: 0.2s;
    }

    &__modalTabContBoxLi {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        background: #f8f7f5;
        border-radius: 8px;
        margin-top: 8px;
        // &:nth-child(n + 4) {
        //     display: none;
        // }
        // &-open{
        //     &:nth-child(n + 4) {
        //         display: block;
        //     }
        // }
    }

    &__modalTabContBoxName {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__modalTabContBoxTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-bottom: 8px;
    }

    &__modalTabContBoxFooter {
        display: flex;
        justify-content: flex-end;
    }

    &__modalTabContBoxLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        transition-duration: 0.2s;

        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #d7a600;
            text-decoration: none;
            opacity: 0.7;
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }

    &__modalTabContBoxBtnWrap {
        text-align: center;
        width: 582px;
        max-width: 100%;
    }

    &__modalTabContBoxBtn {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #d7a600;
            border-right: 1px solid #d7a600;
            transform: rotate(135deg);
            margin-right: 10px;
            transition-duration: 0.2s;
        }

        &.open {
            &::before {
                transform: rotate(-45deg);
                margin-top: 3px;
            }
        }
    }
    &__modalTabContPortIconArea {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 18px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        transition-duration: 0.2s;
        cursor: pointer;
        position: relative;

        &:hover {
            background: #fff4d1;
        }
    }
    &__modalTabContPortArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 18px;
    }
    &__modalTabContOg {
        margin-bottom: 22px;
    }
    &__modalTabContPortBtnArea {
        margin-top: 30px;
        margin-bottom: 18px;
    }
    &__portfolioArea {
        display: block;
        visibility: visible;
        opacity: 1;
        transition-duration: 0.2s;
        &-active {
            display: block;
            visibility: visible;
            opacity: 1;
        }
    }

    &__modalTabMemoDelAreaClose {
        width: 100%;
        height: 100%;
        // background: #151208;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: none;
        // visibility: hidden;
    }
}

// .modal__contSlideCont{
//     z-index: 101;
// }

@media screen and (max-width: 768px) {
    .candidate {
        height: auto;

        &__side {
            width: 100%;
            max-width: 100%;
            height: 100%;
            overflow-y: auto;
            border-right: 1px solid #E8E8E8;
            background: #fff;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 19;
            // padding-bottom: 56px;
            box-sizing: border-box;
            transform: translateY(100vh);
            transition-duration: .3s;
            transition-timing-function: ease-in-out;

            &-active {
                transform: translateY(0);
            }
        }

        &__sideSec {
            padding: 0;
        }

        &__sideInner {
            padding: 16px;
            box-sizing: border-box;
        }

        &__inner {
            padding-top: 56px;
            height: auto;
        }

        &__main {
            width: 100%;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 0 0 56px 0;
        }

        &__mainHeader {
            width: 92%;
            margin: 0 auto;
            padding: 16px 0;
        }

        &__mainLi {
            &-noBg {
                display: none;
            }
        }

        &__sideBtnArea {
            flex-flow: row nowrap;
            bottom: 0;
            position: absolute;
            width: 100%;
        }

        &__mainLi {
            flex-flow: column;
            border-radius: 0;
            margin-bottom: 2px;
            margin: 0 auto 1px auto;
            padding: 16px 4%;
        }

        &__mainName{
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
        }
        &__mainName2,
        &__mainAge,
        &__mainSituation,
        &__mainApplications,
        &__mainMemo {
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            align-items: flex-start;
            &::before{
                content: attr(data-title);
                color: #8A8883;
                width: 6em;
            }
        }

        &__mainMemo {
            max-width: 330px;
            &::before {
                margin-right: 4em;
            }
        }

        &__mainName {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.011em;
            margin-bottom: 16px;

        }

        &__modalTabs{
            padding: 10px 4%;
            width: 108%;
            margin-left: -4%;
            justify-content: center;
            position: sticky;
            top: 55px;
            z-index: 10;
            background: #fff;
            border-bottom: 1px solid #E8E8E8;
            // position: relative;
            // &::after{
            //     content: "";
            //     display: block;
            //     width: 108%;
            //     height: 1px;
            //     position: absolute;
            //     bottom: 0;
            //     left: -4%;
            //     background-color: #E8E8E8;
            // }
        }

        &__modalTab {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            padding: 6px 12px;
            margin-right: 6px;
            white-space: nowrap;

        }
        &__modalTabContInner{
            padding-top: 28px;
        }
        &__modalTabContArea{
            width: 108%;
            margin-left: -4%;
            padding: 20px 0;
            border-top: none;
            // border-top: none;

            // border-bottom: 1px solid #E8E8E8;
        }
        &__modalTabMemoDelArea{
            transform: translate(30px, 100%);
            left: auto;
            right: 0;
            margin: auto;
            z-index: 9;
        }
        &__modalTabContInner{
            padding-left: 4%;
            padding-right: 4%;
            box-sizing: border-box;
            &-top{
                padding-top: 0;
            }

        }
        &__modalTabContTitle{
            margin-bottom: 20px;
        }

        &__modalTabMemoFooter{
            align-items: center;
        }

        &__modalTabMemoTextareaBtn{
            position: static;
            transform: translateX(0);
            margin: 16px 0 0 auto;
        }

        // &__mainName2{
        //     width: 100%;
        // }
        // &__mainAge{
        //     width: 100%;
        // }
    }
}
