@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans JP', sans-serif;
    width: 100%;
    overflow-x: hidden;
}

// @import '~flatpickr/dist/flatpickr.min.css';
// @import '~flatpickr/dist/plugins/monthSelect/style.css';

.flatpickr-monthSelect-month {
    width: 32.9%;
}

.form__syokusyuAdd {
    display: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #151208;
    line-height: 24px;
    letter-spacing: 0.014em;
    margin-bottom: 14px;
}

.form__groupFlex-col {
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.main__footer-end {
    justify-content: flex-end;
}

.form__cal {
    width: 256px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
        0px 2px 4px rgba(0, 0, 0, 0.08);
    background: #fff;
    position: absolute;
    top: 36px;
    left: 0;
    padding: 16px;
    z-index: 1;
    box-sizing: border-box;
    cursor: default;
    visibility: hidden;
    opacity: 0;
    transition-duration: 0.2s;
}

.form__cal-open {
    visibility: visible;
    opacity: 1;
}

.form__calHeader {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #151208;
    line-height: 24px;
    letter-spacing: 0.014em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__calHeader-back {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: none;
}

.form__calHeader-back::after {
    content: '';
    width: 7px;
    height: 7px;
    border-top: 1px solid #151208;
    border-right: 1px solid #151208;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.form__calHeader-next {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.form__calHeader-next::after {
    content: '';
    width: 7px;
    height: 7px;
    border-top: 1px solid #151208;
    border-right: 1px solid #151208;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.form__calHeader-notThisYear .form__calHeader-back {
    display: inline-block;
}

.form__calCont {
    display: flex;
    margin-top: 12px;
}

.form__calContUl {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
}

.form__calContLi {
    width: 33%;
    padding: 4px;
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #151208;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover,
    &-active {
        background: #3db19f;
        color: #fff;
    }

    &-disabled {
        opacity: 0.5;
        &:hover {
            background: none;
            color: #151208;
            cursor: default;
        }
    }
}

.form__calBg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
