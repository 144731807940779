.cm {
    justify-content: center;
    padding: 24px 0 0 0;
    background: #f5f4ef;

    &-pdb {
        padding-bottom: 24px;
    }

    &-col {
        flex-flow: column;
        justify-content: flex-start;
    }

    &-w {
        background: #fff;
    }

    &__header {
        width: 824px;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-radius: 8px;
        padding: 24px;
        box-sizing: border-box;
        margin: 0 auto 16px auto;
    }

    &__headerLeft {
        display: flex;
        align-items: center;
    }

    &__headerIconArea {
        width: 112px;
        height: 112px;
        border-radius: 50%;
        overflow: hidden;
        box-sizing: border-box;
        margin-right: 32px;
        flex-grow: 0;
    }

    &__headerIcon {
        object-fit: fill;
        width: calc(100% + 2px);
        height: 105%;
    }

    &__headerTextArea {
    }

    &__headerTitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 32px;
        letter-spacing: -0.001em;
        margin-bottom: 8px;
    }

    &__headerCompa {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-bottom: 8px;
    }

    &__headerDl {
        display: flex;
    }

    &__headerDt {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
        text-align: left;
        margin-right: 16px;
    }

    &__headerDd {
        display: flex;
    }

    &__headerDdCont {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 16px;
    }

    &__headerDdIcon {
        width: 20px;
        height: auto;
        margin-right: 8px;
    }

    &__headerDdNum {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__main {
        width: 824px;
        max-width: 100%;
        background: #fff;
        margin: 0 auto;
        border-radius: 8px;
    }

    &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-bottom: 32px;
    }

    &__title2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 24px;

        &-mgt {
            margin-top: 24px;
        }
    }

    &__mainHeader {
        width: 572px;
        max-width: 100%;
        background: #f8f7f5;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    &__mainHeaderDl {
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 16px;

        &-none {
            border: none;
            margin-bottom: 0;
        }
    }

    &__mainHeaderDt {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #3db19f;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 4px;

        svg {
            margin-right: 8px;
        }

        img {
            margin-right: 8px;
        }
    }

    &__mainHeaderDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 16px;
        white-space: break-spaces;

        &-last {
            margin-bottom: 0;
        }
    }

    &__mainTextArea {
        margin-top: 24px;
        padding-bottom: 24px;
    }

    &__mainText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 16px;
        word-break: break-word;
    }

    &__mainDl {
        display: flex;
        flex-flow: row wrap;
        color: #151208;
    }

    &__mainDt {
        width: 8em;
        padding: 16px 0;
        box-sizing: border-box;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainDd {
        width: calc(100% - 8em);
        padding: 16px 0;
        box-sizing: border-box;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainDdLink {
        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #d7a600;
            text-decoration: none;
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }

    &__formText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 12px;
    }

    &__conditonCheckItemWrap {
        display: none;
    }

    &__headerRight {
    }

    &__headerRightTop {
        display: flex;
        align-items: center;
    }

    &__headerRightBtn {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e1e1e1;
        border-radius: 50%;
    }

    &__mainTabs {
        display: flex;
        list-style: none;
        padding: 12px 8px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__mainTab {
        margin: 0 12px;
    }

    &__mainTabLink {
        display: flex;
        padding: 8px 24px;

        border-radius: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;
        }

        &:hover {
            color: #8a8883;
            text-decoration: none;
        }

        &:active {
            color: #8a8883;
            text-decoration: none;
        }

        &:hover {
            background: #ffcc1d;

            &:link {
                color: #151208;
                text-decoration: none;
            }

            &:visited {
                color: #151208;
                text-decoration: none;
            }

            &:hover {
                color: #151208;
                text-decoration: none;
            }

            &:active {
                color: #151208;
                text-decoration: none;
            }

            .cm__mainTabLinkNum {
                background: #151208;
            }
        }

        &-active {
            background: #ffcc1d;

            &:link {
                color: #151208;
                text-decoration: none;
            }

            &:visited {
                color: #151208;
                text-decoration: none;
            }

            &:hover {
                color: #151208;
                text-decoration: none;
            }

            &:active {
                color: #151208;
                text-decoration: none;
            }

            .cm__mainTabLinkNum {
                background: #151208;
            }
        }
    }

    &__mainTabLinkNum {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #8a8883;
        color: #fff;
        margin-left: 8px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.011em;
        text-align: center;
        transition-duration: 0.2s;
    }

    &__evaWrap {
        padding: 0 16px 24px 16px;

        .pagenation {
            width: 609px;
            max-width: 100%;
        }

        .evaluation__leftEva {
            margin: 0;
        }

        .evaluation__leftEvaHeader {
            padding: 9.5px 0;
            margin: 8px 0;
        }
    }

    &__invitation {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    &__invitationLogoArea {
        width: 170px;
        margin-bottom: 16px;
    }

    &__invitationLogo {
        width: 100%;
        height: auto;
    }

    &__invitationCatch {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.007em;
        margin-bottom: 16px;
    }

    &__invitationText {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-bottom: 32px;
    }

    .form__label {
        color: #151208;
    }
}

.cmModal {
    .modal__contMainRightCont,
    .modal__contMainRightContSp {
        & > p.form__groupCheckItem {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .cm {
        padding: 0;

        &-sppd {
            padding-top: 56px;
        }

        &__title {
            margin: 0;
            padding: 24px 0;
        }

        &__title2 {
            font-size: 16px;
        }

        &__header {
            border-radius: 0;
            padding: 24px 16px;
        }

        &__headerLeft {
            // align-items: flex-start;
        }

        &__headerTitle {
            font-size: 20px;
            margin-bottom: 4px;
        }

        &__headerCompa {
            font-size: 14px;
            margin-bottom: 16px;
        }

        &__headerIconArea {
            width: 96px;
            height: 96px;
            margin-right: 24px;
            margin-bottom: 0;
            &-mgb16 {
                margin-bottom: 16px;
            }
        }

        &__main {
            border-radius: 0;
            padding-bottom: 30px;
        }

        &__mainTab {
            width: 48%;
        }

        &__mainTabLink {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0;
            width: 100%;
        }

        &__pfImgArea {
            flex-flow: column;
            align-items: flex-start;
        }

        &__headerIconArea {
            // margin-bottom: 16px;
        }

        &__invitationCatch {
            font-size: 24px;
        }

        &__invitationBtn {
            width: 226px;
        }

        &__mainDt {
            width: 100%;
            display: block;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #8a8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            padding: 0;
            margin-bottom: 8px;
        }

        &__mainDd {
            width: 100%;
            display: block;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            padding: 0;
            margin-bottom: 24px;
        }
    }
}
