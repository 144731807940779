@charset "utf-8";

/*!日本語*/

// settings
@import 'variables';

@import '~flatpickr/dist/flatpickr.css';

@import '_common.scss';
@import '_form.scss';
@import '_modal.scss';
@import '_button.scss';
@import '_avatar.scss';

// layout

@import 'blocks/_admin.scss';
@import 'blocks/_cal.scss';
@import 'blocks/_candidate.scss';
@import 'blocks/_comment.scss';
@import 'blocks/_company.scss';
@import 'blocks/_dashboard.scss';
@import 'blocks/_evaluation.scss';
@import 'blocks/_jobvacancies.scss';
@import 'blocks/_question.scss';
@import 'blocks/react_select.scss';
@import 'blocks/_recommendation.scss';
@import 'blocks/_search.scss';
@import 'blocks/_selection.scss';
@import 'blocks/_step1.scss';
@import 'blocks/_step.scss';
@import 'blocks/_cm.scss';
@import 'blocks/cm015.scss';
@import 'blocks/invitation.scss';
@import 'blocks/notification.scss';
@import 'blocks/specialFeature.scss';

// overwrite
@import '_pcsp.scss';
