@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Roboto:wght@300;400;500;700&display=swap');
@import 'variables';
$shadow1: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
$shadow2: 0px 2px 4px rgba(0, 0, 0, 0.01), 0px 5px 8px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.08);
$shadow3: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.04), 0px 4px 14px rgba(0, 0, 0, 0.08);
$shadow4: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(0, 0, 0, 0.08);
$shadow5: 0px 8px 16px rgba(0, 0, 0, 0.06), 0px 12px 24px rgba(0, 0, 0, 0.06), 0px 1px 32px rgba(0, 0, 0, 0.06);

body {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    overflow-x: hidden;
}

button {
    font-family: 'Noto Sans JP', sans-serif;
}

.app,
#root {
    position: relative;
}

// header
.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 25px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    &__logoArea {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__logoWrap {
        margin-right: 25px;
    }

    &__logoName {
        font-size: 16px;
        letter-spacing: 0.011em;
    }

    &__saveBtnArea {
    }

    &__saveBtn {
        border: none;
        background: none;
        font-size: 14px;
        letter-spacing: 0.014em;
        color: #dead05;
        cursor: pointer;
    }

    &__humbArea {
        display: none;
    }

    &__nav {
        margin-left: 64px;
    }

    &__navUl {
        list-style: none;
        display: flex;
    }

    &__navLi {
        padding: 7px 10px;
        box-sizing: border-box;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 32px;
            background: #e8e8e8;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }

    &__navLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 10px 15px;
        box-sizing: border-box;
        border-radius: 4px;
        transition-duration: 0.2s;

        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
            background: #ffcc1d;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }

        &-active {
            background: #ffcc1d;
        }
    }

    &__rightArea {
        display: flex;

        &-aic {
            align-items: center;
        }
    }

    &__infoIconArea {
        &-active {
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background: #ff611d;
                border: 1px solid #fff;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    &__name {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.017em;
        margin-left: 40px;
    }

    &__backBtn {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.011em;
        display: flex;
        align-items: center;
        transition-duration: 0.2s;

        &::before {
            content: '';
            display: block;
            width: 17px;
            height: 7px;
            background-image: url(../img/back-arrow.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 18px;
        }

        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }
    }

    &__selectWrap {
        display: inline-block;
        cursor: pointer;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0.9em;
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }

        &-mgt0 {
            margin-top: 0;
        }

        &-triA {
            &::before {
                right: 0.5em;
            }
        }

        &-ml {
            margin-left: 35px;
        }
    }

    &__select {
        background: #fff;
        border: none;
        padding: 5px 35px 5px 12px;
        box-sizing: border-box;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
    }

    &__option {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__customSelectArea {
        position: relative;

        margin-left: 20px;
    }

    &__customSelectBack {
        width: 100%;
        height: 100vh;
        // background: #000;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        display: none;
    }

    // &__customSelectWrap {
    //     display: inline-block;
    //     cursor: pointer;
    //     width: 100%;

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         right: 0.9em;
    //         bottom: 0;
    //         margin: auto;
    //         width: 0;
    //         height: 0;
    //         padding: 0;
    //         border-left: 6px solid transparent;
    //         border-right: 6px solid transparent;
    //         border-top: 6px solid #000;
    //         pointer-events: none;
    //     }

    // }
    $custom-select-padding-x: 16px;

    &__customSelect {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;

        background: #fff;
        padding: 6px $custom-select-padding-x;
        border-radius: 4px;
        box-sizing: border-box;
        color: #151208;
        cursor: pointer;
        transition-duration: 0.2s;

        display: flex;
        align-items: center;

        &::after {
            content: '';
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
            margin-left: 13px;
        }

        &:hover {
            background: #ffcc1d;
        }

        &-active {
            background: #fff4d1;
        }

        &-profileClicked {
            background: #ffcc1d;
        }
    }

    &__customSelectList {
        list-style: none;
        padding: 0;
        box-sizing: border-box;
        // margin-top: 13px;
        // min-width: 146px;
        border-radius: 4px;
        transition-duration: 0.2s;
        position: absolute;
        top: 36px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 15;
        background: #fff;
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        overflow: hidden;

        &-active {
            display: block;
            visibility: visible;
            opacity: 1;
            max-height: 10000px;
            padding: 4px 0;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        }

        &-t0 {
            top: 36px;
        }

        &-right {
            left: unset;
            right: 0;
            transform: translateX(-$custom-select-padding-x);
        }
    }

    &__customSelectItem {
        width: 100%;

        border-bottom: 1px solid #e8e8e8;

        &:nth-child(1) {
            border-top: 1px solid #e8e8e8;
        }
    }

    &__customSelectItemLink {
        box-sizing: border-box;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: block;
        padding: 12px 16px;
        white-space: nowrap;
        transition-duration: 0.2s;

        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
            font-weight: 500;
            background: #ffcc1d;
            cursor: pointer;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }
    }
}

// spHeader
.spHeader {
    display: none;
}

// step
.step {
    width: 100%;

    &__text {
        padding: 15px 25px;
        box-sizing: border-box;
        font-size: 14px;
        letter-spacing: 0.014em;
    }

    &__bar {
        width: 100%;
        height: 8px;
        background: #ffeaa4;
        position: relative;

        &::after {
            content: '';
            display: block;
            height: 8px;
            background: #ffcc1d;
            position: absolute;
            top: 0;
            left: 0;
        }

        &-25 {
            &::after {
                width: 25%;
                border-radius: 0 8px 8px 0;
            }
        }

        &-33 {
            &::after {
                width: 33%;
                border-radius: 0 8px 8px 0;
            }
        }

        &-50 {
            &::after {
                width: 50%;
                border-radius: 0 8px 8px 0;
            }
        }

        &-66 {
            &::after {
                width: 66%;
                border-radius: 0 8px 8px 0;
            }
        }

        &-75 {
            &::after {
                width: 75%;
                border-radius: 0 8px 8px 0;
            }
        }

        &-100 {
            &::after {
                width: 100%;
                border-radius: 0 8px 8px 0;
            }
        }
    }
}

// main
.main {
    width: 100%;
    background: #f8f7f5;
    display: flex;
    min-height: 100vh;
    position: relative;

    &-errorBar {
        display: block;
        padding-top: 0 !important;
    }

    &__formArea {
        width: 58%;
        background: #fff;
    }

    &__footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 13px 30px;
        box-sizing: border-box;
        border-top: 1px solid #e8e8e8;
        margin-top: 80px;

        position: sticky;
        bottom: 0;
        left: 0;
        background: #fff;

        &-end {
            justify-content: flex-end;
        }
    }

    &__footerLeft {
    }

    &__footerRight {
        text-align: right;
    }

    &__footerReq {
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #b8b7b4;
        line-height: 24px;
        letter-spacing: 0.014em;
        background: #f8f7f5;
        border-radius: 18px;
        padding: 8px 24px;
        box-sizing: border-box;
    }

    &__footerBtn {
        width: 101px;
        border: none;
        border-radius: 18px;
        padding: 8px 10px;
        background: #ffcc1d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transition-duration: 0.2s;
        margin: 0 0 0 auto;
        display: none;

        display: none;

        &:hover {
            opacity: 0.7;
        }

        &-disabled {
            background: #f8f7f5;
            color: #b8b7b4;
            cursor: auto;

            &:hover {
                opacity: 1;
            }

            .main__footerBtnIcon {
                fill: #b8b7b4;
            }
        }

        &-active {
            display: flex;
        }

        &-wa {
            width: auto;
        }
    }

    &__footerBtnIcon {
        width: 16px;
        fill: #000;
        margin-left: 10px;
    }

    &__footerBack {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;
        transition-duration: 0.2s;

        &::before {
            content: '';
            display: block;
            width: 17px;
            height: 7px;
            background-image: url(../img/arrow2.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 7px;
        }

        &:link {
            color: #dead05;
            text-decoration: none;
        }

        &:visited {
            color: #dead05;
            text-decoration: none;
        }

        &:hover {
            color: #dead05;
            text-decoration: none;
            opacity: 0.7;
        }

        &:active {
            color: #dead05;
            text-decoration: none;
        }
    }

    &__evaluation {
        width: 100%;
        background: #f5f4ef;
    }

    &__errorBar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 17px 32px 15px 32px;
        box-sizing: border-box;
        background: #fdd6cd;
        position: sticky;
        top: 56px;
        left: 0;
        z-index: 10;
    }

    &__errorBarLeft {
        display: flex;
        align-items: center;
    }

    &__errorBarRight {
        display: flex;
        align-items: center;
    }

    &__errorBarText {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #cd1818;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__errorBarLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #cd1818;
        line-height: 24px;
        letter-spacing: 0.014em;

        &:link {
            color: #cd1818;
        }

        &:visited {
            color: #cd1818;
        }

        &:hover {
            color: #cd1818;
            opacity: 0.7;
        }

        &:active {
            color: #cd1818;
        }
    }

    &__errorCloseArea {
        width: 12px;
        margin-left: 33px;
        cursor: pointer;

        svg {
            width: 100%;
            height: auto;
        }
    }
}

// footer
.footer {
    width: 100vw;
    padding: 16px 25px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.04), 0px 4px 14px rgba(0, 0, 0, 0.08);
    // position: fixed;
    // 2022/03/25変更
    position: sticky;
    bottom: 0;
    left: 0;

    &-flex {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    &-flex1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        z-index: 6;
    }

    &__leftCont {
        &-flex {
            display: flex;
            align-items: center;
        }
    }
    &__inner {
        display: flex;
        &-jce {
            justify-content: flex-end;
        }
        &-w824 {
            width: 824px;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    &__corp {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;

        &-mgr {
            margin-right: 24px;
        }
    }

    &__corp2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__ul {
        list-style: none;
        display: flex;
        align-items: center;
    }

    &__li {
        margin-right: 15px;

        &-mr12 {
            margin-right: 12px;
        }

        &-mr16 {
            margin-right: 16px;
        }

        &-mr24 {
            margin-right: 24px;
        }
    }

    &__btnIcon {
        svg {
            width: 16px;
            fill: #d7a600;
            fill-rule: evenodd;
            margin-right: 8px;
            transition-duration: 0.2s;
        }
    }
}

// pagenation

.pagenation {
    width: 100%;
    max-width: 100%;
    margin-top: 23px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &__prev {
        width: 8.5px;
        height: 8.5px;
        border-top: 1px solid #8a8883;
        border-right: 1px solid #8a8883;
        transform: rotate(-135deg);
        margin-right: 14px;
        margin-left: 14px;

        &-disabled {
            cursor: not-allowed;
        }

        &:hover:not(&-disabled) {
            cursor: pointer;
        }
    }

    &__next {
        width: 8.5px;
        height: 8.5px;
        border-top: 1px solid #8a8883;
        border-right: 1px solid #8a8883;
        transform: rotate(45deg);
        margin-left: 7px;
        margin-right: 14px;

        &-disabled {
            cursor: not-allowed;
        }

        &:hover:not(&-disabled) {
            cursor: pointer;
        }
    }

    &__num {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 7px;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            &:not(.current) {
                cursor: pointer;
            }
        }

        &:active {
            color: #8a8883;
            text-decoration: none;
        }

        &.current {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;
        }
    }

    &__dot {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 7px;
    }

    &-mgt23 {
        margin-top: 23px;
    }
}

.spPagenation {
    display: none;
}

.aleart {
    width: 416px;
    position: fixed;
    bottom: 100px;
    left: 16px;
    // transform: translateY(-100%);
    padding: 16px 16px 16px 16px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
        0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;

    &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #069fcf;
        line-height: 24px;
        letter-spacing: 0.014em;
        height: 32px;
        background: #fff;
        border-radius: 20px;
        padding: 0 24px;

        &:link {
            color: #069fcf;
            text-decoration: none;
        }

        &:visited {
            color: #069fcf;
            text-decoration: none;
        }

        &:hover {
            color: #069fcf;
            text-decoration: none;
        }

        &:active {
            color: #069fcf;
            text-decoration: none;
        }
    }

    &__container {
        position: relative;
    }

    &-btm {
        top: auto;
        bottom: 16px;
    }

    &-success {
        background: #069fcf;
        color: #fff;

        .aleart__text {
            &::before {
                background-image: url(../img/success-icon.svg);
            }
        }
    }

    &-danger {
        background: #cd1818;
        color: #fff;

        .aleart__text {
            &::before {
                background-image: url(../img/danger-icon.svg);
            }
        }
    }

    &__num {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 7px;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            &:not(.current) {
                cursor: pointer;
            }
        }

        &:active {
            color: #8a8883;
            text-decoration: none;
        }

        &.current {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;
        }
    }

    &__dot {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 7px;
    }

    &-mgt23 {
        margin-top: 23px;
    }
}
.sp {
    display: none;
}

// tooltip
.tooltip {
    &__main {
        min-width: 326px;
        padding: 10px 24px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        background: #3e3104;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        position: absolute;
        bottom: 0;
        left: 25px;
        transform: translate(-50%, 100%);
        display: none;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.2s;
        z-index: $tooltip-backdrop-index + 1;

        &-open {
            display: flex;
            visibility: visible;
            opacity: 1;
        }
    }

    &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__link {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__areaClose {
        width: 100%;
        height: 100%;
        // background: #151208;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: none;
        // visibility: hidden;
    }

    &__btn {
        position: relative;
    }

    &__closeBg {
        width: 100%;
        height: 100%;
        // background: #151208;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $tooltip-backdrop-index;
        display: none;
        // visibility: hidden;
    }
}
.circularProgress {
    position: absolute;
    top: 50%;
    left: 50%;
}

@media screen and (max-width: 768px) {
    body {
        min-width: 100%;
    }

    // header
    .header {
        width: 100%;
        height: 56px;
        padding: 0 12px;
        border-top: 1px solid #e8e8e8;
        border-bottom: none;
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 16;

        &-spTop {
            position: sticky;
            top: 0;
            bottom: auto;
            border-bottom: 1px solid #e8e8e8;
        }

        &__logoArea {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        &__logoWrap {
        }

        &__logoName {
        }

        &__saveBtnArea {
        }

        &__saveBtn {
            border: none;
            background: none;
            font-size: 14px;
            letter-spacing: 0.014em;
            color: #dead05;
            cursor: pointer;
        }

        &__humbArea {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
        }

        &__nav {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            margin-left: 0;
            background: rgba(76, 61, 8, 0.8);
            z-index: 11;
            visibility: hidden;
            opacity: 0;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;

            &-open {
                visibility: visible;
                opacity: 1;

                .header__navInner {
                    transform: translateX(0);
                }
            }
        }

        &__navInner {
            width: 85%;
            height: 100%;
            background: #fff;
            transform: translateX(-100%);
            transition-duration: 0.3s;

            &-open {
                transform: translateX(0);
            }
        }

        &__navBgClose {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &__navHeader {
            display: flex;
            justify-content: space-between;
            padding: 8px 8px 8px 17px;
            border-bottom: 1px solid #e8e8e8;
        }

        &__navClose {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
        }

        &__navUl {
            width: 100%;
            flex-flow: column;
            margin: 17px 0;
            border-bottom: 1px solid #e8e8e8;

            &-none {
                border-bottom: none;
            }
        }

        &__navLi {
            padding: 0;

            &::after {
                display: none;
            }
        }

        &__navLink {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            padding: 12px 16px;
            box-sizing: border-box;
            border-radius: 4px;
            transition-duration: 0.2s;

            &::after {
                content: '';
                width: 7px;
                height: 7px;
                border-top: 1px solid #151208;
                border-right: 1px solid #151208;
                transform: rotate(45deg);
            }

            &-none {
                &::after {
                    display: none;
                }
            }

            &-active {
                background: #ffcc1d;
            }
        }

        &__rightArea {
            display: flex;

            &-aic {
                align-items: center;
            }
        }

        &__infoIconAreaWrap {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__infoIconArea {
            &-active {
                &::after {
                }
            }
        }

        &__name {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.017em;
            margin-left: 40px;
        }

        &__backBtn {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.011em;
            display: flex;
            align-items: center;
            transition-duration: 0.2s;

            &::before {
                content: '';
                display: block;
                width: 17px;
                height: 7px;
                background-image: url(../img/back-arrow.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 18px;
            }

            &:link {
                color: #151208;
                text-decoration: none;
            }

            &:visited {
                color: #151208;
                text-decoration: none;
            }

            &:hover {
                color: #151208;
                text-decoration: none;
            }

            &:active {
                color: #151208;
                text-decoration: none;
            }
        }

        &__selectWrap {
            display: none;
        }

        &__select {
        }

        &__option {
        }
    }

    // spHeader
    .spHeader {
        display: block;
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        height: 55px;
        z-index: 10;

        &-inSec {
            position: sticky;
            border-bottom: 1px solid #e8e8e8;
        }

        &__inner {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            box-sizing: border-box;

            &.border {
                border-bottom: solid 1px #e8e8e8;
            }
        }

        &__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;

            &:link {
                color: #000;
                text-decoration: none;
            }

            &:visited {
                color: #000;
                text-decoration: none;
            }

            &:hover {
                color: #000;
                text-decoration: none;
            }

            &:active {
                color: #000;
                text-decoration: none;
            }
            &-link {
                display: flex;
                align-items: center;
                &::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url(../img/back-arrow.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                    margin-right: 19px;
                }
            }
        }

        &__close {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-left: 24px;
            border: 1px solid transparent;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            transition-duration: 0.2s;

            &::before {
                content: '';
                display: block;
                width: 2px;
                height: 20px;
                background: #151208;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transform: rotate(-135deg);
            }

            &::after {
                content: '';
                display: block;
                width: 2px;
                height: 20px;
                background: #151208;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transform: rotate(-45deg);
            }
        }
        &__btn {
            display: flex;
            align-items: center;
            &:link {
                color: #d7a600;
                text-decoration: none;
            }

            &:visited {
                color: #d7a600;
                text-decoration: none;
            }

            &:hover {
                color: #b28e14;
                text-decoration: none;

                .btn__btnIcon {
                    svg {
                        fill: #b28e14;
                    }
                }
            }

            &:active {
                color: #d7a600;
                text-decoration: none;
            }
        }
    }

    // main
    .main {
        &__formArea {
            width: 100%;
        }
        &__footer {
            padding-left: 16px;
            padding-right: 16px;
        }
        &__errorBar {
            padding: 8px 8px 8px 12px;
        }
        &__errorBarLeft {
            width: 64%;
            margin-right: 25px;
        }
        &__errorCloseArea {
            width: 32px;
            height: 32px;
            padding: 6px;
            box-sizing: border-box;
            margin-left: 12px;
        }
    }

    // footer
    .footer {
        &-p16 {
            padding: 16px;
        }
    }

    // pagenation

    .pagenation {
        margin-bottom: 23px;
    }

    .spPagenation {
        width: 100%;
        margin: 26px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__text {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    .aleart {
        width: 92%;
        position: fixed;
        top: auto;
        bottom: 72px;
        left: 4%;
        transform: translateY(0);
        padding: 14px 20px 14px 20px;
        box-sizing: border-box;

        &::before {
            width: 16px;
            height: 16px;
        }
    }
}

.mgb {
    margin-bottom: 15px;
}

.mgr {
    margin-right: 10px;
}
// animation
