.jobvacancies {
    width: 100%;
    max-width: 100%;

    &__cont {
        padding: 32px 0;
        border-top: 1px solid #e8e8e8;
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 8px;

        &-jcsb {
            justify-content: space-between;
        }
    }

    &__titleImg {
        display: inline-block;
        margin-right: 10px;
    }

    &__titleSup {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #369f8f;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__dl {
        width: 593px;
        max-width: 100%;
        margin-top: 32px;
    }

    &__dt {
        margin-top: 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__dd {
        margin-top: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__ddTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        &-mgt16 {
            margin-top: 16px;
        }
    }

    &__ddBox {
        display: inline-block;
        min-width: 416px;
        margin-top: 8px;
        padding: 16px;
        background: #f8f7f5;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        color: #151208;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__ddBoxText {
        &-mgt4 {
            margin-top: 4px;
        }
    }

    &__ddBoxDl {
        display: flex;
        margin-top: 4px;
    }

    &__ddBoxDt {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 16px;
    }

    &__ddBoxDtImg {
        display: inline-block;
        margin-right: 6px;
    }
    &__ddBoxDd {
    }
}

@media screen and (max-width: 768px) {
    .jobvacancies {
        &__cont{
            padding-top: 32px;
        }
        &__dl{
            margin-top: 16px;
        }

        &__ddBox{
            min-width: unset;
            width: 100%;
            box-sizing: border-box;
        }

        &__title {
            .sp{
                display: flex;
            }

            .btn__textBtn{
                height: unset;
                padding: unset;
                &:hover{
                    color: #d7a600;
                    background-color: transparent;
                    border: 1px solid transparent;
                }
            }
        }
    }
}
