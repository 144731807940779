// dashboard

.content {
    width: 100vw;
    min-height: 100vh;
    background: #f5f4ef;
    padding: 24px;
    position: relative;

    &__inner {
        width: 100%;
        max-width: 1024px;
        margin: auto;
    }
}

.dashboard {
    &__alert {
        font-size: 14px;
        color: #fff;
        background: #069fcf;
        padding: 12px 24px;
        margin-bottom: 24px;
        border-radius: 8px;
        box-sizing: border-box;
        gap: 0 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-align-items: center;
        align-items: center;

        &-num {
            height: 30px;
            color: #069fcf;
            background: #fff;
            padding: 0 15px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            border-radius: 25px;
        }
    }

    &__activity {
        background: #fff;
        padding: 24px;
        margin-bottom: 48px;
        border-radius: 8px;
        box-sizing: border-box;
    }

    &__status {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        &-dl {
            width: 100%;
            text-align: center;

            dt {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: #8a8883;
                margin-bottom: 8px;
                letter-spacing: 0.014em;
            }

            dd {
                background: #f8f7f5;
                padding: 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -ms-align-items: center;
                align-items: center;
            }

            &:first-of-type dd {
                border-radius: 4px 0 0 4px;
            }

            &:last-of-type dd {
                border-radius: 0 4px 4px 0;
            }
        }

        &-num {
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            display: inline-block;
            letter-spacing: -0.02em;
        }

        &-unit {
            font-size: 14px;
            font-style: normal;
            margin-left: 2px;
            display: inline-block;
        }
    }

    &__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-wrap {
            margin-bottom: 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        &-sub {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            color: #8a8883;
            margin-bottom: 16px;
            letter-spacing: 0.014em;
        }
    }

    &__mainTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__btn {
        &-link {
            margin-left: auto;
            display: inline-block;
            text-decoration: none;
        }

        &-txt {
            font-size: 14px;
            font-style: normal;
            color: #d7a600;
            margin-right: 8px;
        }

        svg {
            position: relative;
            top: 2px;
        }
    }

    &__mensetsu {
        margin-bottom: 48px;

        .slick-next,
        .slick-prev {
            width: 30px;
            height: 30px;
            background: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-align-items: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 6px 12px rgba(0, 0, 0, 0.08), 0 1px 16px rgba(0, 0, 0, 0.08),
                0 2px 4px rgba(0, 0, 0, 0.08);
            transition-duration: 0.2s;

            &::before {
                content: '';
            }

            &:hover {
                background: #fff;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
            }
        }

        .slick-next {
            right: -50px;
        }

        .slick-prev {
            left: -50px;

            svg {
                position: relative;
                left: -2px;
                transform: rotate(180deg);
            }
        }

        .slick-none {
            display: none;
        }

        .slick-slide {
            width: 100%;
            max-width: 600px;
            background: #fff;
            padding: 24px;
            margin-right: 12px;
            border-radius: 8px;
            box-sizing: border-box;
        }

        &__title {
            margin-bottom: 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            display: none;

            &-date {
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                display: inline-block;
                letter-spacing: -0.02em;
            }

            &-num {
                font-family: Roboto;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                margin-left: 16px;
                display: inline-block;
                letter-spacing: -0.02em;
            }

            &-unit {
                font-size: 14px;
                font-style: normal;
                margin-left: 2px;
                display: inline-block;
            }
        }

        &__status {
            margin-bottom: 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            &-dl {
                width: 100%;

                dt {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    color: #8a8883;
                    padding: 0 8px;
                    margin-bottom: 8px;
                    letter-spacing: 0.014em;
                }

                dd {
                    font-size: 14px;
                    font-style: normal;
                    line-height: 18px;
                    min-height: 50px;
                    background: #f8f7f5;
                    padding: 4px 8px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-align-items: center;
                    align-items: center;
                }

                &:nth-child(1) {
                    width: 34%;
                }

                &:nth-child(2) {
                    width: 33%;
                }

                &:nth-child(3) {
                    width: 33%;

                    dd {
                        padding-right: 24px;
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }

        .dashboard__btn {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }
    }

    &__job {
        margin-bottom: 48px;
        margin-left: -10px;
        margin-right: -10px;

        .slick-next,
        .slick-prev {
            width: 30px;
            height: 30px;
            background: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-align-items: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 6px 12px rgba(0, 0, 0, 0.08), 0 1px 16px rgba(0, 0, 0, 0.08),
                0 2px 4px rgba(0, 0, 0, 0.08);
            transition-duration: 0.2s;

            &::before {
                content: '';
            }

            &:hover {
                background: #fff;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
            }
        }

        .slick-next {
            right: -50px;
        }

        .slick-prev {
            left: -50px;

            svg {
                position: relative;
                left: -2px;
                transform: rotate(180deg);
            }
        }

        .slick-none {
            display: none;
        }

        .slick-track {
            display: flex !important;
            margin-left: 0;
        }
        .slick-slide {
            width: calc((100% - 48px) / 3);
            height: inherit;
            & > div {
                margin: 10px;
                height: 96%;
            }
        }

        &__item {
            color: #151208;
            background: #fff;
            padding: 24px;
            display: block;
            border-radius: 8px;
            text-decoration: none;
            box-sizing: border-box;
            transition-duration: 0.2s;
            height: inherit;
            margin-bottom: 24px;
            &:hover {
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 6px 12px rgba(0, 0, 0, 0.08),
                    0 1px 16px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08);
            }
            &-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 18px;
                letter-spacing: 0.008em;
            }
            &-dl {
                margin-bottom: 18px;
                gap: 10px 18px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                dt {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    width: 35%;
                    color: #8a8883;
                }
                dd {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    width: calc(65% - 18px);
                }
            }
            &-tag {
                gap: 0 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                li {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    color: #fff;
                    background: #b28e14;
                    padding: 5px 10px;
                    border-radius: 4px;
                    list-style-type: none;
                    margin-bottom: 5px;
                }
            }
            &-bottom {
                padding-top: 16px;
                margin-top: 16px;
                border-top: 1px solid #e8e8e8;
            }
            &-gyoshu {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #8a8883;
                margin-bottom: 10px;
                line-height: 22px;
            }
            &-cname {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }
        }
    }

    &__jobL {
        margin-bottom: 48px;

        &__item {
            color: #151208;
            width: 100;
            background: #fff;
            padding: 24px;
            display: block;
            border-radius: 8px;
            position: relative;
            box-sizing: border-box;
            margin-bottom: 24px;

            &-title {
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 18px;
                letter-spacing: 0.008em;
            }

            &-dl {
                margin-bottom: 18px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                dt {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    color: #8a8883;
                    margin-right: 16px;
                }

                dd {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    margin-right: 48px;
                }
            }

            &-tag {
                gap: 0 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                li {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    color: #fff;
                    background: #b28e14;
                    padding: 7px 10px 8px;
                    border-radius: 4px;
                    list-style-type: none;
                }
            }

            &-bottom {
                padding: 16px 120px 0 0;
                margin-top: 16px;
                border-top: 1px solid #e8e8e8;
                position: relative;
            }

            &-gyoshu {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #8a8883;
                margin-bottom: 10px;
            }

            &-cname {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }

            &-deleteBtn {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #fd5386;
                background: #fff;
                padding: 8px 20px 7px 20px;
                border: 1px solid #e1e1e1;
                border-radius: 18px;
                cursor: pointer;
                box-sizing: border-box;
                letter-spacing: 0.014em;
                position: absolute;
                right: 0;
                bottom: 0;
                transition-duration: 0.2s;

                &:hover {
                    color: #000;
                    background: #f8f7f5;
                }
            }
        }
    }

    &__addJob {
        background: #fff;
        padding: 24px;
        margin-bottom: 48px;
        border-radius: 8px;
        box-sizing: border-box;

        &__input {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;

            &-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 100%;
                margin-bottom: 3px;
                display: block;
                letter-spacing: 0.014em;
            }

            &-input {
                width: calc(100% - 200px);
            }

            .btn {
                width: 160px;
                margin-left: auto;
            }
        }
    }
}

.fArticle {
    &__kv {
        background: #fff;
        padding: 24px;
        margin-bottom: 48px;
        border-radius: 8px;
        box-sizing: border-box;

        &__img {
            border-radius: 8px;
            overflow: hidden;
        }

        &__lead {
            padding: 24px 0;

            &-title {
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px;
                padding: 24px 0 48px;
                border-bottom: 1px solid #e8e8e8;
                text-align: center;
            }

            &-text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                padding: 24px 10%;

                p:not(:last-of-type) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    &__input {
        background: #fff;
        padding: 24px;
        margin-bottom: 48px;
        border-radius: 8px;
        box-sizing: border-box;

        &-label {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 3px;
            display: block;
            letter-spacing: 0.014em;
        }

        &-input,
        &-textarea {
            width: 100%;
        }

        &-textarea {
            min-height: 240px;
            resize: vertical;
        }

        &__bottom {
            padding-top: 24px;
            margin-top: 24px;
            border-top: 1px solid #e8e8e8;
        }

        &__publishSetting {
            gap: 0 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &-title {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #8a8883;
                margin-right: 5px;
                position: relative;
                top: -2px;
            }

            &-select {
                padding: 0;
                border: none;
            }

            &-shitagaki {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #d7a600;
                background: #fff;
                padding: 8px 20px 7px 20px;
                border: 1px solid #e1e1e1;
                border-radius: 18px;
                cursor: pointer;
                box-sizing: border-box;
                letter-spacing: 0.014em;
                transition-duration: 0.2s;

                &:hover {
                    color: #000;
                    background: #ffcc1d;
                    border: 1px solid #ffcc1d;
                }
            }

            &-publish {
                padding: 8px 20px 7px 20px;
                margin-left: 0;
                gap: 0 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                &::after {
                    content: '';
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #000;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    &__list {
        margin-bottom: 48px;
        gap: 24px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        &__item {
            color: #151208;
            width: calc((100% - 24px) / 2);
            background: #fff;
            padding: 24px;
            display: block;
            border-radius: 8px;
            text-decoration: none;
            box-sizing: border-box;
            transition-duration: 0.2s;

            &:hover {
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 6px 12px rgba(0, 0, 0, 0.08),
                    0 1px 16px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08);
            }

            &-title {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                padding-bottom: 16px;
                margin-bottom: 16px;
                border-bottom: 1px solid #e8e8e8;
                letter-spacing: 0.008em;
            }

            &-text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .content {
        padding: 24px 8px 56px 8px;
        box-sizing: border-box;

        &__inner {
            max-width: 100%;
        }
    }

    .dashboard {
        &__alert {
            padding: 8px;

            &-num {
                height: 24px;
                padding: 0 10px;
            }
        }

        &__activity {
            padding: 16px 8px;
        }

        &__status {
            flex-wrap: wrap;

            &-dl {
                margin-bottom: 16px;
                text-align: left;

                dt {
                    margin-bottom: 4px;
                }

                dd {
                    background: #fff;
                    padding: 0;
                    justify-content: flex-start;
                }

                &:first-of-type dd {
                    border-radius: 0;
                }

                &:last-of-type dd {
                    border-radius: 0;
                }
            }
        }

        &__mensetsu {
            .slick-next,
            .slick-prev {
                width: 24px;
                height: 24px;
                z-index: 100;
            }

            .slick-next {
                right: 0;
            }

            .slick-prev {
                left: 0;
            }

            .dashboard__btn {
                justify-content: center;
            }

            .slick-slide {
                max-width: calc(80vw - 16px);
                padding: 16px 8px;
                margin: 0 8px;
            }

            &__status {
                flex-wrap: wrap;

                &-dl {
                    dt {
                        margin-bottom: 4px;
                        padding: 0;
                    }

                    dd {
                        min-height: inherit;
                        background: #fff;
                        padding: 0;
                        margin-bottom: 16px;
                    }

                    &:nth-child(n) {
                        width: 100%;

                        dt {
                            padding: 0;
                        }

                        dd {
                            padding: 0;
                            border-radius: 0;
                        }
                    }
                }
            }
        }

        &__job {
            gap: 16px 0;

            &__item {
                width: 100%;
                padding: 16px 8px;
            }
        }

        &__jobL {
            &__item {
                padding: 16px 8px;

                &-title {
                    font-size: 16px;
                    margin-bottom: 16px;
                }

                &-dl {
                    margin-bottom: 16px;
                    gap: 8px 0;

                    dt {
                        width: 30%;
                        margin-right: 0;
                    }

                    dd {
                        width: 70%;
                        margin-right: 0;
                    }
                }
            }
        }

        &__addJob {
            padding: 16px 8px;

            &__input {
                gap: 0;

                &-input {
                    width: 100%;
                }

                .btn {
                    margin: 16px auto 0;
                }
            }
        }
    }

    .fArticle {
        &__kv {
            padding: 16px 8px;

            &__img {
                img {
                    max-width: 100%;
                }
            }

            &__lead {
                padding: 0;

                &-title {
                    font-size: 18px;
                    line-height: 24px;
                    padding: 16px 0;
                    text-align: left;
                }

                &-text {
                    padding: 16px 0;
                }
            }
        }

        &__input {
            padding: 16px 8px;

            &-textarea {
                min-height: 160px;
            }

            &__bottom {
                padding-top: 16px;
                margin-top: 16px;
            }

            &__publishSetting {
                gap: 16px 0;
                flex-wrap: wrap;

                &-title {
                    width: 30%;
                    margin-right: auto;
                }

                &-select {
                    width: 30%;
                }

                &-shitagaki {
                    width: calc((100% - 16px) / 2);
                }

                &-publish {
                    width: calc((100% - 16px) / 2);
                    margin-left: auto;
                }
            }
        }

        &__list {
            gap: 16px 0;

            &__item {
                width: 100%;
                padding: 16px 8px;

                &-title {
                    font-size: 16px;
                }
            }
        }
    }
}
