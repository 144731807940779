// notification

.notification {
    width: 100vw;
    background: #f5f4ef;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &__leftCont {
        width: 300px;
        padding: 24px;
        box-sizing: border-box;
    }

    &__leftContInner {
        width: 100%;
        padding: 23px;
        box-sizing: border-box;
        border-radius: 16px;
        background: #fff;
    }

    &__inner {
        width: 600px;
        padding: 24px 0 24px 24px;
        box-sizing: border-box;
    }

    &__rightHeader {
        width: 100%;
        padding: 24px 24px 16px 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
    }

    &__list {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    &__listSwitch {
        width: 50px;
        position: relative;

        label {
            height: 30px;
            background: #eee;
            display: block;
            border-radius: 30px;
            cursor: pointer;
            transition: 0.3s;
        }

        input[type='checkbox'] {
            display: none;

            &:checked + label {
                background: #ffcc1d;
            }

            &:checked ~ .notification__listSwitchImg {
                transform: translateX(20px);
                background: #fff;
            }
        }
    }

    &__listSwitchImg {
        width: 20px;
        height: 20px;
        background: #aaa;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        pointer-events: none;
        transition: 0.3s;
    }
}

@media screen and (max-width: 950px) {
    .notification {
        flex-flow: column-reverse;
        justify-content: flex-end;
        padding-bottom: 52px;

        &__inner {
            width: 100%;
            max-width: 100%;
            order: 1;
            padding: 24px;
        }

        &__leftCont {
            width: 100%;
            max-width: unset;
            order: 3;
        }
    }
}

@media screen and (max-width: 768px) {
    .notification {
        flex-flow: column-reverse;
        justify-content: flex-end;
        padding-bottom: 52px;

        &__leftCont {
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin: 0 auto;
        }

        &__leftContInner {
            width: 100%;
            padding: 24px 16px;
            border-radius: 0;
            top: 0;
            border-bottom: 1px solid #e8e8e8;
        }

        &__leftTitle {
            font-size: 14px;
        }

        &__inner {
            width: 100%;
            max-width: 100%;
            padding: 0;
            order: 1;
        }

        &__rightCont {
            width: 100%;
        }

        &__rightHeader {
            width: 100vw;
            border-radius: 0;
            padding: 16px;
        }

        &__rightMain {
            border-radius: 0;
            padding: 0;
        }

        &__rightMainHeader {
            width: 100%;
            overflow-x: auto;
            padding: 10px 4% 11px 4%;
            position: sticky;
            top: 56px;
            left: 0;
            background: #fff;
            z-index: 11;
        }

        &__list {
            li {
                font-size: 14px;

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
