.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #e1e1e1;
    overflow: hidden;
    flex-shrink: 0;
    display: inline-block;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-mgr12 {
        margin-right: 12px;
    }

    //   &-s80px {
    //     width: 80px;
    //     height: 80px;
    //   }
}
