.step {
    background: #fff;

    &__inner {
        width: 632px;
        max-width: 92%;
        margin: 0 auto;
        padding-bottom: 100px;

        &-pb0{
            padding-bottom: 0;
        }
    }

    &__title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.005em;
    }

    &__sec {
        width: 100%;
    }

    &__secHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 0;

        &-pb0{
            padding-bottom: 0;
        }
    }

    &__secDl {
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: 32px;
        &-nbd{
            border-bottom: none;
        }
    }

    &__secDt {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-bottom: 8px;
        margin-top: 24px;

        &-mt40{
            margin-top: 40px;
        }
    }

    &__secDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: left;

    }

    &__secInDl {}

    &__secInDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8A8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 8px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        &-mt0{
            margin-top: 0;
        }

    }

    &__secInDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__ddTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        &-mgt16{
            margin-top: 16px;
        }

    }

    &__ddBox {
        display: inline-block;
        min-width: 416px;
        margin-top: 8px;
        padding: 16px;
        background: #F8F7F5;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        color: #151208;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__ddBoxText {
        &-mgt4 {
            margin-top: 4px;
        }
    }

    &__ddBoxDl {
        display: flex;
        margin-top: 4px;
    }

    &__ddBoxDt {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8A8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 16px;

    }

    &__ddBoxDtImg {
        display: inline-block;
        margin-right: 6px;
    }
    &__footer {
        width: 632px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items:center;


        &-jcsb {
            justify-content: space-between;
        }
    }
    &__footerText {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;

    }

    &__footerRight{
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 768px) {
    .step{
        &__ddBox{
            min-width: initial;
            width: 100%;
            box-sizing: border-box;
        }
        &__footer{
            flex-flow: column;
            &-jcsb{
                justify-content: space-between;
                align-items: flex-start;
            }
        }
        &__footerText{
            margin-bottom: 10px;
        }
        &__title{
            font-size: 18px;
        }
        &__secHeader{
            height: 72px;
            padding: 0;
        }
        &__secDt{
            margin-top: 32px;
        }
    }
}
