$modalZindex: 1201 !default;
$shadow3: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.04), 0px 4px 14px rgba(0, 0, 0, 0.08);
// modal
.modal {
    width: 100%;
    height: 100vh;
    background: rgba(76, 61, 8, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &-pcClear {
        background: transparent;
    }

    &-ofh {
        overflow: hidden;
    }

    &-active {
        opacity: 1;
        visibility: visible;
        z-index: $modalZindex;
    }

    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-rt {
            justify-content: flex-end;
            align-content: flex-start;
        }

        &-lt {
            justify-content: flex-start;
            align-content: flex-start;
        }

        &-spAiFe {
            align-items: flex-end;
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__cont {
        z-index: $modalZindex + 1;
    }

    &__contA {
        display: none;
        width: 378px;
        max-width: 100%;
        padding: 24px 24px 14px 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06), 0px 12px 24px rgba(0, 0, 0, 0.06),
            0px 1px 32px rgba(0, 0, 0, 0.06);
    }

    &__contB {
        display: none;
        min-width: 576px;
        max-width: 100%;
        padding: 24px 24px 14px 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06), 0px 12px 24px rgba(0, 0, 0, 0.06),
            0px 1px 32px rgba(0, 0, 0, 0.06);
    }

    &__contC {
        display: none;
        width: 576px;
        max-width: 92%;
        max-height: 90%;
        overflow-y: auto;
        padding: 24px 24px 14px 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06), 0px 12px 24px rgba(0, 0, 0, 0.06),
            0px 1px 32px rgba(0, 0, 0, 0.06);

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }

        &-w474 {
            width: 474px;
            max-width: 92%;
        }

        &-w70p {
            width: 70%;
        }
    }

    &__contE {
        display: none;
        min-width: 368px;
        background-color: #fff;
        border-radius: 24px;
        position: relative;

        .modal {
            &__contHeader {
                background: none;
                padding: 16px 24px;

                &:after {
                    width: 100%;
                    left: 0;
                }
            }

            &__contTitle {
                margin: 0;
            }

            &__scheduleConfArea {
                padding: 24px;

                &:after {
                    width: 100%;
                    left: 0;
                }
            }

            &__scheduleConfItem {
                margin-top: 8px;
                padding: 4px 0;

                &:hover {
                    background: #fff4d1;
                }

                &:first-child {
                    margin: 0;
                }
            }

            &__btnArea {
                padding: 12px 24px;
            }
        }
    }

    &__contF {
        display: none;
        max-width: 344px;
        background-color: #fff;
        border-radius: 8px;
        position: relative;

        .modal {
            &__contHeader {
                background: none;
                padding: 16px 24px;

                &:after {
                    width: 100%;
                    left: 0;
                }
            }

            &__contTitle {
                margin: 0;
            }

            &__btnsDescription {
                padding: 24px 22px 16px 24px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.014em;
                color: #151208;
            }

            &__btnArea {
                padding: 12px 24px 20px 24px;
                flex-direction: column;
            }

            &__btn {
                width: 100%;
                margin-top: 12px;
                margin-left: 0;
                border: none;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &__btnYellow {
        background: #ffcc1d;
        color: #151208;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        transition-property: color background-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        max-width: unset !important;

        &:hover:not(:disabled) {
            color: #000;
            background-color: #d7a600;
        }
    }

    &__btnGreen {
        border: none;
        background: #3db19f;
        color: #ffffff;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        max-width: unset !important;
    }

    &__btnCancel {
        background-color: #fff;
        border: 1px solid #e1e1e1 !important;
        color: #cd1818;
        max-width: unset !important;
    }

    &__inputRadio {
        &[type='radio'] {
            display: none;

            &:checked + .modal__labelRadio::before {
                border: 1px solid #d7a600;
            }

            &:checked + .modal__labelRadio::after {
                opacity: 1;
            }

            &:disabled + .modal__labelRadio {
                opacity: 0.5;
            }
        }
    }

    &__labelRadio {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 32px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;

        &:before {
            background: #fff;
            border: 1px solid #8a8883;
            border-radius: 50%;
            content: '';
            display: block;
            height: 16px;
            left: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
        }

        &:after {
            background: #d7a600;
            border-radius: 50%;
            content: '';
            display: block;
            height: 10px;
            left: 10px;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
        }
    }

    &__contSlideCont {
        width: 848px;
        max-width: 100%;
        height: 100vh;
        overflow: hidden;
        background: #f5f4ef;
        padding: 0 24px 24px 24px;
        overflow-y: scroll;
        // display: none;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }

        &-w400 {
            width: 400px;
            height: calc(100vh - 56px);
            margin-top: 56px;
            max-width: 100%;
            background: #fff;
            box-shadow: $shadow3;
        }
    }

    &__contSlideCont2 {
        width: 796px;
        max-width: 100%;
        height: 100vh;
        overflow: hidden;
        background: #fff;
        padding: 0 24px 24px 24px;
        overflow-y: scroll;
        // display: none;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition-duration: .3s;
        transition-timing-function: ease-in-out;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #C4C4C4;
        }

    }

    &__contSearchInput {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 12px;
        background: #f5f4ef;
        border-radius: 4px;
        border: 1px solid transparent;
        width: 100%;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #8a8883;
    }

    &__searchInputArea {
        width: 65%;
        position: relative;
    }

    &__adCont {
        display: none;
        width: 70%;
        max-width: 100%;
        max-height: 90vh;
        // overflow-y: hidden;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06), 0px 12px 24px rgba(0, 0, 0, 0.06),
            0px 1px 32px rgba(0, 0, 0, 0.06);

        position: relative;

        &-w331 {
            width: 331px;
        }
    }

    &__adContInner {
        overflow-y: scroll;
        width: 100%;
        height: 80vh;
        padding: 0 0 100px 0;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
    }

    &__adContFooter {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 16px 25px;
        border-radius: 0 0 16px 16px;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.04), 0px 4px 14px rgba(0, 0, 0, 0.08);
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__fullScreenCont {
        width: 100%;
        height: 100%;
    }
    &__fullScreenContInner {
        width: 78%;
        height: 90%;
        margin: 5% auto;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;
    }

    &__contHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 17px;
        position: relative;
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 10;

        &::after {
            content: '';
            display: block;
            width: calc(100% + 48px);
            height: 1px;
            background: #e8e8e8;
            position: absolute;
            bottom: 0;
            left: -24px;
        }

        &-pd {
            padding: 24px 24px 16px 24px;
            box-sizing: border-box;

            &::after {
                width: 100%;
                left: 0;
            }
        }

        &-fixed {
            width: 100%;
            background: #fff;
            padding: 20px 24px;
            // box-sizing: border-box;
            position: sticky;
            top: 0;
            right: 0;
            transform: translateX(-24px);
            z-index: 10;
        }
    }

    &__contTitleWrap {
        display: flex;
        align-items: center;
    }

    &__contTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 8px;

        // margin-bottom: 8px;
        &-mgr {
            margin-right: 16px;
        }

        &-num {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
        }

        &-mini {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.014em;
        }

        &-mgb0 {
            margin-bottom: 0;
        }
    }

    &__close {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 24px;
        border: 1px solid transparent;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        transition-duration: 0.2s;

        &::before {
            content: '';
            display: block;
            width: 2px;
            height: 20px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-135deg);
        }

        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 20px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-45deg);
        }

        &:hover {
            border: 1px solid #151208;
        }
    }

    &__textArea {
        margin-bottom: 16px;
    }

    &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__btnArea {
        display: flex;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;

        &-fe {
            justify-content: flex-end;
        }
    }

    &__btn {
        padding: 6px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 18px;
        margin-left: 15px;
        cursor: pointer;
        transition-duration: 0.2s;

        &:disabled {
            opacity: 0.5;
        }

        &-a {
            border: 1px solid #e1e1e1;
            color: #d7a600;
            background: #fff;

            &:hover:not(:disabled) {
                border: 1px solid #d7a600;
            }
        }

        &-b {
            background: #cd1818;
            border: 1px solid #cd1818;
            color: #fff;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                0px 1px 12px rgba(0, 0, 0, 0.06);

            &:hover:not(:disabled) {
                box-shadow: none;
                opacity: 0.7;
            }
        }

        &-c {
            background: #f8f7f5;
            color: #b8b7b4;
            border: 1px solid #f8f7f5;

            &-active {
                background: #ffcc1d;
                border: 1px solid #ffcc1d;
                color: #151208;
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                    0px 1px 12px rgba(0, 0, 0, 0.06);

                &:hover {
                    box-shadow: none;
                    opacity: 0.7;
                }
            }
        }
    }

    &__schedule {
        list-style: none;
        width: 100%;
        padding: 2px 0 24px 0;
        box-sizing: border-box;
        position: relative;
        counter-reset: number 0;

        &::after {
            content: '';
            display: block;
            width: calc(100% + 48px);
            height: 1px;
            background: #e8e8e8;
            position: absolute;
            bottom: 0;
            left: -24px;
        }

        // border-bottom: 1px solid #e8e8e8;
    }

    &__scheduleItem {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 22px;
        counter-increment: number 1;

        &-mgr {
            margin-right: 30px;
        }

        &-144 {
            width: 144px;
        }

        &-mgt {
            margin-top: 22px;
        }
    }

    &__scheduleItemTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 30px;

        &::before {
            content: '候補' counter(number);
        }
    }

    &__scheduleSelect {
        padding: 12px 30px 12px 12px !important;
    }

    &__schedulePlus {
        margin-left: 15px;
        cursor: pointer;
    }

    &__scheduleMainus {
        margin-left: 23px;
        cursor: pointer;
    }

    &__scheduleMainusIcon {
        &-disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &__scheduleConfArea {
        width: 100%;
        padding: 2px 0 24px 0;
        box-sizing: border-box;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: calc(100% + 48px);
            height: 1px;
            background: #e8e8e8;
            position: absolute;
            bottom: 0;
            left: -24px;
        }
    }

    &__scheduleConfTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    &__scheduleConf {
        list-style: decimal;
    }

    &__scheduleConfItem {
        display: flex;
        margin-top: 10px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
    }

    &__scheduleConfTime {
        margin-left: 7px;
    }

    &__contForm {
        position: relative;
        padding-bottom: 30px;

        &::after {
            content: '';
            display: block;
            width: calc(100% + 48px);
            height: 1px;
            background: #e8e8e8;
            position: absolute;
            bottom: 0;
            left: -24px;
        }
    }

    &__contFormDl {
        width: 100%;
        padding: 25px 0 0 0;
    }

    &__contFormDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 20px;
    }

    &__contFormDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 30px;
    }

    &__contFormDdText {
        height: 256px;
        overflow-y: auto;
    }

    &__adSearchArea {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 24px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__adSearch {
        width: 100%;
        background: #f5f4ef;
        padding: 12px;
        box-sizing: border-box;
        border: none;
    }

    &__contMain {
        height: 100%;

        &-flex {
            display: flex;
            justify-content: space-between;
        }

        &-pdb {
            padding-bottom: 24px;
        }

        &-noh {
            height: auto;
        }
    }

    &__contMainInner {
        width: 824px;
        max-width: 100%;
        margin: 16px auto;
        background: #fff;
        border-radius: 16px;
    }

    &__choiceContInner {
        overflow-y: scroll;
        width: 100%;
        height: 90vh;
        padding: 0 0 72px 0;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
    }

    &__choiceContInner1 {
        overflow-y: scroll;
        width: 100%;
        // height: 90vh;
        height: auto;
        max-height: 90vh;
        padding: 0 0 72px 0;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
    }

    &__contMainLeft {
        // width: 272px;
        height: 100%;
        width: 33%;
        border-right: 1px solid #e8e8e8;
        padding-bottom: 50px;
    }

    &__contMainRight {
        // width: 544px;
        width: 67%;
        padding: 34px 24px 20px 24px;
        box-sizing: border-box;
    }

    &__contMainRight1 {
        // width: 544px;
        width: 100%;
        padding: 34px 24px 20px 24px;
        box-sizing: border-box;
    }

    &__contMainRightCont {
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        display: none;
        visibility: hidden;
        opacity: 0;

        &-active {
            display: block;
            visibility: visible;
            opacity: 1;
        }
    }

    &__contMainRightInner {
        padding-left: 16px;
        box-sizing: border-box;
    }

    &__contMainLeftUl {
        width: 100%;
        padding-top: 16px;
        list-style: none;
    }

    &__contMainLeftLi {
        width: 100%;
        padding: 10px 24px;
        box-sizing: border-box;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition-duration: 0.2s;

        &:hover {
            background: #ffcc1d;
        }

        &-active {
            background: #ffcc1d;
        }

        &-num {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #3db19f;
            //styleName: En - 16 Medium;
            font-family: Roboto;
            font-size: 16px;
            color: #fff;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            display: none;
        }
    }

    &__job {
        margin: 24px auto;
    }

    &__searchFooter {
        padding: 16px 24px;
        box-sizing: content-box;
        // justify-content: flex-end;
        position: sticky;
        right: 0;
        bottom: 0;
        transform: translateX(-24px);
    }

    &__searchFooter2 {
        padding: 26px 0;
        box-sizing: border-box;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 768px) {
    .modal {
        background: rgba(76, 61, 8, 0.8);
        &__contC {
            padding: 14px;
        }

        &__contE {
            width: 91.46%;
            min-width: unset;
            max-width: 368px;
            border-radius: 8px;

            .modal {
                &__scheduleConfArea {
                    padding: 16px;
                }

                &__scheduleConfItem {
                    padding: 8px 0;
                }

                &__btnArea {
                    flex-direction: row;
                    justify-content: flex-end;
                }

                &__btn {
                    max-width: 119px;
                }
            }
        }

        &__contSlideCont {
            &-w400 {
                width: 100%;
                max-width: 100%;
                height: auto;
                background: #fff;
                padding: 0 4%;
                margin-top: 0;
                box-sizing: border-box;
                box-shadow: $shadow3;
            }

            &-spUp {
                transform: translateY(100%);
                top: auto;
                bottom: 0;
            }

            &-full {
                width: 100%;
                max-width: 100%;
                // height: 100%;
                background: #fff;
                padding: 0 4%;
                margin-top: 0;
                box-sizing: border-box;
            }
        }

        &__textArea {
            margin-bottom: 10px;
        }

        &-pcClear {
            background: rgba(204, 192, 148, 0.8);
            // height: 500px;
        }

        &__adCont {
            width: 100%;
            height: 100vh;
            max-height: 100vh;
        }

        &__adContInner {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        &__contMain {
            height: auto;
            // padding-bottom: 56px;
            box-sizing: border-box;
            width: 100%;
        }

        &__choiceContInner {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        &__contMainLeft {
            width: 100%;
        }

        &__contMainRight {
            display: none;
        }

        &__contMainLeftUl {
            width: 92%;
            margin: 0 auto;
        }

        &__contMainLeftLi {
            flex-flow: column;

            &-active {
                background: #fff;
            }
            &:hover {
                background: #fff;
            }

            &-text {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 12px 0;
                box-sizing: border-box;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.014em;
                margin-bottom: 12px;
            }
        }
        &__contMainLeftLiSp {
            display: flex;
            flex-flow: column;
            .modal__contMainRightContSp {
                max-height: 0;
                overflow: hidden;
                transition-duration: 0.2s;
            }
            &-open {
                .modal__contMainRightContSp {
                    max-height: 1000px;
                }
                .modal__contMainLeftLiRight {
                    &::after {
                        border-top: none;
                        border-bottom: 8px solid #000;
                    }
                }
            }
        }
        &__contMainLeftLiRight {
            display: flex;
            align-items: center;
            transition-duration: 0.2s;
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #000;
                margin-left: 15px;
            }

            &-open {
                &::after {
                    border-top: none;
                    border-bottom: 8px solid #000;
                }
            }
        }
        
        &__contTitle {
            font-size: 16px;
            letter-spacing: 0.011em;
            margin-bottom: 0;
        }

        &__adContFooter {
            border-radius: 0;

        }
        &__contMainRight-sp {
            display: block;
            width: 100%;
            max-width: 100%;
        }
    }
}
