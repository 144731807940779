.flatpickr-calendar {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 10px;

    &:before,
    &:after {
        display: none;
        opacity: 0;
    }
}

.flatpickr-innerContainer {
    width: 100%;
}

.flatpickr-rContainer {
    width: 100%;
}

.flatpickr-days {
    width: 100%;
}

.dayContainer {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.numInputWrapper {
    position: relative;
    &::after {
        content: '年';
        position: absolute;
        top: 0;
        right: 1ch;
        bottom: 0;
        margin: auto;
    }
}

.flatpickr-months {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.flatpickr-prev-month {
    position: absolute;
    right: 1.5em;
    left: auto !important;
}

.flatpickr-month {
    width: 100%;
    padding: 0 1em;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.flatpickr-current-month {
    width: 100%;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    left: 10px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.014em;
    padding: 0;
    padding-top: 3px;
}

.flatpickr-current-month input.cur-year {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.014em;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.014em;
}

.flatpickr-current-month .numInputWrapper {
    width: 5ch;
    display: inline-block;
}

.flatpickr-monthDropdown-months {
    padding: 0 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    -webkit-appearance: none;
    appearance: none;
}

.flatpickr-day {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &.today {
        background: #3db19f;
        color: #fff;
        border: none;
    }
}

.flatpickr-prev-month svg {
    transform: rotate(90deg);
}

.flatpickr-next-month svg {
    transform: rotate(90deg);
}
