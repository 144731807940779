// member
.member {
    &__mainWrap {
        width: 864px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0 4px 0;
        box-sizing: border-box;
        margin-top: 8px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
            margin-right: 2px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        justify-content: space-between;
        color: #151208;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        transition-duration: 0.2s;
        background: #fff;

        &-gray {
            color: #8a8883;
        }

        &:nth-child(1) {
            background: transparent;
        }
    }

    &__mainLiInner {
        display: flex;
        width: calc(100% - 48px);
    }

    &__mainName {
        width: 18%;
        padding: 20px 0 20px 24px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainJobMail {
        width: 28%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainAuthority {
        width: 12%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainStatus {
        width: 22%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        span {
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                flex-grow: 0;
                margin-right: 12px;
            }
        }

        &-ac {
            &::before {
                border: 1.5px solid #52d276;
                background: #52d276;
            }
        }

        &-pause {
            &::before {
                border: 1.5px solid #b8b7b4;
                background: #b8b7b4;
            }
        }

        &-archive {
            &::before {
                border: 1.5px solid #8a8883;
                background: transparent;
            }
        }

        &-col {
            flex-flow: column;
            justify-content: center;
        }

        // &-gray{
        //         display: block;
        //         color: #8A8883;
        //         &::before{
        //             display: none;
        //         }

        // }

        span {
            &.member__mainStatus-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;

                &::before {
                    display: none;
                }
            }
        }

        span {
            &.member__mainStatus-invite {
                &::before {
                    width: 17px;
                    height: 13px;
                    border-radius: 0;
                    background-image: url(../../img/mail.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        span {
            &.member__mainStatus-expired {
                color: #cd1818;

                &::before {
                    width: 18px;
                    height: 18px;
                    border-radius: 0;
                    background-image: url(../../img/mail2.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__mainCertification {
        width: 18%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainDotArea {
        width: 48px;
        padding: 20px 16px 20px 0;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
    }

    &__mainDot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            background: #fff4d1;
        }

        &.active {
            background: #fff4d1;
        }
    }

    &__mainDotText {
        width: 168px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // padding: 16px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 3;
        transform: translate(calc(-50% + 16px), 16px);
        margin: auto;
        white-space: nowrap;
        cursor: pointer;
        display: none;
    }
    &__mainDotText1 {
        width: 168px;
        //height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // padding: 16px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 3;
        transform: translate(calc(-50% + 16px), 16px);
        margin: auto;
        white-space: nowrap;
        cursor: pointer;
    }
    &__mainDotSpan {
        display: block;
        padding: 12px 16px;
        border-bottom: solid 1px #e8e8e8;

        &:first-child {
            &:hover {
                background: #fff4d1;
                border-radius: 8px 8px 0px 0px;
            }
        }

        &:last-child {
            &:hover {
                background: #fff4d1;
                border-radius: 0px 0px 8px 8px;
            }
        }
    }

    &__mainDotBg {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        // background: #000;
        z-index: 2;
        display: none;
    }

    &-dpb {
        display: block;
    }

    &-pdb0 {
        padding-bottom: 8px;
    }

    &-pdt4 {
        padding-top: 4px;
    }

    &__permissionLblModal {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }
}

@media screen and (max-width: 768px) {
    .member {
        &__mainWrap {
            padding: 0 16px 56px 16px;
        }

        &__mainTitleArea {
            padding-bottom: 16px;
        }

        &__mainLi {
            flex-direction: column;
            flex-wrap: wrap;
            gap: 12px;
            position: relative;
            padding: 16px 16px 8px 16px;

            font-weight: 400;
            letter-spacing: 0.011em;

            &.member__mainLi-gray {
                display: none;
            }
        }

        &__mainLiInner {
            width: 100%;
            flex-flow: column;

            > div {
                width: 100%;
                padding: 0;
                margin-bottom: 8px;
                font-weight: 500;
            }
        }

        &__mainName {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
        }

        &__mainDotArea {
            width: 100%;
            padding: 13px 0 0 0;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #e8e8e8;
        }

        &__modal {
            padding: 0;
            width: calc(100% - 32px);
            max-width: calc(100% - 32px);

            .form__groupCheck {
                padding: 16px;
                margin: 0;
                border-bottom: 1px solid #e8e8e8;
            }
            &-sppdb {
                padding-bottom: 4px;
            }
        }

        &__modalHeader {
            display: flex;
            justify-content: space-between;
            padding: 12px 11px 8px 16px;
            border-bottom: 1px solid #e8e8e8;

            .modal__contTitle {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.014em;
            }

            .modal__contTitle2 {
                font-size: 18px;
                font-weight: 700;
                color: #151208;
                line-height: 24px;
                letter-spacing: 0.008em;
            }

            &-pdt16 {
                padding-top: 16px;
            }

            &-nobd {
                border-bottom: none;
            }
        }

        &__modalBtnArea {
            display: flex;
            flex-flow: row nowrap;

            &.modal__btnArea {
                padding: 12px 16px;
                box-sizing: border-box;
            }
        }

        &__mainStatus {
            &-gray {
                margin-top: -3px;
            }
            // span.member__mainStatus-ac{
            //     &::before{
            //         margin-right: 8px;
            //     }
            // }
            span {
                &::before {
                    margin-right: 8px;
                }
            }
        }
    }

    .memberSpTitle {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        word-break: break-all;

        &::before {
            display: block;
            width: 8em;
            margin-right: 1em;
            content: attr(data-type);
            font-size: 14px;
            color: #8a8883;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.014em;
            white-space: nowrap;
        }

        &-spCol {
            flex-flow: column;
        }
    }
}
