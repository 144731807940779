// form

input:focus,
textarea:focus,
select:focus {
    outline: solid 1px #151208;
}

input[type='submit']:focus {
    outline: none;
}

.form {
    &__inner {
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
    }

    &__groupWrap {
        &.agent {
            .form__label {
                display: flex;

                &::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    background-image: url(../img/agent-icon.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__group {
        margin-bottom: 50px;

        &-inSup {
            margin-bottom: 35px;
        }

        &-mgt0 {
            margin-top: 0;
        }

        &-mgt {
            margin-top: 15px;
        }

        &-mgt2 {
            margin-top: 25px;
        }

        &-mgt3 {
            margin-top: 40px;
        }
        &-mgt4 {
            margin-top: 5px;
        }

        &-mgb0 {
            margin-bottom: 0;
        }

        &-w100 {
            width: 100%;
        }

        &-relative {
            position: relative;
        }

        &-w100 {
            width: 100%;
        }

        &-block {
            display: block;
        }

        &.required {
            .form__label {
                display: flex;

                &::after {
                    content: '\5FC5\9808';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.017em;
                    width: 41px;
                    height: 24px;
                    margin-left: 10px;
                    background: #fdd6cd;
                    border-radius: 12px;
                }
            }

            .form__adMainLabel {
                display: flex;

                &::after {
                    content: '\5FC5\9808';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    font-style: normal;
                    color: #151208;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.017em;
                    width: 41px;
                    height: 24px;
                    margin-left: 10px;
                    background: #fdd6cd;
                    border-radius: 12px;
                }
            }
        }

        &.success {
            .form__label {
                display: flex;

                &::after {
                    content: '';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 41px;
                    height: 24px;
                    margin-left: 10px;
                    background: #069fcf;
                    border-radius: 12px;
                    background-image: url(../img/check-icon.svg);
                    background-size: 12.5px;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }

            .form__adMainLabel {
                display: flex;

                &::after {
                    content: '';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 41px;
                    height: 24px;
                    margin-left: 10px;
                    background: #069fcf;
                    border-radius: 12px;
                    background-image: url(../img/check-icon.svg);
                    background-size: 12.5px;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        &.error {
            .form__inputText {
                border: 1px solid #cd1818;
                background: #fdd6cd;
            }

            .form__alert {
                visibility: visible;
            }

            .form__inputSup {
                color: #cd1818;
            }

            .form__inputUnitName {
                color: #cd1818;
            }

            .form__checkLabel {
                &::before {
                    border: 1px solid #cd1818;
                }

                color: #cd1818;
            }

            .form__checkLabel2 {
                &::before {
                    border: 1px solid #cd1818;
                }

                color: #cd1818;
            }

            .form__date {
                border: 1px solid #cd1818;
            }

            .form__inputTextArea {
                border: 1px solid #cd1818;
                background-color: #fdd6cd;
            }

            .form__select {
                border: 1px solid #cd1818;
            }

            .form__adText {
                border: 1px solid #cd1818;
            }
        }
    }

    &__groupFlex {
        display: flex;
        align-items: center;

        &-col {
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &__label {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;

        &-mini {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-top: 22px;
        }

        &-flex {
            display: flex;
        }

        &-mgt0 {
            margin-top: 0;
        }

        &-mgt9 {
            margin-top: 9px;
        }
        &-mgb8 {
            display: inline-block;
            margin-bottom: 8px;
        }
        &-error {
            color: #cd1818;
        }
        &-fl {
            &-error {
                display: flex;
                align-items: center;
                color: #cd1818;
            }
        }
        &-iconAf {
            display: inline-block;
            margin-left: 10px;
        }
    }

    &__inLabel {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 22px;

        &-fl {
            display: flex;
            align-items: center;
        }

        &-iconAf {
            display: inline-block;
            margin-left: 10px;
        }
    }

    &__labelSup {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #369f8f;
    }

    &__inputText {
        height: 36px;
        background: #f5f4ef;
        border: none;
        padding: 5px 10px;
        border-radius: 4px;
        box-sizing: border-box;

        &-w100 {
            width: 100%;
        }

        &-w632px {
            width: 632px;
        }

        &-w320px {
            width: 320px;
            max-width: 100%;
        }

        &-w256px {
            width: 256px;
            max-width: 100%;
        }

        &-w128px {
            width: 128px;
        }

        &-w96px {
            width: 96px;
        }

        &-w72px {
            width: 72px;
        }

        &-w10em {
            width: 10em;
        }

        &-w8em {
            width: 8em;
        }

        &-w6em {
            width: 6em;
        }

        &-w4em {
            width: 4em;
        }

        &-w3em {
            width: 3em;
        }

        &-mgr {
            margin-right: 14px;
        }

        &-textarea {
            line-height: 23px;
            min-height: 36px;
        }
    }

    &__subLabel {
        width: 600px;
        max-width: 100%;
        border: 1px solid #e8e8e8;
        padding: 6px 12px;
        margin: 10px 0px;
        box-sizing: border-box;

        .title {
            padding: 6px 12px;
            color: #8a8883;
        }

        .details {
            padding: 6px 12px;
        }
    }

    &__textAreaWrap {
        &-w600 {
            width: 600px;
            max-width: 100%;
            // height: 256px;
        }
    }

    &__inputTextArea {
        width: 100%;
        height: 256px;
        background: #f5f4ef;
        border: none;
        padding: 6px 12px;
        border-radius: 4px;
        box-sizing: border-box;

        &-h50 {
            height: 128px;
        }

        &-ha {
            height: auto;
        }

        &-lh2em {
            line-height: 2.1em;
        }
    }

    &__select {
        background: #f5f4ef;
        border: none;
        padding: 12px 100px 12px 12px;
        border-radius: 4px;
        box-sizing: border-box;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;

        &-ad {
            width: 448px;
            max-width: 100%;
            border: 1px solid #000;
        }

        &-w100 {
            width: 100%;
            padding-right: 35px;
        }
    }

    &__selectWrap {
        display: inline-block;
        margin-top: 22px;
        cursor: pointer;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0.9em;
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }

        &-mgt0 {
            margin-top: 0;
        }

        &-mgt5 {
            margin-top: 5px;
        }

        &-triA {
            &::before {
                right: 0.5em;
            }
        }

        &-w100 {
            width: 100%;
        }

        &-custom {
            optgroup {
                display: block;
                margin-top: 13px;
                padding: 8px 0;
                border-radius: 8px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                    0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
            }

            option {
                display: block;
                padding: 12px 16px;
                border-bottom: 1px solid #e8e8e8;

                &:hover {
                    background: #ffcc1d;
                }
            }
        }
    }

    &__file {
        &-dpn {
            display: none;
        }

        &__portfolioOgImgArea {
            width: 150px;
            height: 80px;
            margin-right: 8px;
            margin-top: 8px;
            font-size: 6px;
        }
    }

    &__fileLabel {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        border: 1px solid #e1e1e1;
        padding: 6px 20px;
        border-radius: 18px;
        cursor: pointer;

        &-plus {
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                content: '';
                display: block;
                width: 17px;
                height: 17px;
                background-image: url(../img/plus-icon-recom.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 9px;
            }
        }
    }

    &__fileText {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        padding: 4px 36px 4px 12px;
        box-sizing: border-box;
        background: #fff4d1;
        border-radius: 16px;
        margin-bottom: 8px;
        position: relative;

        &-ib {
            display: inline-block;
        }

        &-invalid {
            border: 1px solid #cd1818;
        }
    }

    &__fileTextDel {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            background: #000;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-135deg);
        }

        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            background: #000;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-45deg);
        }
    }

    &__inputBeforeText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 5px;
    }

    &__inputMiddleText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 5px;
        margin-right: 5px;
    }

    &__inputAfterText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 5px;
    }

    &__supArea {
        margin-top: 3px;

        &-flex {
            display: flex;
            justify-content: space-between;
        }

        &-w256px {
            width: 256px;
            max-width: 100%;
        }

        &-jcfe {
            justify-content: flex-end;
        }
    }

    &__inputSup {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        text-align: right;
        &-end {
            align-self: flex-end;
            flex-grow: 1;
        }
    }

    &__alert {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #cd1818;
        line-height: 16px;
        letter-spacing: 0.017em;
        visibility: hidden;
    }

    &__inputUnitName {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 5px;
    }

    &__checkLabel {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: relative;
        cursor: pointer;
        // padding: 5px 0 5px 40px;
        padding-left: 40px;

        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border: 1px solid #8a8883;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 5px;
        }

        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            background: #dead05;
            opacity: 0;
            width: 10px;
            height: 10px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 9px;
        }

        &-mgl0 {
            padding-left: 30px;

            &::before {
                left: 0;
            }

            &::after {
                left: 4px;
            }
        }

        &-disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &-ib {
            display: inline-block;
        }

        &-gr {
            color: #8a8883;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.017em;
            margin-top: -1px;
            display: block;
        }

        &-subGr {
            padding-left: 40px;
        }
    }

    &__checkLabel2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: relative;
        cursor: pointer;
        padding-left: 35px;
        // padding: 5px 0 5px 35px;
        display: block;

        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border: 1px solid #8a8883;
            border-radius: 2px;
            background-color: #fff;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 5px;
        }

        &::after {
            content: '';
            display: block;
            background: #ffcc1d;
            border: 1px solid #ffcc1d;
            border-radius: 2px;
            opacity: 0;
            width: 16px;
            height: 16px;
            background-image: url(../img/check.svg);
            background-size: 13px 10px;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 5px;
        }

        &-mgl0 {
            padding-left: 30px;

            &::before,
            &::after {
                left: 0;
            }
        }

        &-disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &__checkItem {
        display: none;

        &:checked + label {
            &::before {
                border: 1px solid #dead05;
            }

            &::after {
                opacity: 1;
            }
        }
        &.checked + label {
            &::before {
                border: 1px solid #dead05;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &__checkItemModal {
        display: none;

        &.checked + label {
            &::before {
                border: 1px solid #dead05;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &__checkItem2 {
        display: none;

        &:checked + label {
            &::before {
                border: 1px solid #dead05;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &__groupCheckArea {
        &-mgb {
            margin-bottom: 15px;
        }
    }

    &__groupCheck {
        margin-top: 15px;

        &-flex {
            display: flex;
            flex-flow: row wrap;
        }

        &-w50 {
            width: 50%;
        }
    }

    &__groupCheckItem {
        margin-bottom: 10px;

        &-w50 {
            width: 200px;
            max-width: 100%;
            margin-bottom: 15px;
        }

        &-w502 {
            width: calc(100% - 200px);
            max-width: 100%;
            min-width: 200px;
            // margin-bottom: 15px;
        }

        &-w50b {
            width: 140px;
            max-width: 100%;
            margin-bottom: 15px;
        }

        &-w50b2 {
            width: calc(100% - 140px);
            max-width: 100%;
            min-width: 140px;
            // margin-bottom: 15px;
        }

        &-mgb16 {
            margin-bottom: 16px;
        }
        &-mgb24 {
            margin-bottom: 24px;
        }
    }

    &__interlock {
        // margin-top: 15px;
        display: none;
        opacity: 1;
        transition-duration: 0.5s;

        &-active {
            display: block;
            opacity: 1;
        }

        &-flex {
            &.form__interlock {
                &-active {
                    display: flex;
                    opacity: 1;
                }
            }
        }
    }

    &__date {
        height: 36px;
        background: #f5f4ef;
        border: none;
        padding: 5px 10px;
        // border: 1px solid #151208;
        border-radius: 4px;
        box-sizing: border-box;
    }

    &__sup {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        margin-top: 7px;

        &-mgt0 {
            margin-top: 0;
        }
    }

    // 住所
    &__adWrap {
        max-width: 100%;
        display: inline-block;
        margin-top: 22px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0.9em;
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }
    }

    &__adText {
        background: #f5f4ef;
        border: none;
        padding: 0 100px 0 12px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 4px;
        box-sizing: border-box;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        width: 448px;
        max-width: 100%;
        height: 36px;
        // border: 1px solid #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;

        &-w240px {
            width: 240px;
            max-width: 100%;
            border: none;
            padding: 0 30px 0 12px;
        }
    }

    &__adInputArea {
        width: 816px;
        max-width: 90vw;
        height: 400px;
        max-height: 90vh;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px #00000014;
        box-shadow: 0px 1px 16px 0px #00000014;
        box-shadow: 0px 6px 12px 0px #00000014;
        box-shadow: 0px 4px 8px 0px #00000014;
        position: absolute;
        top: 36px;
        left: 0;
        z-index: 5;
        overflow-y: scroll;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.2s;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }

        &-active {
            visibility: visible;
            opacity: 1;
        }
    }

    &__adInputAreaInner {
        background: #fff;
    }

    &__adInputAreaBack {
        width: 100vw;
        height: 100vh;
        // background: #000;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &__adSearchArea {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;

        &-w100 {
            padding: 24px 0;
        }

        &-bdn {
            border: none;
        }
        &-pd {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    &__adSearchText {
        width: 6em;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 10px;
    }

    &__adSearch {
        width: calc(100% - 6em - 10px);
        background: #f5f4ef;
        padding: 12px;
        box-sizing: border-box;
        border: none;

        &-w100 {
            width: 100%;
        }
    }

    &__adAreaArea {
        width: 100%;
        padding: 16px 24px 16px 24px;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;

        &-w100 {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__adAreaTitle {
        width: 100%;
        font-size: 14px;
        font-style: normal;
        color: #8a8883;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__groupCheckAd {
        display: inline-block;
        margin-top: 15px;
        margin-right: 25px;
    }

    &__adMainWrap {
        padding: 20px 16px;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;
        width: 448px;
        max-width: 100%;
        position: relative;
        display: none;

        &-mgt {
            margin-top: 13px;
        }
    }

    &__adGroup {
    }

    &__adMainLabel {
        font-size: 14px;
        font-style: normal;
        color: #8a8883;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 10px;

        &-icon {
            margin-right: 10px;
        }
    }

    &__postCodeWrap {
        display: inline-block;
        padding: 0;
        position: relative;

        &::before {
            content: '〒';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }
    }

    &__inputPostCode {
        padding-left: 2em;
    }

    &__adPlus1 {
        font-size: 14px;
        color: #dead05;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;
        margin-top: 28px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e8e8e8;
        display: none;
        cursor: pointer;

        &-disableText {
            color: #b8b7b4;
        }

        &-disabled::before {
            filter: grayscale(100%);
        }

        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(../img/plus.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__adPlus2 {
        width: 448px;
        max-width: 100%;
        font-size: 14px;
        color: #dead05;
        background: #fff;
        border: 1px solid #dead05;
        border-radius: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
        padding: 10px;
        display: none;
        cursor: pointer;

        &:disabled::before {
            filter: grayscale(100%);
        }

        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(../img/plus.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__adMainRemove {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    // dropDown

    &__dropWrap {
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    &__dropInputWrap {
        display: inline-block;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0.9em;
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }
    }

    &__dropText {
        background: #f5f4ef;
        border: none;
        padding: 0 100px 0 12px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 4px;
        box-sizing: border-box;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        width: 448px;
        max-width: 100%;
        height: 36px;
        border: 1px solid #000;

        &-w240px {
            width: 240px;
            max-width: 100%;
            border: none;
            padding: 0 30px 0 12px;
        }
        &-w280px {
            width: 280px;
            max-width: 100%;
            border: none;
            padding: 0 30px 0 12px;
        }
    }

    &__dropInputArea {
        width: 545px;
        max-width: 90vw;
        height: 300px;
        max-height: 90vh;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px #00000014;
        box-shadow: 0px 1px 16px 0px #00000014;
        box-shadow: 0px 6px 12px 0px #00000014;
        box-shadow: 0px 4px 8px 0px #00000014;
        position: absolute;
        top: 36px;
        left: 0;
        z-index: 5;
        overflow-y: scroll;
        visibility: hidden;
        opacity: 0;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }

        &-active {
            visibility: visible;
            opacity: 1;
        }
    }

    &__dropInputAreaInner {
    }

    &__dropInputAreaBack {
        width: 100vw;
        height: 100vh;
        // background: #000;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &__dropSearchArea {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__dropSearchText {
        width: 10em;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 10px;
    }

    &__dropSearch {
        width: calc(100% - 10em - 10px);
        background: #f5f4ef;
        padding: 12px;
        box-sizing: border-box;
        border: none;
    }

    &__dropUl {
        list-style: none;
        width: 100%;
        padding: 16px 24px 16px 24px;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
    }

    &__dropLi {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;

        &-top {
            padding-top: 0;
        }
    }

    &__dropNameArea{
        display: flex;
    }

    &__dropName {
        width: 10em;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 1em;
    }

    &__dropTelNum {
        width: 13em;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
        text-align: left;
        margin-right: 1em;
    }

    &__dropBtnWrap {
        // width: calc(100% - 27em);
        display: flex;
        justify-content: flex-end;
    }

    // &__dropBtn {
    //     font-size: 14px;
    //     font-style: normal;
    //     font-weight: 500;
    //     color: #151208;
    //     line-height: 24px;
    //     letter-spacing: 0.014em;
    //     background: #FFCC1D;
    //     box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
    //     border-radius: 18px;
    //     padding: 7px 22px;
    //     border: none;
    //     cursor: pointer;

    // }

    &__portfolioArea {
        width: 667px;
        max-width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        margin-top: 15px;
    }

    &__portfolioAreaHeader {
        display: flex;
        justify-content: flex-end;
    }

    &__portfolioAreaHeaderImg {
        cursor: pointer;
    }

    &__portfolioOg {
        width: 488px;
        max-width: 100%;
        height: 115px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
        box-sizing: border-box;
        background: #f8f7f5;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        margin-top: 10px;
        &-w582 {
            width: 582px;
            .form__portfolioOgTextArea {
                width: 340px;
            }
        }
        &-inLink {
            padding: 0 18px 0 0;
        }
    }
    &__portfolioOg1 {
        width: 400px;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
        box-sizing: border-box;
        &-w582 {
            width: 582px;
            .form__portfolioOgTextArea {
                width: 340px;
            }
        }
        &-inLink {
            padding: 0 10px 0 0;
        }
    }
    &__portfolioOg-2{
        width: 80%;
        max-width: 100%;
        height: 115px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
        box-sizing: border-box;
        background: #f8f7f5;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        margin-top: 10px;
        &-w582 {
            width: 582px;
            .form__portfolioOgTextArea {
                width: 340px;
            }
        }
        &-inLink {
            padding: 0 18px 0 0;
        }
    }
    &__portfolioOgLinkArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0 18px 18px;
        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }
    }

    &__portfolioOgImgArea {
        width: 150px;
        height: 80px;
        margin-right: 8px;
    }

    &__portfolioOgImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__portfolioOgTextArea {
        width: calc(100% - 160px);
    }

    &__portfolioOgTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 4px;
        &-mgb0 {
            margin-bottom: 0;
        }
    }

    &__portfolioOgLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.005em;
        margin-bottom: 4px;
        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #d7a600;
            text-decoration: none;
            opacity: 0.7;
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }

    &__portfolioOgText {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 20px;
        letter-spacing: 0.017em;
    }
    &__portfolioOgText1 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        letter-spacing: 0.017em;
    }

    &__portfolioOgIconAreaWrap {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
    }

    &__portfolioOgIconArea {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        transition-duration: 0.2s;
        cursor: pointer;
        position: relative;

        &:hover {
            background: #fff4d1;
        }
    }

    &__portfolioPlus {
        font-size: 14px;
        color: #dead05;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 10px;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-image: url(../img/plus.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__portfolioFooter {
        padding-top: 18px;
        border-top: 1px solid #e8e8e8;
        margin-top: 18px;
    }

    &__textContArea {
    }

    // &__submit1 {
    //     display: block;
    //     height: 36px;
    //     padding: 0 24px;
    //     font-size: 14px;
    //     font-style: normal;
    //     font-weight: 500;
    //     color: #B8B7B4;
    //     line-height: 24px;
    //     letter-spacing: 0.014em;
    //     border: none;
    //     outline: none;
    //     background: #F8F7F5;
    //     border-radius: 18px;
    //     margin-left: 12px;
    //     flex-shrink: 0;

    // }

    // customform

    &__customSelectArea {
        position: relative;

        &-mgl10 {
            margin-left: 10px;
        }

        &-mgt5 {
            margin-top: 5px;
        }

        &-mgt16 {
            margin-top: 16px;
        }

        &-w100 {
            width: 100%;
        }

        &-w178 {
            width: 178px;
            max-width: 100%;
        }

        &-w296 {
            width: 296px;
            max-width: 100%;
        }

        &-2 {
            .form {
                &__customSelect {
                    width: 216px;
                    padding: 6px 12px;

                    &::placeholder {
                        color: #151208;
                    }
                }
            }
        }

        &-flex {
            display: flex;
            align-items: center;
        }
    }

    &__customSelectBack {
        width: 100%;
        height: 100vh;
        // background: #000;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        display: none;
    }

    &__customSelectWrap {
        display: inline-block;
        cursor: pointer;
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0.9em;
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }
    }

    &__customSelectWrap-2 {
        display: inline-block;
        cursor: pointer;
        width: 100%;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 14.6em;
            bottom: 0;
            margin: auto;
            width: 0;
            height: 0;
            padding: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }
    }

    &__customSelect {
        height: 36px;
        background: #f5f4ef;
        border: none;
        padding: 5px 10px;
        border-radius: 4px;
        box-sizing: border-box;
        color: #151208;
        // cursor: pointer;

        &-w {
            background: #fff;
        }

        &-w100 {
            width: 100%;
        }

        &-w256 {
            width: 256px;
        }
    }

    &__customSelectList {
        list-style: none;
        padding: 0;
        box-sizing: border-box;
        // margin-top: 13px;

        border-radius: 8px;
        transition-duration: 0.2s;
        position: absolute;
        top: 49px;
        left: 0;
        width: 100%;
        z-index: 15;
        background: #fff;
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        overflow: hidden;

        &-active {
            display: block;
            visibility: visible;
            opacity: 1;
            max-height: 10000px;
            padding: 8px 0;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        }

        &-t0 {
            top: 36px;
        }
    }

    &__customSelectItem {
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-bottom: 1px solid #e8e8e8;
        transition-duration: 0.2s;
        cursor: pointer;

        &:nth-child(1) {
            border-top: 1px solid #e8e8e8;
        }

        &:hover {
            background: #ffcc1d;
            font-weight: 500;
        }
    }

    &__customSelectTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #000000;
        margin-bottom: 4px;
    }

    &__syokusyuAdd {
        display: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 14px;
    }

    &__header-jc {
        justify-content: center;
    }

    &__group-inSup2 {
        margin-bottom: 24px;
    }

    &__inputSup2 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        margin-top: 4px;
    }

    &__group-mgb1 {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {
    .form {
        &__inner {
            width: 100%;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 30px 4%;
            &-sppd {
                padding: 0 4%;
            }
        }

        &__customSelectArea {
            &-w100sp {
                width: 100%;
            }

            &-2 {
                .form {
                    &__customSelect {
                        width: 100%;
                    }
                }
            }
        }

        &__customSelectTitle{
            margin-bottom: 16px;
        }

        &__adSearchArea {
            &-pd {
                padding-bottom: 0;
            }
        }

        &__group {
            margin-bottom: 32px;
            &-spMgb16 {
                margin-bottom: 16px;
            }
        }

        &__groupCheckItem {
            &-sp100 {
                width: 100%;
            }
        }

        &__groupCheckAd {
            width: 100%;
        }

        &__checkLabel {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
        }

        &__checkLabel2 {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
        }

        &__inputText {
            &-sp100 {
                width: 100%;
            }
        }

        &__supArea {
            &-sp100 {
                width: 100%;
            }
        }
        &__portfolioOgLinkArea {
            max-width: calc(100% - 30px);
        }
        &__portfolioOgImgArea {
            max-width: 17vw;
            max-height: 17vw;
        }

        &__portfolioOgTextArea {
            width: auto;
        }
        &__adWrap {
            width: 100%;
        }
        &__adText {
            &-w240px {
                width: 100%;
            }
        }
        &__labelSup {
            margin-top: 4px;
        }

        &__dropInputArea {
            z-index: 18;
        }

        &__dropInputAreaBack {
            background: rgba(76, 61, 8, 0.6);
            z-index: 18;
        }

        &__dropInputAreaInner {
            z-index: 20;
            position: fixed;
            width: 92%;
            height: 78%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            overflow-y: scroll;
            border-radius: 8px;
            background: #fff;
        }

        &__dropInputClose {
            width: 32px;
            min-width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-left: 16px;
            border: 1px solid transparent;
            box-sizing: border-box;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 2px;
                height: 20px;
                background: #151208;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transform: rotate(-135deg);
            }

            &::after {
                content: "";
                display: block;
                width: 2px;
                height: 20px;
                background: #151208;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transform: rotate(-45deg);
            }
        }

        &__dropSearchArea {
            padding: 12px 8px 11px 16px;
        }

        &__dropSearch {
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #8A8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            padding: 4px 12px;
            width: 100%;
        }

        &__dropSearchText {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            white-space: nowrap;
        }

        &__dropUl {
            padding: 0 16px;
        }

        &__dropLi{
            padding: 8px 0;
            &-top{
                padding-top: 8px;
            }
        }

        &__dropName{
            width: auto;
            margin-right: 16px;
        }

        &__dropTelNum {
            width: auto;
            font-size: 14px;
            font-weight: 400;
            color: #8A8883;
            line-height: 24px;
            letter-spacing: 0.014em;

        }
        &__dropBtn.btn{
            height: 36px;
        }
        &__dropText-w280px {
            width: 100%;
        }
        &__dropWrap,
        &__dropInputWrap {
            width: 100%;
        }

        &-pdrlSp16{
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;
        }
    }
}
