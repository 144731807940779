$shadow1: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
$shadow4: 5px 5px black !default;

.search {
    background: #f5f4ef;
    min-height: initial;

    &__inner {
        width: 1152px;
        max-width: 100%;
        height: calc(100vh - 56px);
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__side {
        width: 312px;
        max-width: 27%;
        height: 100%;
        overflow-y: auto;
        border-right: 1px solid #e8e8e8;
        background: #fff;
        position: sticky;
        top: 0;
        left: 0;
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }
        &-inactive::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: none;
        }
    }

    &__sideSec {
        width: 100%;
        padding: 25px 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;

        &-last {
            padding-bottom: 56px;
            box-sizing: border-box;
        }
    }

    &__groupFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    &__label1 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        margin-bottom: 8px;
        display: block;
    }

    &__label3 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        display: block;
    }

    &__keepBtn {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 16px;
        cursor: pointer;
        color: #d7a600;

        &-disabled {
            color: #8a8883;
            cursor: context-menu;
        }
    }

    &__sideSecTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 24px;
    }

    &__label2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 8px;
        display: block;
    }

    &__conditon {
        width: 100%;
        margin-top: 20px;

        &-mgb {
            margin-bottom: 20px;
        }
    }

    &__conditonTitleArea {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__conditonTitleAreaSP {
        display: none;
    }

    &__conditonTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 1em;
    }

    &__conditonBtn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #d7a600;
        cursor: pointer;

        &-gray {
            color: #b8b7b4;
            cursor: default;
        }
    }

    &__conditonCheckArea {
    }

    &__conditonCheckItemWrap {
        display: none;
    }

    &__conditonCheckTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 4px;
    }

    &__conditonCheckItem {
        margin-top: 8px;

        &-dpn {
            display: none;
        }

        &-w50 {
            width: 50%;
        }
    }

    &__sideSecTitle2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 20px;
    }

    &__sideSecGroup {
        padding-top: 14px;

        &-top {
            padding-top: 0;
        }
    }

    &__sideSecGroupTitle {
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 8px solid #000;
        }

        &-open {
            &::after {
                border-top: none;
                border-bottom: 8px solid #000;
            }
        }
    }

    &__sideSecGroupTitleNum {
        display: none;
    }

    &__sideSecGroupInner {
        max-height: 0;
        overflow: hidden;
        transition-duration: 0.2s;
    }

    &__sideSecGroupCheckWrap {
        margin-top: 14px;

        &-flex {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &__sideBtnArea {
        padding: 14px 16px;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        background: #fff;
        border-top: 1px solid #e8e8e8;
        position: sticky;
        bottom: 0;
        left: 0;
        max-width: 100%;
    }

    &__main {
        width: 840px;
        max-width: 73%;
        height: 100%;
        overflow-y: auto;
        padding: 0 25px;
        box-sizing: border-box;
        position: relative;
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #c4c4c4;
        }

        &-inactive::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: none;
        }
    }

    &__numberArea {
        margin: 30px 0 15px 0;
    }

    &__number {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-small {
            font-size: 14px;
        }
    }

    &__numberAreaBtn {
        display: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        background: transparent;
        line-height: 24px;
        letter-spacing: 0.014em;
        border: none;
    }

    &__mainHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__mainHeaderLeft {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainHeaderSelectWrap {
        margin-left: 16px;
        width: 535px;
        max-width: 40%;
    }

    &__mainHeaderSelect {
        background: #fff;
    }

    &__mainHeaderRight {
        width: 228px;
        height: 40px;
        background: #ffffff;
        border-radius: 20px;
        padding: 2px;
        box-sizing: border-box;
        display: flex;
    }

    &__mainHeaderBtn {
        width: 50%;
        height: 100%;
        padding: 0 13px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #c4c4c4;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 20px;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            color: #000;
            background: #ffcc1d;

            .search__mainHeaderBtnIcon {
                fill: #000;
            }
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            cursor: default;

            .search__mainHeaderBtnIcon {
                fill: #000;
            }
        }
    }

    &__mainHeaderBtnIcon {
        display: inline-block;
        width: 15px;
        fill: #c4c4c4;
        fill-rule: evenodd;
        margin-right: 5px;
        transition-duration: 0.2s;
    }

    &__mainContWrap {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 40px;
    }

    &__mainCont {
        margin-top: 12px;
        border-radius: 8px;
        background: #fff;
        transition-duration: 0.2s;

        &:hover {
            box-shadow: $shadow4;
        }
    }

    &__mainContInner {
        width: 100%;
        padding: 24px 0 12px 0;
        box-sizing: border-box;
        display: flex;
    }

    &__mainCheckArea {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        padding: 16px;
    }

    &__mainTargetArea {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        padding: 16px;
    }

    &__mainTarget {
    }

    &__mainTargetIcon {
        min-width: 16px;
        width: 16px;
        fill: #d7a600;
    }

    &__mainContMain {
        width: 100%;
        padding-right: 16px;
        box-sizing: border-box;
    }

    &__mainContHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // padding-right: 16px;
        box-sizing: border-box;
        margin-bottom: 16px;
    }

    &__mainContHeaderDlWrap {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    &__mainContHeaderDl {
        display: flex;
        align-items: center;
    }

    &__mainContHeaderDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainContHeaderDd {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        background: #e0f7f3;
        border-radius: 2px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #3db19f;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 3px;
        margin-right: 24px;

        &::before {
            content: '';
            display: block;
            width: 17px;
            height: 17px;
            background-image: url(../../img/earth-icon.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 5px;
        }

        &-ng {
            color: #fd5386;
            background: #fee0e1;

            &::before {
                background-image: url(../../img/kagi-icon.svg);
            }
        }

        &-manager {
            color: #368c9f;
            background: #cdf4fd;

            &::before {
                background-image: url(../../img/kessaisya.svg);
            }
        }
    }

    &__mainContHeaderBtn {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        border: none;
        background: #fff;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 17px;
            height: 17px;
            background-image: url(../../img/star-icon.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__mainContClickArea {
        cursor: pointer;
    }

    &__mainContTitleArea {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #e8e8e8;
        &-mini {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;

            .search__mainContCompany {
                margin-bottom: 0;
            }
        }
    }

    &__mainContTitle {
        display: block;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 10px;

        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }
    }

    &__mainContCompany {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        &::before {
            content: '';
            display: block;
            width: 15px;
            height: 17px;
            background-image: url(../../img/company-icon.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__mainContTitleDl {
        display: flex;
        align-items: center;
    }

    &__mainContTitleDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 16px;
    }

    &__mainContTitleDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 32px;
    }

    &__mainContPoint {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &__mainContPointTitle {
        min-width: 9em;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 24px;
    }

    &__mainContPointDd {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        overflow: hidden;
    }

    &__mainContPointTag {
        padding: 0 8px;
        background: #b28e14;
        border-radius: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 8px;
        margin-bottom: 4px;
    }

    &__mainContInfoArea {
        width: 100%;
        display: flex;
        border-radius: 4px;
        padding: 12px 12px 9px 12px;
        box-sizing: border-box;

        &-bg {
            background: #f8f7f5;
        }

        &-mgb {
            margin-bottom: 12px;
        }

        &-pdl0 {
            padding-left: 0;
        }
    }

    &__mainContInfoTitle {
        width: 7em;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainContInfoDlWrap {
    }

    &__mainContInfoDl {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 3px;
    }

    &__mainContInfoDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 1em;

        &-5em {
            width: 5.5em;
        }

        &-6em {
            width: 6.5em;
        }

        &-7em {
            width: 7.5em;
        }
    }

    &__mainContInfoDd {
        width: 10em;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 1em;
    }

    &__remarksArea {
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #e1e1e1;
        border-radius: 4px;
    }

    &__remarks {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainContFooter {
        width: 100%;
        padding: 9px 24px;
        box-sizing: border-box;
        border-top: 1px solid #e8e8e8;
    }

    &__mainContFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__mainContFooterLeft {
        display: flex;
        align-items: center;
    }

    &__mainContFooterDate {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.01em;
        margin-right: 10px;
    }

    &__mainContFooterRight {
        display: flex;
        align-items: center;
    }

    &__mainContFooterDot {
        display: none;
    }

    &__mainCountArea {
        width: calc(100% + 50px);
        // width: 840px;
        // max-width: 73%;
        padding: 16px 19px;
        box-sizing: border-box;
        background: #fff;
        border-bottom: 1px solid #e8e8e8;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        transform: translateX(-25px);
        transition-duration: 0.3s;
        display: none;
        visibility: hidden;
        opacity: 0;

        &-active {
            display: block;
            visibility: visible;
            opacity: 1;
            padding: 16px 19px;
            box-sizing: border-box;
            background: #fff;
            border-bottom: 1px solid #e8e8e8;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 10;
            transform: translateX(-25px);
            transition-duration: 0.3s;
        }
    }

    &__mainCount {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__mainCountSup {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        display: none;
    }

    &__mainFootArea {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: calc(100% + 50px);
        padding: 16px 25px;
        box-sizing: border-box;
        background: #fff;
        border-top: 1px solid #e8e8e8;
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 10;
        transform: translateX(-25px);
        display: none;
        visibility: hidden;
        opacity: 0;
        transition-duration: 0.3s;

        &-active {
            display: flex;
            visibility: visible;
            opacity: 1;
        }
    }

    &__mainFootAreaClose {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        cursor: pointer;
        position: relative;
        padding-left: 17px;
        margin-left: 5px;
        transition-duration: 0.2s;

        &::before {
            content: '';
            display: block;
            width: 1.5px;
            height: 16px;
            background: #c4c4c4;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            transform: rotate(-135deg);
            margin-top: 4px;
        }

        &::after {
            content: '';
            display: block;
            width: 1.5px;
            height: 16px;
            background: #c4c4c4;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            transform: rotate(-45deg);
            margin-top: 4px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__modalTitleNum {
        display: none;
    }

    &__mainCover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(229, 229, 229, 0.5);
    }

    // cm001

    &__cm001SeachArea {
        padding: 18px 0 22px 0;
        display: flex;
        align-items: center;
    }

    &__cm001SeachLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 16px;
        white-space: nowrap;
    }

    &__cm001SeachInput {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 12px;
        background: #f5f4ef;
        border-radius: 4px;
        border: 1px solid transparent;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        // color: #8A8883;
        margin-right: 8px;
    }

    &__cm001Li {
        width: 100%;
        margin-bottom: 12px;
        padding: 16px 16px 14px 16px;
        box-sizing: border-box;
        background: #f8f7f5;
        list-style: none;
        border-radius: 8px;
        &:nth-last-child(1) {
            margin-bottom: 40px;
        }
    }

    &__cm001LiHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 8px;
    }

    &__cm001LiTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__cm001LiHeaderRight {
        display: flex;
        align-items: center;
    }

    &__cm001LiHeaderDay {
        font-size: 14px;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 14px;
    }

    &__cm001LiLi {
        margin-top: 12px;

        &:nth-child(1) {
            margin-top: 0;
        }
    }

    &__cm001LiLiTitle {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 4px 0;
        margin-bottom: 4px;
    }

    &__cm001LiUl {
        list-style: none;
        overflow: hidden;
        transition-duration: 0.3s;
    }

    &__cm001LiLiTagArea {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }

    &__cm001LiLiTag {
        padding: 4px 16px;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 16px;
        background: #fff;
        display: flex;
        margin: 0 8px 8px 0;
    }

    &__cm001LiLiTagDt {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 8px;
        color: #8a8883;
    }

    &__cm001LiLiTagDd {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__cm001DropMenuWrap {
        text-align: center;
        margin: 6px auto;
        display: none;
    }

    &__cm001DropMenu {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #d7a600;
            border-right: 1px solid #d7a600;
            transform: rotate(135deg);
            margin-right: 10px;
            transition-duration: 0.3s;
        }

        &.open {
            &::before {
                transform: rotate(-45deg);
                margin-top: 3px;
            }
        }
    }

    &__cm001LiLiBtnArea {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
    }
}

.searchCm001DelBtn {
    position: relative;
}

@media screen and (max-width: 768px) {
    .search {
        padding-top: 56px;
        padding-bottom: 56px;

        &__inner {
            width: 92%;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }

        &__side {
            width: 100%;
            max-width: 100%;
            height: 100%;
            overflow-y: auto;
            border-right: 1px solid #e8e8e8;
            background: #fff;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 19;
            // padding-bottom: 56px;
            box-sizing: border-box;
            transform: translateY(100vh);
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;

            &-active {
                transform: translateY(0);
            }
        }

        &__sideSec {
            width: 100%;
            padding: 25px 16px;
            box-sizing: border-box;
            border-bottom: 1px solid #e8e8e8;

            &-last {
                padding-bottom: 56px;
                box-sizing: border-box;
            }
        }

        &__label1 {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            color: #8a8883;
            line-height: 16px;
            letter-spacing: 0.017em;
            margin-bottom: 8px;
            display: block;
        }

        &__keepBtn {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #d7a600;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-top: 16px;
            cursor: pointer;
        }

        &__sideSecTitle {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.008em;
            margin-bottom: 24px;
        }

        &__label2 {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-bottom: 8px;
            display: block;
        }

        &__conditon {
            width: 100%;
            margin-top: 20px;

            &-mgb {
                margin-bottom: 20px;
            }
        }

        &__conditonTitleArea {
            display: none;
        }

        &__conditonTitleAreaSP {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            &::after {
                content: '';
                width: 7px;
                height: 7px;
                border-top: 1px solid #151208;
                border-right: 1px solid #151208;
                transform: rotate(45deg);
            }
        }

        &__conditonTitle {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-right: 1em;
        }

        &__conditonBtn {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.014em;
            color: #d7a600;
            cursor: pointer;
        }

        &__conditonCheckArea {
            display: none;
        }

        &__conditonCheckItemWrap {
            display: none;
        }

        &__conditonCheckTitle {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #8a8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-top: 4px;
        }

        &__conditonCheckItem {
            margin-top: 8px;

            &-dpn {
                display: none;
            }
        }

        &__sideSecTitle2 {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-top: 20px;
        }

        &__sideSecGroup {
            padding-top: 14px;

            &-top {
                padding-top: 0;
            }
        }

        &__sideSecGroupTitle {
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition-duration: 0.2s;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #000;
            }

            &-open {
                &::after {
                    border-top: none;
                    border-bottom: 8px solid #000;
                }
            }
        }

        &__sideSecGroupTitleNum {
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            right: 32px;
            bottom: 0;
            margin: auto;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: #3db19f;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #fff;
                line-height: 24px;
                letter-spacing: 0.005em;
                display: none;
            }
        }

        &__sideSecGroupInner {
            max-height: 0;
            overflow: hidden;
            transition-duration: 0.2s;
        }

        &__sideSecGroupCheckWrap {
            margin-top: 14px;
        }

        &__sideBtnArea {
            padding: 14px 16px;
            box-sizing: border-box;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            background: #fff;
            border-top: 1px solid #e8e8e8;
            position: sticky;
            bottom: 0;
            left: 0;
        }

        &__main {
            width: 100%;
            max-width: 100%;
            height: auto;
            overflow-y: default;
            padding: 0 0;
        }

        &__numberArea {
            margin: 20px 0 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__numberAreaBtn {
            display: block;
        }

        &__number {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;

            &-num {
                display: inline-block;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: -0.01em;
                margin-left: 15px;
            }

            &-small {
                font-size: 14px;
            }
        }

        &__mainHeader {
        }

        &__mainHeaderLeft {
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.014em;
            white-space: nowrap;
        }

        &__mainHeaderSelect {
            background: #fff;
        }

        &__mainHeaderRight {
            width: 228px;
            height: 40px;
            background: #ffffff;
            border-radius: 20px;
            padding: 2px;
            box-sizing: border-box;
            display: flex;
        }

        &__mainHeaderBtn {
            width: 50%;
            height: 100%;
            padding: 0 13px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #c4c4c4;
            line-height: 24px;
            letter-spacing: 0.014em;
            border-radius: 20px;
            cursor: pointer;
            transition-duration: 0.2s;

            &:hover {
                color: #000;
                background: #ffcc1d;

                .search__mainHeaderBtnIcon {
                    fill: #000;
                }
            }

            &-active {
                color: #000;
                background: #ffcc1d;
                cursor: default;

                .search__mainHeaderBtnIcon {
                    fill: #000;
                }
            }
        }

        &__mainHeaderBtnIcon {
            display: inline-block;
            width: 15px;
            fill: #c4c4c4;
            fill-rule: evenodd;
            margin-right: 5px;
            transition-duration: 0.2s;
        }

        &__mainContWrap {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 40px;
        }

        &__mainCont {
            &:hover {
                box-shadow: none;
            }
        }

        &__mainContInner {
            padding: 16px 12px 16px 12px;

            &-checkBox {
                .search__mainCheckArea {
                    display: flex;
                }

                .search__mainContMain {
                    width: calc(100% - 50px);
                }
            }
        }

        &__mainCheckArea {
            display: none;
            padding: 12px;
            padding-left: 0;
        }

        &__mainTargetArea {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            padding: 16px;
        }

        &__mainTarget {
        }

        &__mainTargetIcon {
        }

        &__mainContMain {
            padding-right: 0;
        }

        &__mainContHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;
            // padding-right: 16px;
            box-sizing: border-box;
            margin-bottom: 8px;
        }

        &__mainContHeaderDlWrap {
        }

        &__mainContHeaderDl {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 8px;
        }

        &__mainContHeaderDt {
            width: 5em;
            margin-left: 8px;
        }

        &__mainContHeaderDd {
            display: flex;
            align-items: center;
            padding: 5px 7px;
            background: #e0f7f3;
            border-radius: 2px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #3db19f;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-left: 3px;
            margin-right: 24px;

            &::before {
                content: '';
                display: block;
                width: 17px;
                height: 17px;
                background-image: url(../../img/earth-icon.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 5px;
            }

            &-ng {
                color: #fd5386;
                background: #fee0e1;

                &::before {
                    background-image: url(../../img/kagi-icon.svg);
                }
            }

            &-manager {
                color: #368c9f;
                background: #cdf4fd;

                &::before {
                    background-image: url(../../img/kessaisya.svg);
                }
            }
        }

        &__mainContClickArea {
            cursor: default;
        }

        &__mainContTitleArea {
            padding-bottom: 10px;
            margin-bottom: 10px;

            &-mini {
            }
        }

        &__mainContTitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.008em;
            margin-bottom: 8px;
        }

        &__mainContCompany {
            margin-bottom: 8px;

            &::before {
            }
        }

        &__mainContTitleDl {
        }

        &__mainContTitleDt {
        }

        &__mainContTitleDd {
        }

        &__mainContPoint {
            width: calc(100% + 11px);
            // overflow-x: auto;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }

        &__mainContPointTitle {
            display: none;
        }

        &__mainContPointDd {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            overflow-x: auto;
        }

        &__mainContPointTag {
            white-space: nowrap;
        }

        &__mainContInfoArea {
        }

        &__mainContInfoTitle {
            display: none;
        }

        &__mainContInfoDlWrap {
            width: 100%;
        }

        &__mainContInfoDl {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 3px;
        }

        &__mainContInfoDt {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #8a8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-right: 1em;

            &-5em {
                width: 7.5em;
            }

            &-6em {
                width: 7.5em;
            }

            &-7em {
                width: 7.5em;
            }
        }

        &__mainContInfoDd {
            width: 10em;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-right: 1em;
        }

        &__remarksArea {
            display: none;
        }

        &__remarks {
        }

        &__mainContFooter {
            width: 100%;
            padding: 3px 12px;
        }

        &__mainContFooterLeft {
        }

        &__mainContFooterDate {
        }

        &__mainContFooterRight {
            display: flex;
            align-items: center;

            .btn {
                display: none;
            }
        }

        &__mainContFooterDot {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__mainCountArea {
            width: 100%;
            position: fixed;
            top: 56px;
            left: 0;
            z-index: 10;
            transform: translateX(0);
        }

        &__mainCount {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
        }

        &__mainCountSup {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            color: #8a8883;
            line-height: 16px;
            letter-spacing: 0.017em;
            display: none;
        }

        &__mainFootArea {
            width: 100%;
            height: 56px;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 17;
            transform: translateX(0);
            padding: 0 16px;

            .search__mainContFooterRight {
                .btn__textBtn {
                    display: flex;
                }

                .btn__shadowBtn {
                    display: flex;
                }
            }
        }

        &__mainFootAreaClose {
            width: 24px;
            height: 24px;
            padding-left: 0;
            margin-left: 5px;
            transition-duration: 0.2s;

            &::before {
                content: '';
                display: block;
                width: 1.5px;
                height: 16px;
                background: #000;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                transform: rotate(-135deg);
                margin-top: 4px;
            }

            &::after {
                content: '';
                display: block;
                width: 1.5px;
                height: 16px;
                background: #000;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                transform: rotate(-45deg);
                margin-top: 4px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        &__modalTitleNum {
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            right: 32px;
            bottom: 0;
            margin: auto;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: #3db19f;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: #fff;
                line-height: 24px;
                letter-spacing: 0.005em;
                display: none;
            }
        }

        &__spModalBtn {
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 6px 0;
            box-sizing: border-box;
            border: 1px solid #fff;
            margin-bottom: 15px;

            &-w {
                color: #d7a600;
                background: #fff;
                box-shadow: $shadow1;
                .btn__btnIcon {
                    svg {
                        fill: #d7a600;
                    }
                }
            }

            &-wr {
                color: #cd1818;
                background: #fff;
                box-shadow: $shadow1;

                .btn__btnIcon {
                    svg {
                        fill: #cd1818;
                    }
                }
            }

            &-tra {
                color: #fff;
                border: 1px solid #fff;
                background: transparent;
            }

            &-close {
                background: transparent;
                border: none;
                color: #fff;
                .btn__btnIcon {
                    img {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
            }
        }

        // cm001

        &__cm001SeachArea {
            padding: 16px 0;
            flex-flow: row wrap;
        }

        &__cm001SeachLabel {
            width: 100%;
            margin-right: 0;
            margin-bottom: 8px;
        }

        &__cm001SeachInput {
            width: calc(100% - 60px - 4em);
        }

        &__cm001Ul {
            width: 100%;
            list-style: none;
        }

        &__cm001Li {
            padding: 16px 16px 14px 16px;
            list-style: none;
        }

        &__cm001LiHeader {
            flex-flow: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: 0;
            margin-bottom: 12px;
        }

        &__cm001LiTitle {
            margin-bottom: 4px;
        }

        &__cm001LiHeaderRight {
            display: flex;
        }

        &__cm001LiHeaderDay {
            margin-left: 12px;
            &-top {
                margin-left: 0;
            }
        }

        &__cm001LiLiTagArea {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
        }

        &__cm001LiLiTag {
            padding: 4px 12px;
            margin: 0 8px 4px 0;
        }

        &__cm001LiLiBtnArea {
            justify-content: space-between;
            margin-top: 14px;
        }
    }
}
