.selection__searchSelect .css-1s2u09g-control {
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f4ef;
    border-color: transparent;
    cursor: default;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
}

.css-1hb7zxy-IndicatorsContainer {
    margin-left: 8px;
}

.css-1hb7zxy-IndicatorsContainer .css-1okebmr-indicatorSeparator {
    background-color: transparent;
}
