// selection
@import '../variables';

.selection {
    flex-flow: column;
    background: #f5f4ef;

    &__inner {
        width: 100%;
        padding: 24px 24px 24px 24px;
        box-sizing: border-box;

        &-pdb0 {
            padding-bottom: 0;
        }
    }

    &__searchArea {
        width: 1104px;
        max-width: 100%;
        padding: 25px 0 15px 0;
        box-sizing: border-box;
        background: #fff;
        margin: 0 auto;
        border-radius: 8px;
    }

    &__searchAreaTop {
        width: 100%;
        padding: 0 24px 24px 24px;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    &__searchTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-bottom: 20px;
    }

    &__searchTitle1 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
    }

    &__searchGroup {
        margin-top: 20px;
        margin-right: 24px;
    }

    &__searchGroup1 {
        margin-top: 16px;
        margin-right: 24px;
    }

    &__searchLabel {
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 3px;
    }

    &__searchInput {
        width: 216px;
        height: 36px;
        background: #f5f4ef;
        border: none;
        outline: none;
        padding: 0 12px 0 12px;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__searchSelect {
        width: 216px;
        height: 36px;
        background: #f5f4ef;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;

        border: none;
        outline: none;
        padding: 0 12px 0 20px;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    &__multiple_select {
        display: block;
        color: #8a8883;
        padding: 0;

        &-disableSelect {
            pointer-events: none;
            padding: 0;
        }
    }

    &__searchFlex {
        display: flex;
        flex-flow: row wrap;
    }

    &__searchSelectWrap {
        margin-top: 0;
    }

    &__searchAreaBottom {
        display: flex;
        justify-content: flex-end;
        margin-top: 14px;
        padding: 0 24px;
        box-sizing: border-box;
    }

    &__searchBtn1 {
        background: transparent;
        border: none;
        font-size: 14px;
        font-style: normal;
        color: #b8b7b4;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        transition-duration: 0.2s;
        cursor: pointer;

        &:hover {
            color: #000;
        }

        &-disableBtn {
            pointer-events: none;
        }
    }

    &__searchBtn2 {
        transition-duration: 0.2s;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.014em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 8px 40px 7px 40px;
        border-radius: 18px;
        color: #000;
        background: #ffcc1d;
        border: 1px solid #ffcc1d;
        box-sizing: border-box;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        cursor: pointer;
        margin-left: 21px;

        &:link {
            color: #000;
            text-decoration: none;
        }

        &:visited {
            color: #000;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            text-decoration: none;
            box-shadow: none;
            background: #d7a600;
            border: 1px solid #d7a600;
        }

        &:active {
            color: #000;
            text-decoration: none;
        }
    }

    &__mainWrap {
        width: 1104px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__side {
        width: 19%;
    }

    &__sideUl {
        width: 100%;
        list-style: none;
        position: sticky;
        top: 82px;
        left: 0;
    }

    &__sideLi {
        width: 100%;

        &-attention {
            position: relative;

            &::after {
                content: '';
                width: 10px;
                height: 10px;
                background: #ff611d;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }

        &-disableLink {
            pointer-events: none;
        }
    }

    &__sideLink {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        padding: 12px 20px;
        margin-bottom: 8px;
        box-sizing: border-box;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            text-decoration: none;
            background: #ffcc1d;
        }

        &:active {
            color: #8a8883;
            text-decoration: none;
        }

        &-num {
            display: flex;
            justify-content: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #fff;
            line-height: 24px;
            letter-spacing: 0.005em;
            padding: 0 8px;
            box-sizing: border-box;
            border-radius: 16px;
            background: #3db19f;
            min-width: 3em;
        }

        &-active {
            color: #000;
            text-decoration: none;
            background: #ffcc1d;
            &:link {
                color: #000;
            }

            &:visited {
                color: #000;
            }

            &:hover {
                color: #000;
            }

            &:active {
                color: #000;
            }
        }
    }

    &__main {
        width: 78%;
    }

    &__mainTitleFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__mainTitleBtn {
        text-decoration: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 20px;
        background: #3db19f;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 999px;
        border: none;
        transition-duration: 0.2;

        &:disabled,
        &-disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &__mainTitleBtnIcon {
        margin-right: 8px;
        height: 24px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-top: 7px;
        margin-bottom: 12px;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        transition-duration: 0.2s;

        &-gray {
            color: #8a8883;
        }

        &-white {
            background: #fff;
        }

        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        }
        &:nth-child(1) {
            box-shadow: none;
            cursor: default;
            &:hover {
                box-shadow: none;
            }
        }
    }

    &__mainLi-no {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        // cursor: pointer;
        transition-duration: 0.2s;

        &-gray {
            color: #8a8883;
        }

        &-white {
            background: #fff;
        }

        &-progress {
            .selection {
                &__mainOwner,
                &__mainPosition,
                &__mainPartner,
                &__mainStatus,
                &__mainName,
                &__mainJobInfo,
                &__mainCompany,
                &__mainStep {
                    font-weight: 400;
                }
            }
        }

        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
        }
        &:nth-child(1) {
            box-shadow: none;
            cursor: default;
            &:hover {
                box-shadow: none;
            }
        }
    }

    &__mainName {
        width: 14%;
        padding: 20px 0 20px 20px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainJobInfo {
        width: 38%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__mainCompany {
        width: 23%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainStep {
        width: 15%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__mainSituation {
        width: 12%;
        padding: 20px 20px 20px 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__mainOwner {
        width: 28%;
        padding: 16px 16px 16px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;
    }

    &__mainPosition {
        width: 28%;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        &-hr {
            width: 45%;
        }
    }

    &__mainPartner {
        width: 14.5%;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__mainInfo {
        width: 37%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;

        &-wordBreak {
            white-space: normal;
            word-break: break-all;
        }
    }

    &__mainInfoTag {
        background: #f8f7f5;
        border-radius: 24px;
        display: inline-flex;
        align-items: flex-start;
        padding: 2px 8px;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.014em;
        color: #8a8883;
        margin-bottom: 10px;
    }

    &__mainInfoContent {
        margin-bottom: 8px;
    }

    &__mainInfoStatusArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__mainInfoStatus {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding-left: 20px;
        position: relative;

        &-progress {
            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: #52d276;
                border-radius: 100%;
            }
        }

        &-stop {
            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: #b8b7b4;
                border-radius: 100%;
            }
        }

        &-archive-type1 {
            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: #b8b7b4;
                border-radius: 100%;
            }
        }

        &-archive-type2 {
            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                background-color: #fff;
                border-radius: 100%;
                border: 2px solid #b8b7b4;
            }
        }

        &-draft {
            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: #ffcc1d;
                border-radius: 100%;
            }
        }
    }

    &__mainInfoEditDate {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.014em;
        color: #8a8883;
    }

    &__mainStatus {
        width: 17.7%;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        font-weight: 500;
        white-space: nowrap;

        .selection-dpb {
            font-weight: 500;
        }
    }

    &__mainSituationMark {
        width: 67px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 16px;
        margin: 0 auto;

        &-blue {
            background: #1daeff;
        }

        &-red {
            background: #ff611d;
        }

        &-grey {
            background: #b8b7b4;
        }

        &-green {
            background: #52d276;
        }
    }

    &__mainNoneText {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 10px;

        &-rt {
            margin-left: 24px;
        }
    }

    &__mainSituationMark {
        width: 67px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 16px;
        margin: 0 auto;

        &-blue {
            background: #1daeff;
        }

        &-red {
            background: #ff611d;
        }
    }

    &__mainNoneText {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 10px;
    }

    &__mainType {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        width: 15%;
        padding: 20px 0 20px 32px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;

        &-hide {
            display: none;
        }
    }

    &__mainNum {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        width: 15%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__mainStatusNum {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        width: 14%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__mainProgressNum {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        width: 13%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__mainEmpty {
        width: 14%;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__mainEmptyFlex {
        display: flex;
        align-items: center;
    }

    &__mainEmptyLink {
        cursor: pointer;
        img {
            vertical-align: middle;
        }
    }

    &__mainEmptyOptionArea {
        position: relative;
    }

    &__mainEmptyOptionIcon {
        cursor: pointer;
        margin-left: 12px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        &:hover {
            background-color: #fff4d1;
        }

        img {
            width: 21px;
        }
    }

    &__mainEmptyOptions {
        position: absolute;
        z-index: 1;
        right: -36px;
        transform: translateY(-20px);
        opacity: 0;
        pointer-events: none;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        &.show {
            transform: translateY(0px);
            opacity: 1;
            pointer-events: unset;
        }
    }

    &__mainEmptyOptionsUl {
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        overflow: hidden;
    }

    &__mainEmptyOptionsLi {
        white-space: nowrap;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 12px 16px;
        border-bottom: solid 1px #e8e8e8;
        transition-duration: 0.2s;
        cursor: pointer;

        &:last-child {
            border: none;
        }

        &:hover {
            background: #ffcc1d;
            font-weight: 500;
        }
    }

    &-dpb {
        display: block;
    }
    &-pdb0 {
        padding-bottom: 8px;
    }
    &-pdt4 {
        padding-top: 4px;
    }

    &__pagination {
        margin-top: 23px;
    }

    &__jobPostingOptionsLi {
        padding: 12px 14px;
    }

    @media screen and (max-width: 1045px) {
        &__inner {
            padding: 16px;
        }

        &__mainWrap {
            flex-direction: column;
            padding: 0 16px 16px 16px;
        }

        &__side {
            width: 100vw;
            transform: translateX(-16px);
        }

        &__sideUl {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            overflow: auto;
        }

        &__sideLi {
            width: unset;

            &:first-child {
                margin-left: 16px;
            }

            &:last-child {
                margin-right: 16px;
            }
        }

        &__sideLi-attention {
            &::after {
                content: unset;
            }
        }

        &__sideLink {
            padding: 6px 4px;
            &:hover {
                .selection__sideLink-num {
                    background-color: #000;
                }
            }
        }

        &__sideLink-active {
            .selection__sideLink-num {
                background-color: #000;
            }
        }

        &__sideLink-text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.014em;
        }

        &__sideLink-num {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.011em;
            line-height: 1;
            padding: 2px 5px;
            background: #8a8883;
        }

        &__sideLink {
            gap: 8px;
            white-space: nowrap;
            border-radius: 9999px;
            padding: 6px 4px;

            &-active {
                padding: 6px 12px;
            }
        }

        &__main {
            width: 100%;
        }
    }

    @media screen and (max-width: 850px) {
        &__mainOwner {
            width: 26%;
        }

        &__mainPosition {
            width: 26%;
        }

        &__mainStatus {
            width: 19%;
        }

        &__mainSituation {
            width: 15%;
        }
    }

    @media screen and (max-width: 768px) {
        margin-top: 55px;

        &__side {
            transform: translateX(-8px);
        }

        &__mainWrap {
            padding: 0 8px 56px 8px;
        }

        &__inner {
            padding: 0 16px;
            position: fixed;
            inset: 0;
            width: 100%;
            background-color: #fff;
            min-height: 100vh;
            z-index: 18;
            overflow: hidden;

            transform: translateY(100vh);
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;

            &.search__side-active {
                transform: translateY(0);
            }

            .spHeader__inner {
                padding: 0;
            }
        }

        &__searchArea {
            padding: 0;
            position: relative;
        }

        .spHeader-inSec {
            position: absolute;
            top: 0;
            left: 0;
            border: none;

            &::before {
                position: absolute;
                content: '';
                width: 100vw;
                height: 1px;
                background-color: #e8e8e8;
                bottom: 0;
                left: -16px;
            }
        }

        &__searchAreaTop {
            padding: 80px 0 0 0;
            border: none;
        }

        &__searchGroup {
            margin: 0 0 24px 0;
            width: 100%;
        }

        &__searchLabel {
            margin-bottom: 16px;
        }

        .form__selectWrap {
            display: block;
        }

        &__searchInput,
        &__searchSelect {
            width: 100%;
        }

        &__searchTitle {
            display: none;
        }

        &__searchAreaBottom {
            justify-content: center;
            margin: 0;
            padding: 12px 8px;
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            border-top: 1px solid #e8e8e8;
        }

        &__searchBtn1 {
            width: 42%;
        }

        &__searchBtn2 {
            width: 54.4%;
            margin-left: 12px;
        }

        &__side {
            background-color: #fff;
        }

        &__sideUl {
            padding: 9.5px 0;
        }

        &__sideLi {
            &:first-child {
                margin-left: 8px;
            }

            &:last-child {
                margin-right: 8px;
            }
        }

        &__sideLink {
            margin: 0;
        }

        &__mainTitle {
            margin: 0;
            padding: 20px 0;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 100vw;
                height: 1px;
                bottom: 0;
                left: -16px;
                background-color: #e8e8e8;
            }
        }

        &__mainTitleBtn {
            position: fixed;
            bottom: 72px;
            right: 12px;
            z-index: 1;
            padding: 10px 20px;

            transition-duration: 0.2s;
            opacity: 0;
            pointer-events: none;
            transform: translateY(-20px);

            &.show {
                opacity: 1;
                transform: translateY(0);
                pointer-events: unset;
            }
        }

        &__mainLi {
            flex-direction: column;
            flex-wrap: wrap;
            gap: 12px;
            position: relative;
            padding: 16px 8px;

            font-weight: 400;
            letter-spacing: 0.011em;

            &:nth-child(1) {
                display: none;
            }
        }

        &__mainOwner,
        &__mainName {
            order: 0;
            width: calc(100% - 67px - 8px);
            padding: 0;
            margin: 4px 0 0 0;

            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.011em;
        }

        &__mainPosition,
        &__mainJobInfo {
            order: 2;
            width: 100%;
            padding: 0;
        }

        &__mainLi-gray {
            color: #151208;
        }

        &__mainPartner,
        &__mainCompany {
            order: 3;
            width: 100%;
            padding: 0;

            br {
                display: none;
            }
        }

        &__mainStatus,
        &__mainStep {
            order: 4;
            width: 100%;
            padding: 0;
        }

        &__mainStep {
            flex-direction: row !important;
            flex-wrap: wrap;

            &::before {
                width: 100%;
            }

            .selection-dpb {
                color: #8a8883;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                margin-left: 16px;
            }
        }

        &__mainSituation {
            // order: 1;
            width: auto;
            padding: 0;
            position: absolute;
            top: 16px;
            right: 8px;
        }

        &__mainSituationMark {
            margin: 0;
        }

        &__mainPosition,
        &__mainPartner,
        &__mainStatus,
        &__mainJobInfo,
        &__mainCompany,
        &__mainStep {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &:before {
                content: attr(data-type);
                display: block;
                color: #8a8883;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                margin: 0 0 4px 0;
            }
        }

        &__mainLi-no {
            flex-direction: row;
            padding: 16px;
        }

        &__mainInfo {
            width: 100%;
            padding: unset;
        }

        &__mainInfoContetnt {
            width: 100%;
            padding: unset;
            font-weight: 500;
        }

        &__mainType,
        &__mainNum,
        &__mainStatusNum,
        &__mainProgressNum {
            width: calc(50% - 6px);
            padding: unset;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            &:before {
                content: attr(data-type);
                display: block;
                color: #8a8883;
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                margin-right: 24px;
                width: 4.5rem;
            }
        }

        &__mainType,
        &__mainStatusNum {
            &:before {
                width: 2.7rem;
            }
        }

        &__mainEmpty {
            width: 100%;
            padding: 12px 0 0;
            border-top: 1px solid #e8e8e8;
        }

        &__mainEmptyOptionIcon {
            margin-left: 16px;
        }

        &__mainEmptyFlex {
            justify-content: flex-end;
        }

        &__mainEmptyOptions {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            background: rgba(76, 61, 8, 0.8);
            padding: 16px 40px;
            box-sizing: border-box;
            z-index: 999;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            transform: translateY(0px);
        }

        &__mainEmptyOptionsUl {
            width: 100%;
            background: unset;
            box-shadow: none;
        }

        &__mainEmptyOptionsLi {
            background: #fff;
            margin-bottom: 16px;
            border-radius: 999px;
            justify-content: center;
            padding: 6px 0;
            border: none;

            &-edit,
            &-draft,
            &-copy,
            &-again,
            &-start {
                color: #d7a600;

                &:active {
                    color: #151208;
                }
            }

            &-stop,
            &-archive {
                color: #cd1818;

                &:active {
                    color: #151208;
                }
            }

            &-closeMenu {
                color: #fff;
                background: transparent;
                margin-bottom: 0;
            }
        }

        &__mainEmptyOptionsLiIcon {
            height: 24px;
            margin-right: 10px;
        }

        &__mainEmptyOptionsClose {
            display: flex !important;
            align-items: center;
            height: 24px;
            color: #fff;
        }
    }
}

.selectionModal {
    width: 100%;
    height: 100vh;
    background: rgba(76, 61, 8, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &-active {
        opacity: 1;
        visibility: visible;
    }

    &__inner {
        display: flex;
        justify-content: center;
        align-content: center;

        &-rt {
            justify-content: flex-end;
            align-content: flex-start;
        }
    }

    &__cont {
        width: 700px;
        max-width: 100%;
        height: 100vh;
        overflow: auto;
        background: #fff;
        // display: none;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
    }

    &__contHeader {
        padding: 14px 24px 14px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
    }

    &__contHeaderTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.008em;
    }

    &__contHeaderRight {
        display: flex;
        align-items: center;
    }

    &__contDecline {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #cd1818;
        line-height: 24px;
        letter-spacing: 0.014em;
        border: 1px solid #e1e1e1;
        border-radius: 24px;
        background: #fff;
        padding: 8px 20px;
        box-sizing: border-box;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            border: 1px solid #000;
        }
    }

    &__contEdit {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #ffffff;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        background: #3db19f;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 18px;
        border: none;

        &:disabled {
            opacity: 0.3;
        }
    }

    &__contResume {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: left;
        padding: 8px 20px;
        background: #ffcc1d;
        border: none;
        box-sizing: border-box;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 18px;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            box-shadow: none;
            background: #d7a600;
            // border: 1px solid #B28E14;
        }
    }

    &__close {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 24px;
        border: 1px solid transparent;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        transition-duration: 0.2s;

        &::before {
            content: '';
            display: block;
            width: 2px;
            height: 20px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-135deg);
        }

        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 20px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-45deg);
        }

        &:hover {
            border: 1px solid #151208;
        }
    }

    &__step {
        max-width: 80%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 22px 0 58px 0;
        box-sizing: border-box;

        &.step2 {
            padding: 22px 0 58px 0;
        }

        &.step3 {
            padding: 22px 0 90px 0;
        }

        &.step4 {
        }
    }

    &__stepCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #b8b7b4;
        box-sizing: border-box;
        position: relative;
    }

    &__stepSup {
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #b8b7b4;
        line-height: 16px;
        letter-spacing: 0.017em;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translate(-50%, 100%);
    }

    &__stepDate {
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        font-family: 'Roboto';
        color: #b8b7b4;
        line-height: 16px;
        letter-spacing: 0.01em;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        bottom: -42px;
        transform: translate(-50%, 0);
    }

    &__stepBorder {
        width: calc(100% - 120px);
        height: 2px;
        background: #e8e8e8;
        position: relative;
    }

    &__stepBorder2 {
        width: 96px;
        height: 2px;
        background: #e8e8e8;
        position: relative;
    }

    .mainBgColor {
        background: #3db19f;
    }

    .mainBdColor {
        border-color: #3db19f;
    }

    .mainBgColor2 {
        background: #cd1818;
    }

    .mainBdColor2 {
        border-color: #cd1818;
    }

    .fc1 {
        color: #151208;
    }

    .fc2 {
        color: #3db19f;
    }

    .fc3 {
        color: #cd1818;
    }

    &__compaUl {
        width: 648px;
        max-width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin: 0 auto;
        padding: 10px 20px;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        box-sizing: border-box;
        margin-bottom: 12px;
    }

    &__compaLi {
        display: flex;
    }

    &__compaLiTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 1em;
    }

    &__compaLiCont {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 3em;
    }

    &__tabs {
        display: flex;
        list-style: none;
        max-width: 100%;
        margin: 0 auto;
        padding: 12px 20px;
        background-color: #fff;
        position: sticky;
        top: 0;
        left: 0;
        border-bottom: 1px solid #e8e8e8;
    }

    &__tab {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.011em;
        padding: 10px 20px;
        border-radius: 20px;
        transition-duration: 0.2s;
        margin-right: 5px;
        cursor: pointer;

        &:hover {
            background: #ffcc1d;
            color: #151208;
        }

        &-active {
            background: #ffcc1d;
            color: #151208;
            cursor: auto;
        }
    }

    &__tabContArea {
        width: 100%;
        border-bottom: 1px solid #e8e8e8;
        background: #f5f4ef;
        padding: 17px 23px 0;
        box-sizing: border-box;
    }

    &__tabCont {
        opacity: 0;
        visibility: hidden;
        display: none;

        &-active {
            opacity: 1;
            visibility: visible;
            display: block;
        }
    }

    &__tabContDateWrap {
        text-align: center;
    }

    &__tabContDate {
        display: inline-block;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 16px;
        letter-spacing: 0.017em;
        text-align: center;
        margin: 0 auto 22px auto;
        border: 1px solid #b8b7b4;
        border-radius: 16px;
        padding: 4px 8px;
    }

    &__tabContStatus {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #3db19f;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 22px;

        &-red {
            color: #cd1818;
        }

        &-tdu {
            text-decoration: underline;
            &:link {
                color: #3db19f;
            }
            &:visited {
                color: #3db19f;
            }
            &:hover {
                color: #3db19f;
            }
            &:active {
                color: #3db19f;
            }
        }

        // &::before {
        //     content: "";
        //     display: block;
        //     width: 18px;
        //     height: 18px;
        //     background: #3DB19F;
        //     border-radius: 50%;
        //     background-image: url(../../img/check-icon.svg);
        //     background-size: 60%;
        //     background-position: center center;
        //     background-repeat: no-repeat;
        //     margin-right: 8px;
        // }
    }

    &__tabContStatusIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        background: #3db19f;
        border-radius: 50%;
        margin-right: 8px;

        &-nbgc {
            background: transparent;
        }
    }

    &__tabContStatusIconImg {
        width: 10px;
        height: auto;

        &-w {
            width: 15px;
        }
    }

    &__tabContCommentArea {
        display: flex;
        flex-grow: 0;
        width: 100%;

        &-last {
            margin-bottom: 100px;
        }
    }

    &__tabContCommentImgArea {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #e1e1e1;
        overflow: hidden;
        margin-right: 10px;
    }

    &__tabContAttachmentsArea {
        display: flex;
        margin-top: 15px;
        flex-direction: column;

        &-mgt0 {
            margin-top: 0;
        }
    }

    &__tabContAttachment {
        text-decoration: underline;
    }

    &__tabContAttachmentIcon {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    &__tabContCommentImg {
        width: calc(100% + 2px);
        height: 100%;
        object-fit: cover;
    }

    &__tabContCommentMainArea {
        width: 100%;
        margin-bottom: 20px;
    }

    &__tabContCommentNameArea {
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.017em;
        margin-bottom: 8px;
    }

    &__tabContCommentName {
        color: #151208;
        margin-right: 5px;
    }

    &__tabContCommentCompa {
        color: #8a8883;
    }

    &__tabContCheckDate {
        padding: 24px 24px 16px 24px;
        background-color: #fff;
        border-radius: 0px 8px 8px 8px;
        position: relative;
        box-sizing: border-box;
    }

    &__tabContCheckHeader {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__tabContCheckText {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.011em;
        color: #3db19f;
        margin-left: 8px;

        br {
            display: none;
        }
    }

    &__tabContCheckMain {
        padding: 12px 24px;
        background: #e0f7f3;
        border-radius: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.011em;
        color: #000000;
        text-align: center;
        margin-top: 16px;
    }

    &__tabContCheckMainDate {
        display: inline;
    }

    &__tabContCheckMainTime {
        display: inline;
        margin-left: 12px;
    }

    &__tabContCheckBtns {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
    }

    &__tabContCheckBtn {
        cursor: pointer;
        &-cancel {
            min-width: 89px;
            border-radius: 999px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.014em;
            color: #cd1818;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            border: 1px solid transparent;
            padding: 0;
            transition-property: border;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;

            &:hover {
                border: 1px solid #e1e1e1;
            }
        }

        &-edit {
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.014em;
            color: #d7a600;
            margin-left: 10px;
            background: #fff;
            transition-property: color background-color;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;

            &:hover {
                color: #000;
                background-color: #ffcc1d;
                border: 1px solid #ffcc1d;
            }
        }

        &:disabled {
            color: #b8b7b4;
            background-color: transparent;
        }
    }

    &__tabContCancel {
        background: #3e3104;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 10px 16px 10px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 86.66%;
        box-sizing: border-box;
        z-index: $tooltip-backdrop-index + 1;
    }

    &__tabContCancelText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #ffffff;
        white-space: nowrap;
    }

    &__tabContCancelBtn {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-decoration-line: underline;
        color: #d7a600;
        min-width: 89px;
        background: transparent;
        border: none;
        padding: 0;
        margin-left: 27px;
        cursor: pointer;
        transition-property: color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &:hover {
            color: #ffcc1d;
        }
    }

    &__tabContCanceled {
        padding: 16px 24px;
        background: #ffffff;
        border-radius: 0px 8px 8px 8px;
    }

    &__tabContCanHeader {
        display: flex;
        align-items: center;
    }

    &__tabContCanText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.014em;
        color: #8a8883;
        margin-left: 8px;
    }

    &__tabContCanWhen {
        margin-top: 8px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__tabContCommentWrap {
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__tabContCanDate {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.014em;
        text-decoration-line: line-through;
        color: #8a8883;
    }

    &__tabContCanTime {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.014em;
        text-decoration-line: line-through;
        color: #8a8883;
        margin-left: 12px;
    }

    &__tabContComment {
        max-width: 90%;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 8px 12px;
        box-sizing: border-box;
        border-radius: 0px 16px 16px 16px;
        background: #fff;
        word-break: break-all;

        &-bgy {
            background: #ffcc1d;
        }
    }

    &__tabContCommentTime {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 14px;
        letter-spacing: 0.005em;
        margin-left: 8px;
    }

    &__tabContSuggestionMainArea {
        max-width: calc(100% - 130px);
        background: #3db19f;
        padding: 16px;
        border-radius: 8px;
        color: #fff;
        margin: 0 0 20px 0;
    }

    &__tabContSuggestionTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin: 0 0 12px 0;
    }

    &__tabContSuggestionComment {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin: 0 0 12px 0;
    }

    &__tabContSuggestionEdited {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.017em;
        margin: 0 0 12px 0;
    }

    &__tabContSuggestionFlex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 12px;
        gap: 12px;
        margin: 0 0 12px 0;
    }

    &__tabContSuggestionLink {
        border: 1px solid #ffffff;
        border-radius: 9999px;
        padding: 8px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-decoration: none;
    }

    &__tabContSuggestionIcon {
        width: 24px;
    }

    &__tabContSuggestionName {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__tabContSuggestionEditArea {
        display: flex;
        justify-content: flex-end;
    }

    &__tabContSuggestionEditButton {
        cursor: pointer;
        color: #151208;
        background-color: #ffcc1d;
        padding: 6px 24px;
        border-radius: 18px;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &:disabled {
            cursor: default;
            background: #f8f7f5;
            color: #b8b7b4;

            &:hover {
                cursor: default;
                background: #f8f7f5;
                color: #b8b7b4;
                box-shadow: none;
            }
        }

        &:hover {
            color: #000;
            box-shadow: none;
            background: #d7a600;
        }
    }

    &__tabContProposalWrap {
    }

    &__tabContProposal {
        display: inline-block;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0em;
        padding: 12px 16px;
        background: #63c0b2;
        margin-bottom: 12px;
        margin-left: 42px;
        border-radius: 8px;

        &-time {
            margin-left: 7px;
        }
    }

    &__tabContSetDateButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        background: #ffcc1d;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 18px;
        border: none;
        cursor: pointer;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;

        margin-bottom: 12px;
        margin-left: 42px;

        transition-property: color background-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        &:hover {
            color: #000;
            background-color: #d7a600;
        }
    }

    &__inputArea {
        width: 100%;
        background: #fff;
        border-top: 1px solid #e8e8e8;
        padding: 12px 16px 8px 16px;
        box-sizing: border-box;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    &__inputAreaInner {
        display: flex;
    }

    &__inputIconArea {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f8f7f5;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: 2px;

        &-active {
            background: #ffcc1d;

            svg {
                fill: #000;
            }
        }

        &-activeA {
            cursor: pointer;
            position: relative;
        }

        &-hide {
            display: none;
        }
    }

    &__inputIconSup {
        min-width: 300px;
        white-space: nowrap;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 8px 16px;
        background: #3e3104;
        border-radius: 4px;
        position: absolute;
        top: -8px;
        left: 0;
        transform: translateY(-100%);

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid transparent;
            border-top: 8px solid #3e3104;
            position: absolute;
            bottom: -16px;
            left: 10px;
        }
    }

    &__inputIcon {
        height: 20px;
        width: auto;
        fill: #b8b7b4;
        fill-rule: evenodd;
    }

    &__fileInput {
        display: none;
    }

    &__inputLabel {
    }

    &__formGroup {
        margin-right: 12px;
        max-width: min-content;
    }

    &__textArea {
        width: 520px;
        max-width: 100%;
        min-height: 36px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__formSubmit {
        width: 77px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        cursor: pointer;
        color: #000;
        background: #ffcc1d;
        border: 1px solid #ffcc1d;
        box-sizing: border-box;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);

        &:link {
            color: #000;
            text-decoration: none;
        }

        &:visited {
            color: #000;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            text-decoration: none;
            box-shadow: none;
            background: #d7a600;
            border: 1px solid #d7a600;
        }

        &:active {
            color: #000;
            text-decoration: none;
        }

        &-disabled {
            color: #b8b7b4;
            box-shadow: none;
            background: #f8f7f5;
            border: 1px solid #f8f7f5;
            cursor: default;

            &:link {
                color: #b8b7b4;
                text-decoration: none;
            }

            &:visited {
                color: #b8b7b4;
                text-decoration: none;
            }

            &:hover {
                color: #b8b7b4;
                text-decoration: none;
            }

            &:active {
                color: #b8b7b4;
                text-decoration: none;
            }
        }
    }

    &__fileInputFileName {
        display: inline-block;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        padding: 0 36px 0 12px;
        box-sizing: border-box;
        background: #ffeaa4;
        border-radius: 16px;
        margin-bottom: 8px;
        position: relative;
    }

    &__fileInputFileDel {
        width: 19px;
        height: 19px;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 2px;
            height: 15px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-135deg);
        }

        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 15px;
            background: #151208;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transform: rotate(-45deg);
        }
    }

    &__inputAreaText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: center;
    }

    &__recommender {
        padding: 13px 20px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;

        &-mgt {
            margin-top: 16px;
        }
    }

    &__recomHeader {
        display: flex;
        justify-content: space-between;
    }

    &__recomTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__recomDlArea {
        display: flex;
        margin-bottom: 18px;
    }

    &__recomLink {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 38px;
        display: flex;
        align-items: center;
        transition-duration: 0.2s;

        &-last {
            margin-right: 0;
        }

        &::before {
            content: '';
            display: block;
            width: 17px;
            height: 17px;
            background-image: url(../../img/download-icon.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }

        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #d7a600;
            text-decoration: none;
            opacity: 0.7;
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }

    &__recomDl {
        display: flex;
        flex-flow: row wrap;
    }

    &__recomDt {
        width: 8.5em;
        padding-right: 0.5em;
        box-sizing: border-box;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 13px;
    }

    &__recomDd {
        width: calc(100% - 9em);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 13px;

        &-mgr {
            margin-right: 1em;
        }
    }

    &__recomHistory {
        &-active {
            margin-top: 16px;
        }
    }

    &__recomUl {
        list-style: none;
    }

    &__recomLi {
    }

    &__recomDate {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__recomInUl {
        list-style: none;
        padding-left: 10px;
        margin-left: 2px;
        border-left: 4px solid #e8e8e8;
    }

    &__recomInLi {
        display: flex;
        margin: 5px 0 10px 0;
    }

    &__recomInLiTime {
        width: 4em;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__recomInLiRight {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% - 4em);
    }

    &__recomInLiImgArea {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #e1e1e1;
        overflow: hidden;
    }

    &__recomInLiImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__recomInLiCatch {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 14px;
    }

    &__recomDdTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &::after {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #d7a600;
            border-right: 1px solid #d7a600;
            transform: rotate(135deg);
            margin-left: 7px;
        }

        &-open {
            &::after {
                margin-top: 5px;
                transform: rotate(-45deg);
            }
        }
    }

    &__recomTextArea {
        width: 100%;
        padding: 0 10px;
        max-height: 0;
        overflow: hidden;
        border: 1px solid #fff;
        box-sizing: border-box;
        background: #f8f7f5;
        border-radius: 4px;
        margin-top: 9px;
        transition-duration: 0.2s;

        &-open {
            padding: 10px;
            max-height: 3000px;
            border: 1px solid #e8e8e8;
        }
    }

    &__recomText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;

        &-before {
            color: #ff611d;
            text-decoration: line-through;
        }

        &-after {
            color: #069fcf;
        }
    }

    &__info {
        padding: 20px 24px 26px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 8px;
    }

    &__infoTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__infoPersonDetail {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
    }

    &__infoPersonImg {
        width: 80px;
        height: 80px;
        border-radius: 999px;
        border: 1px solid #e1e1e1;
        overflow: hidden;

        img {
            width: calc(100% + 2px);
        }
    }

    &__infoPersonText {
        margin-left: 24px;
    }

    &__infoPersonName {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__infoPersonOrg {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.011em;
        margin-top: 8px;
        color: #8a8883;
    }

    &__subs {
        background: #f8f7f5;
        border-radius: 8px;
        padding: 16px;
        margin-top: 23px;
    }

    &__subsBlock {
        margin-top: 16px;

        &:first-child {
            margin: 0;
        }
    }

    &__subsTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__subsUl {
        margin-top: 8px;
    }

    &__subsLi {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4px;

        &:first-child {
            margin: 0;
        }
    }

    &__subsCheckIcon {
    }

    &__subsLiText {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #369f8f;
        margin-left: 8px;
    }

    &__comment {
        margin-top: 24px;
    }

    &__commentP {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 1em;

        &:first-child {
            margin: 0;
        }
    }

    &__orgInfo {
        margin-top: 44px;

        &-mt0 {
            margin-top: 0;
        }
    }

    &__orgInfoTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.011em;
        color: #151208;
    }

    &__orgInfoDl {
        margin-top: 20px;
    }

    &__orgInfoDt {
        min-width: 124px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #8a8883;
        display: inline-block;
        margin-top: 12px;
        vertical-align: top;
    }

    &__orgInfoDd {
        margin-left: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #151208;
        display: inline-block;
        width: calc(100% - 136px);
        margin-top: 12px;

        &:first-child {
            margin: 0;
        }

        &-link {
            color: #d7a600;
        }
    }

    &__outText {
        width: 416px;
        max-width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 15px 22px;
        background: #069fcf;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
            0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 16px;
        left: -20px;
        transform: translateX(-100%);

        &-icon {
            display: inline-block;
            margin-right: 10px;
        }
    }

    &__alertArea {
        background: #fdd6cd;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        color: #cd1818;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__alertIcon {
        margin-right: 8px;
    }

    &__tabContFinish {
        width: 100%;
        background: #fff;
        border-radius: 8px;
        padding: 23px 20px 25px 20px;
        box-sizing: border-box;
        margin-top: 25px;
        text-align: center;
    }

    &__tabContFinishTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #3db19f;
        line-height: 24px;
        letter-spacing: 0.011em;
        text-align: center;
        display: flex;
        justify-content: center;

        &::before {
            content: '';
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #3db19f;
            background-image: url(../../img/check-icon.svg);
            background-size: 60%;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__tabContFinishText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 10px;
    }

    &__tabContFinishBtn {
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 6px 24px;
        background: #ffcc1d;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 18px;
        margin-top: 16px;
        transition-duration: 0.2s;

        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
            box-shadow: none;
            background: #d7a600;
            // border: 1px solid #B28E14;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }

        &-disabled,
        &-disabled:hover,
        &-disabled:visited {
            color: #b8b7b4;
            background: #f8f7f5;
            box-shadow: none;
            cursor: default;
        }
    }

    @media screen and (max-width: 1045px) {
        &__outText {
            left: revert;
            right: 24px;
            width: calc(100% - 48px);
        }
    }
    @media screen and (max-width: 768px) {
        &-active {
            z-index: 16;
        }

        &__contSpHeaderTitle {
            display: none;
        }

        &__contHeader {
            padding: 10px 12px 10px 16px;
        }

        &__contHeaderTitle {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.014em;
        }

        &__contDecline {
            width: 162px;
            padding: 6px 24px;
            white-space: nowrap;
        }

        &__close {
            margin-left: 7px;

            &:before {
                width: 1.6px;
            }

            &:after {
                width: 1.6px;
            }
        }

        &__step {
            padding: 22px 0 58px;

            &.step2 {
                max-width: 73.6%;
                padding: 22px 0 46px 0;
            }

            &.step3 {
                max-width: 73.6%;
            }

            &.step4 {
                max-width: 73.6%;
                padding: 22px 0 62px;
            }
        }

        // &__stepSup{
        //     bottom: -8px;
        // }

        // &__stepDate{
        //     bottom: -26px;
        // }

        &__stepEdit {
            &-with {
                left: 50%;
                bottom: -80px;
            }

            &-without {
                left: 50%;
                bottom: -56px;
            }
        }

        &__editText {
            display: none;
        }

        &__stepEditIcon {
            display: block;
        }

        &__compaUl {
            width: 91.46%;
            padding: 8px 16px;
            margin-bottom: 8px;
        }

        &__compaLiTitle {
            margin-right: 0;
            min-width: 5em;
        }

        &__tabs {
            padding: 9.5px 16px;
            width: 100%;
            justify-content: space-between;
            box-sizing: border-box;
        }

        &__tab {
            width: calc(43.6% - 8px);
            padding: 6px 12px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.014em;
        }

        &__tabContArea {
            padding: 16px 16px 20px 16px;
        }

        &__tabContCommentArea {
            margin: -4px 0 0 0;
        }

        &__tabContSuggestionMainArea {
            max-width: calc(100%);
            margin: 0 0 20px 45px;
        }

        &__tabContStatus {
            &-short {
                width: 65.59%;
                margin: 0 auto;
            }
        }

        &__tabContCheckDate {
            padding: 8px 12px;
            width: calc(100% - 36px);
        }

        &__tabContCheckHeader {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__tabContCheckText {
            margin-top: 8px;

            br {
                display: block;
            }
        }

        &__tabContCheckMain {
        }

        &__tabContCheckMainDate {
            display: block;
        }

        &__tabContCheckMainTime {
            display: block;
            margin-left: 0;
        }

        &__tabContCancel {
            min-width: 312px;
            bottom: -35px;
        }

        &__tabContCanceled {
            padding: 8px 12px;
        }

        &__tabContCanHeader {
            flex-direction: column;
        }

        &__tabContCanText {
            margin-top: 8px;
            margin-left: 0;
        }

        &__recommender {
            padding: 20px 16px 24px 16px;
        }

        &__recomHeader {
            margin-bottom: 20px;
        }

        &__recomDlArea {
            display: none;
        }

        &__recomDl {
            flex-direction: column;
        }

        &__recomDt {
            width: 100%;
            margin-bottom: 8px;
        }

        &__recomDd {
            width: 100%;
            margin-bottom: 24px;

            &:last-child {
                margin: 0;
            }
        }

        &__recomDate {
            font-weight: 400;
            margin-bottom: 8px;
        }

        &__recomInUl {
            padding-left: 12px;
            border-left: 2px solid #e8e8e8;
        }

        &__recomInLi {
            margin: 0 0 16px 0;
            flex-direction: column;

            &:last-child {
                margin: 0;
            }
        }

        &__recomInLiTime {
            margin-bottom: 8px;
        }

        &__recomInLiRight {
            display: flex;
            flex-flow: unset;
            width: 100%;
            flex-direction: column;
        }

        &__recomWrap {
            align-items: center;
            margin-bottom: 8px;
        }

        &__recomDdTitle {
            margin: 0;
        }

        &__recomInLiCatch {
            margin-left: 12px;
        }

        &__recomTextArea {
            padding: 0 8px;

            &-open {
                padding: 8px;
            }
        }

        &__info {
            padding: 16px;
        }

        &__orgInfoDl {
            margin-top: 24px;
        }

        &__orgInfoDt {
            margin-top: 24px;

            &:first-child {
                margin: 0;
            }
        }

        &__orgInfoDd {
            margin-left: 0;
            margin-top: 8px;
            width: 100%;
        }

        &__inputAreaInner {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 12px;
        }

        &__inputIconsArea {
            display: flex;
            order: 1;
        }

        &__inputIconSup {
            top: -16px;
            white-space: unset;
            box-sizing: border-box;
            min-width: calc(100vw - 32px);
        }

        &__formGroup {
            order: 0;
            width: 65%;
            margin: 0;
            max-width: none;

            .form__supArea {
                display: none;
            }
        }

        &__textArea {
            width: 100%;
        }

        &__formSubmit {
            order: 2;
        }

        &__inputArea {
            padding: 11px 16px 12px 16px;
        }

        &__inputAreaText {
            line-height: 20px;

            br {
                display: block;
            }
        }

        &__contResume {
            width: 148px;
            padding: 6px 24px;
            white-space: nowrap;
        }

        &__outText {
            box-sizing: border-box;
            width: calc(100% - 32px);
            right: 16px;

            &.selectionModal__outText-active {
                // JSで操作した方が良い
            }
        }

        &__mainInfo {
            width: 100%;
            padding: unset;
        }

        &__mainInfoContent {
            width: 100%;
            padding: unset;
            font-weight: 500;
        }

        &__mainType,
        &__mainNum,
        &__mainStatusNum,
        &__mainProgressNum {
            width: calc(50% - 6px);
            padding: unset;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            &:before {
                content: attr(data-type);
                display: block;
                color: #8a8883;
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                margin-right: 24px;
                width: 4.5rem;
            }
        }

        &__mainType,
        &__mainStatusNum {
            &:before {
                width: 2.7rem;
            }
        }

        &__mainEmpty {
            width: 100%;
            padding: 12px 0 0;
            border-top: 1px solid #e8e8e8;
        }
    }
}
