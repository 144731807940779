.pc {
}

.sp {
    display: none;
}

@media screen and (max-width: 768px) {
    .pc {
        display: none;
    }

    .sp {
        display: block;

        &-flex {
            display: flex;
        }
    }
}
