// comment
.comment {
    position: relative;
    width: 42%;
    max-width: 42%;
    &__inner {
        box-shadow: 0px 1px 12px 0px #0000000f;
        box-shadow: 0px 2px 4px 0px #0000000f;
        box-shadow: 0px 1px 5px 0px #0000000f;
        width: 350px;
        max-width: 80%;
        border-radius: 8px;
        padding: 24px;
        box-sizing: border-box;
        background: #50b8a8;
        color: #fff;
        margin: 0 30px 30px 30px;
        display: none;
        position: absolute;
        top: 45px;
        top: 9%;
        left: 30px;
    }

    &__title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: block;
            width: 18px;
            height: 22px;
            background-image: url(../../img/hinto-icon.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    #exp3 {
        top: 82%;
    }
}

@media screen and (max-width: 768px) {
    .comment {
        width: auto;
        position: static;

        &__inner {
            position: absolute;
            margin: 0 auto;
            width: 92%;
            max-width: 100%;
            top: 0;
            left: 4%;
            padding: 16px 16px 12px 16px;

            &-up {
                transform: translateY(-100%);
            }
        }

        &__text {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.017em;
        }

        &__close {
            margin: 20px auto 0 auto;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: auto;
            }
        }
    }

    #exp1 {
        top: 9% !important;
    }

    #exp2 {
        top: 70% !important;
    }
}

@media screen and (max-width: 768px) {
    .comment {
        width: auto;
        position: static;

        &__inner {
            position: absolute;
            margin: 0 auto;
            width: 92%;
            max-width: 100%;
            top: 0;
            left: 4%;
            padding: 16px 16px 12px 16px;

            &-up {
                transform: translateY(-100%);
            }
        }

        &__text {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.017em;
        }

        &__close {
            margin: 20px auto 0 auto;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: auto;
            }
        }
    }
}
