.btn {
    transition-duration: 0.2s;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.014em;
    white-space: nowrap;

    cursor: pointer;
    // margin-bottom: 10px;

    &-fs14 {
        font-size: 14px;
    }

    &-h36 {
        height: 36px;
    }

    &-mgl12 {
        margin-left: 12px;
    }

    &-mgl16 {
        margin-left: 16px;
    }

    &-mgr {
        margin-right: 10px;
    }

    &-mgrPc {
        margin-right: 10px;
    }

    &-mgr16 {
        margin-right: 16px;
    }

    &-mgr12 {
        margin-right: 12px;
    }

    &-mgr24 {
        margin-right: 24px;
    }

    &-bfArrow {
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            transform: rotate(-135deg);
            margin-right: 12px;
            margin-top: 2px;
        }
    }

    &-afArrow {
        display: flex;
        align-items: center;

        &::after {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            transform: rotate(45deg);
            margin-left: 12px;
            margin-top: 2px;
        }
    }

    &:link {
        color: #d7a600;
        text-decoration: none;
    }

    &:visited {
        color: #d7a600;
        text-decoration: none;
    }

    &:hover {
        color: #000;
        background: #ffcc1d;
        border: 1px solid #ffcc1d;
        text-decoration: none;

        .btn__btnIcon {
            svg {
                fill: #000;
            }
        }
    }

    &:active {
        color: #d7a600;
        text-decoration: none;
    }

    &__shadowBtn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        border-radius: 20px;
        color: #000;
        background: #ffcc1d;
        border: 1px solid #ffcc1d;
        box-sizing: border-box;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);

        &-wide {
            padding: 0 44px;
        }

        &-inactive {
            background: #fff;
            border: 0px;
            color: gray;
            transition-duration: 0s;

            &:hover {
                background: #fff;
                border: 0px;
                color: gray;
                cursor: none;
            }
        }

        &:link {
            color: #000;
            text-decoration: none;
        }

        &:visited {
            color: #000;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            text-decoration: none;
            box-shadow: none;
            background: #d7a600;
            border: 1px solid #d7a600;
        }

        &:active {
            color: #000;
            text-decoration: none;
        }

        .btn__btnIcon {
            svg {
                fill: #000;
            }
        }

        &-blue {
            background: #3db19f;
            border: 1px solid #3db19f;
            color: #fff;
            &:link {
                color: #fff;
            }

            &:visited {
                color: #fff;
            }

            &:hover {
                color: #fff;
                box-shadow: none;
                background: #3db19f;
                border: 1px solid #3db19f;
            }

            &:active {
                color: #fff;
                text-decoration: none;
            }

            .btn__btnIcon {
                svg {
                    fill: #fff;
                }
            }
        }
    }

    &__textBtn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        color: #d7a600;
        // height: 40px;
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid transparent;
        box-sizing: border-box;
        // border: none;
        background: #fff;
        font-weight: 400;

        &:disabled,
        &-disabled {
            color: #e1e1e1 !important;
            &:hover {
                cursor: not-allowed;
                color: #e1e1e1 !important;
                background: none;
                border: 1px solid #e1e1e1;

                .btn__btnIcon {
                    svg {
                        fill: #e1e1e1;
                    }
                }
            }

            .btn__btnIcon {
                svg {
                    fill: #e1e1e1;
                    .cls-1 {
                        fill: #e1e1e1;
                    }
                }
            }
        }

        &-wide {
            padding: 0 44px;
        }

        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            border: 1px solid #ffcc1d;
            text-decoration: none;

            .btn__btnIcon {
                svg {
                    fill: #000;
                }
            }
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }

        &-red {
            background: transparent;
            color: #cd1818;
            border: none;
            font-weight: 500;
            .btn__btnIcon {
                svg {
                    fill: #cd1818;
                }
            }
            &:link {
                color: #cd1818;
                text-decoration: none;
            }

            &:visited {
                color: #cd1818;
                text-decoration: none;
            }

            &:hover {
                color: #cd1818;
                background: #fdd6cd;
                border: none;
                text-decoration: none;

                .btn__btnIcon {
                    svg {
                        fill: #cd1818;
                    }
                }
            }

            &:active {
                color: #cd1818;
                text-decoration: none;
            }
            &.active {
                color: #cd1818;
                background: #fdd6cd;
                border: none;
                text-decoration: none;

                .btn__btnIcon {
                    svg {
                        fill: #cd1818;
                    }
                }
            }
        }
    }

    &__outlineBtn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        border-radius: 20px;
        border: 1px solid #e1e1e1;
        box-sizing: border-box;
        background: #fff;
        color: #d7a600;

        &-wide {
            padding: 0 44px;
        }

        &:disabled,
        &-disabled {
            color: #e1e1e1;
            &:hover {
                cursor: not-allowed;
                color: #e1e1e1 !important;
                background: none;
                border: 1px solid #e1e1e1;

                .btn__btnIcon {
                    svg {
                        fill: #e1e1e1;
                    }
                }
            }

            .btn__btnIcon {
                svg {
                    fill: #e1e1e1;
                    .cls-1 {
                        fill: #e1e1e1;
                    }
                }
            }
        }

        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            border: 1px solid #ffcc1d;
            text-decoration: none;

            .btn__btnIcon {
                svg {
                    fill: #000;
                }
            }
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }

    &__textDissable {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        color: #b8b7b4;
        height: 40px;
        padding: 0 24px;
        border-radius: 4px;
        border: 1px solid transparent;
        box-sizing: border-box;
        // border: none;
        background: transparent;
        border: none;
        cursor: default;

        &:link {
            color: #b8b7b4;
            text-decoration: none;
        }

        &:visited {
            color: #b8b7b4;
            text-decoration: none;
        }

        &:hover {
            color: #b8b7b4;
            background: transparent;
            border: none;

            .btn__btnIcon {
                svg {
                    fill: #b8b7b4;
                }
            }
        }

        &:active {
            color: #b8b7b4;
            text-decoration: none;
        }

        &-active {
            cursor: pointer;
            color: #d7a600;
            &:link {
                color: #d7a600;
                text-decoration: none;
            }

            &:visited {
                color: #d7a600;
                text-decoration: none;
            }

            &:hover {
                color: #000;
                background: #ffcc1d;
                border: 1px solid #ffcc1d;
                text-decoration: none;

                .btn__btnIcon {
                    svg {
                        fill: #000;
                    }
                }
            }

            &:active {
                color: #d7a600;
                text-decoration: none;
            }
        }
    }

    &__outlineDissable {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        border-radius: 20px;
        color: #d7a600;
        cursor: pointer;

        border: 1px solid #e1e1e1;
        background: #fff;

        &:hover {
            border: 1px solid #ffcc1d;
        }

        &-noActive {
            color: #b8b7b4;
            cursor: default;

            &:hover {
                border: 1px solid #e1e1e1;
                background: #fff;
                color: #b8b7b4;

                .btn__btnIcon {
                    svg {
                        fill: #b8b7b4;
                    }
                }
            }

            .btn__btnIcon {
                svg {
                    fill: #b8b7b4;
                }
            }
        }
    }

    &__yellowDessable,
    &__yellowDessableBtn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        border-radius: 20px;
        border: 1px solid #f8f7f5;
        background: #f8f7f5;
        box-sizing: border-box;
        color: #b8b7b4;
        cursor: default;

        &.btn-bfArrow {
            &::before {
                border-top: 1px solid #b8b7b4;
                border-right: 1px solid #b8b7b4;
            }
        }
        &.btn-bfArrow {
            &::after {
                border-top: 1px solid #b8b7b4;
                border-right: 1px solid #b8b7b4;
            }
        }

        &:hover {
            border: 1px solid #f8f7f5;
            background: #f8f7f5;
            color: #b8b7b4;
            &.btn-bfArrow {
                &::before {
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                }
            }
            &.btn-bfArrow {
                &::after {
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                }
            }
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            border: 1px solid #ffcc1d;
            text-decoration: none;
            cursor: pointer;

            .btn__btnIcon {
                svg {
                    fill: #000;
                }
            }

            &.btn-bfArrow {
                &::before {
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                }
            }
            &.btn-bfArrow {
                &::after {
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                }
            }

            &:link {
                color: #000;
                text-decoration: none;
            }

            &:visited {
                color: #000;
                text-decoration: none;
            }

            &:active {
                color: #000;
                text-decoration: none;
            }

            &:hover {
                color: #000;
                // background: #ffcc1d;
                background: #d7a600;
                // border: 1px solid #ffcc1d;
                border: 1px solid #d7a600;
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                    0px 1px 12px rgba(0, 0, 0, 0.06);
                &.btn-bfArrow {
                    &::before {
                        border-top: 1px solid #000;
                        border-right: 1px solid #000;
                    }
                }
                &.btn-bfArrow {
                    &::after {
                        border-top: 1px solid #000;
                        border-right: 1px solid #000;
                    }
                }
            }
        }

        &-wide {
            padding: 0 44px;
        }
    }

    &__shadowDissableBtn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        border-radius: 20px;
        border: 1px solid #f8f7f5;
        background: #f8f7f5;
        box-sizing: border-box;
        color: #b8b7b4;
        cursor: default;

        &:link {
            color: #b8b7b4;
            text-decoration: none;
        }

        &:visited {
            color: #b8b7b4;
            text-decoration: none;
        }

        &:hover {
            color: #b8b7b4;
            text-decoration: none;
            background: #f8f7f5;
            border: 1px solid #f8f7f5;
        }

        &:active {
            color: #b8b7b4;
            text-decoration: none;
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            border: 1px solid #ffcc1d;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                0px 1px 12px rgba(0, 0, 0, 0.06);
            cursor: pointer;

            .btn__btnIcon {
                svg {
                    fill: #000;
                }
            }

            &:hover {
                color: #000;
                background: #d7a600;
                box-shadow: none;
            }
        }

        &-active-2 {
            color: #b8b7b4;
            background: #f8f7f5;
            border: 1px solid #f8f7f5;

            .btn__btnIcon {
                svg {
                    fill: #000;
                }
            }

            &:hover {
                color: #000;
                background: #d7a600;
                box-shadow: none;
            }
        }

        .btn__btnIcon {
            svg {
                fill: #b8b7b4;
            }
        }
    }

    &__btnIcon {
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            fill: #d7a600;
            fill-rule: evenodd;
            margin-right: 8px;
            transition-duration: 0.2s;
        }
    }

    &-iconL {
        padding-left: 20px;
    }

    &-iconR {
        padding-right: 20px;
    }
    &-w100 {
        width: 100%;
        box-sizing: border-box;
    }

    &__approval {
        font-size: 14px;
        color: #fff;
        background: #069fcf;
        padding: 10px 25px;
        display: inline-block;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        letter-spacing: 0.014em;
        transition-duration: 0.2s;

        &:hover {
            background: #0787af;
            box-shadow: none;
        }

        &-no {
            font-size: 14px;
            color: #fff;
            background: #cd1818;
            padding: 10px 25px;
            margin-left: 12px;
            display: inline-block;
            border: none;
            border-radius: 30px;
            cursor: pointer;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                0px 1px 12px rgba(0, 0, 0, 0.06);
            letter-spacing: 0.014em;
            transition-duration: 0.2s;

            &:hover {
                background: #981111;
                box-shadow: none;
            }
        }
    }
    &__nextPrevBtn {
        width: 100px !important;
        background-color: #0000 !important;
        border-radius: 0 !important;
        border: none;
    }

    &__fullScreen {
        z-index: 9;
    }
}

.toggleBtn {
    width: 228px;
    height: 40px;
    background: #ffffff;
    border-radius: 20px;
    padding: 2px;
    box-sizing: border-box;
    display: flex;

    &__btn {
        width: 50%;
        height: 100%;
        padding: 0 13px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #c4c4c4;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 20px;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            color: #000;
            background: #ffcc1d;

            .toggleBtn__btnIcon {
                fill: #000;
            }
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            cursor: default;

            .toggleBtn__btnIcon {
                fill: #000;
            }
        }
    }

    &__btnIcon {
        display: inline-block;
        width: 15px;
        fill: #c4c4c4;
        fill-rule: evenodd;
        margin-right: 5px;
        transition-duration: 0.2s;
    }
}

@media screen and (max-width: 768px) {
    .btn {
        white-space: nowrap;
        &__outlineDissable {
            &-spnb {
                border: none;
                &:hover {
                    border: none;
                }
            }
        }
        &-sppd66 {
            padding: 0 66px;
        }
        &-spW100A {
            width: 100%;
            margin-top: 12px;
        }
        &-spW100 {
            width: 100%;
            max-width: 300px;
            padding: 0 24px;
        }
        &-miniPd {
            padding: 0 16px;
        }
        &-mgr {
            margin-right: 12px;
        }
        &-mgrPc {
            margin-right: 0;
        }
        &-spMgr {
            margin-right: 12px;
        }
        &-sph32 {
            height: 32px;
        }
        &__textDissable {
            &-active {
                &:hover {
                    background: transparent;
                    color: #d7a600;
                    border: 1px solid transparent;
                }
            }
        }
    }
}
