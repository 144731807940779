// member
@import '../variables';

$shadow4: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(0, 0, 0, 0.08);

.adminLogin {
    font-size: 14px;
    background: #f5f4ef;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;

    &__mainWrap {
        width: 448px;
        max-width: 100%;
        background: #fff;
        padding: 60px 60px 40px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01), 0px 5px 8px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
    }

    &__logo {
        margin-bottom: 50px;
    }

    &__inputWrap {
        margin-bottom: 30px;
        text-align: left;

        .form__inputText {
            width: 100%;
        }
    }

    &__inputTtl {
        margin-bottom: 10px;
        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    &__btnWrap {
        .btn {
            margin: 0 auto 20px;
        }

        .pwForget {
            a {
                color: #8a8883;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__copy {
        color: #151208;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        letter-spacing: 1px;
    }
}

.adminMember {
    &__mainWrap {
        width: 864px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
        margin-top: 8px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        justify-content: space-between;
        color: #151208;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        transition-duration: 0.2s;
        background: #fff;

        &-gray {
            color: #8a8883;
        }

        &:nth-child(1) {
            background: transparent;
        }
    }

    &__mainLiInner {
        display: flex;
        width: calc(100% - 48px);
    }

    &__mainName {
        width: 16%;
        padding: 20px 0 20px 24px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainJobMail {
        width: 24%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainAuthority {
        width: 10%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainStatus {
        width: 18%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        span {
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                flex-grow: 0;
                margin-right: 12px;
            }
        }

        &-ac {
            &::before {
                border: 1.5px solid #52d276;
                background: #52d276;
            }
        }

        &-pause {
            &::before {
                border: 1.5px solid #b8b7b4;
                background: #b8b7b4;
            }
        }

        &-archive {
            &::before {
                border: 1.5px solid #8a8883;
                background: transparent;
            }
        }

        &-col {
            flex-flow: column;
            justify-content: center;
        }

        // &-gray{
        //         display: block;
        //         color: #8A8883;
        //         &::before{
        //             display: none;
        //         }

        // }

        span {
            &.adminMember__mainStatus-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;

                &::before {
                    display: none;
                }
            }
        }

        span {
            &.adminMember__mainStatus-invite {
                &::before {
                    width: 17px;
                    height: 13px;
                    border-radius: 0;
                    background-image: url(../../img/mail.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        span {
            &.adminMember__mainStatus-expired {
                color: #cd1818;

                &::before {
                    width: 18px;
                    height: 18px;
                    border-radius: 0;
                    background-image: url(../../img/mail2.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__mainCertification {
        width: 16%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainLoginBtn {
        width: 16%;
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainDotArea {
        width: 48px;
        padding: 20px 16px 20px 0;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
    }

    &__mainDot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            background: #fff4d1;
        }

        &.active {
            background: #fff4d1;
        }
    }

    &__mainDotText {
        width: 168px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // padding: 16px;
        border-radius: 8px;
        box-shadow: $shadow4;
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 3;
        transform: translate(calc(-50% + 16px), 16px);
        margin: auto;
        white-space: nowrap;
        cursor: pointer;
        display: none;
    }

    &__mainDotBg {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        // background: #000;
        z-index: 2;
        display: none;
    }

    &-dpb {
        display: block;
    }

    &-pdb0 {
        padding-bottom: 8px;
    }

    &-pdt4 {
        padding-top: 4px;
    }
}
@media screen and (max-width: 768px) {
    .adminMember {
        &__mainLi {
            flex-flow: column;
            border-radius: 0;
            margin-bottom: 2px;
            margin: 0 auto 1px auto;
            padding: 16px 4%;
            margin-top: 12px;

            &:nth-child(1) {
                display: none;
                margin-top: 0;
            }
        }

        &__mainJobMail {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
        }

        &__mainName,
        &__mainJobMail,
        &__mainAuthority,
        &__mainStatus,
        &__mainCertification,
        &__mainLoginBtn {
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            align-items: flex-start;
            &::before {
                content: attr(data-title);
                color: #8a8883;
                width: 8em;
            }
        }

        &__mainLiInner {
            display: block;
        }
    }
}

.invited {
    &__mainWrap {
        width: 1024px;
        max-width: 100%;
        padding: 25px 0 0 0;
        box-sizing: border-box;
        margin: 0 auto;
        border-radius: 8px;
    }

    &__header {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
        position: relative;
    }

    &__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 32px;
        letter-spacing: 0.008em;
    }

    &__subTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #8a8883;
        margin-bottom: 22px;
        letter-spacing: 0.008em;
        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    &__mainTitle-num {
        display: inline-block;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.02em;
        margin-left: 15px;
    }

    &__subTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-top: 16px;
    }

    &__txt {
        font-size: 14px;
        line-height: 24px;
        color: #8a8883;
        margin-top: 10px;
    }

    &__btnArea {
        margin-top: 26px;

        .btn {
            width: 130px;
        }
    }
}

@media screen and (max-width: 768px) {
    .invited {
        padding: 0 !important;

        &__mainTabLink {
            text-align: center;
        }

        &__title {
            padding: 16px 20px;
        }

        &__footer {
            &-index {
                z-index: 16;
            }
        }

        &__footerReq {
            background: transparent;
            color: black;
        }

        &__subTitle {
            margin: 24px 0 16px 16px;
        }
    }
}

.invitedConf {
    background: #fff;
    justify-content: center;
    padding: 24px 0;

    &__inner {
        width: 824px;
        max-width: 100%;
        margin: 0 auto;
        padding: 18px 0 30px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    &__dt {
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: left;
        margin-bottom: 10px;
    }

    &__dd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        text-align: left;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .invitedConf {
        padding: 0;
        &__dl {
            padding: 0 16px 25px;
        }

        &__inner {
            padding: 0 0 30px;
        }
    }
}

.company {
    &__mainWrap {
        width: 864px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
        margin-top: 8px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        justify-content: space-between;
        color: #151208;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        transition-duration: 0.2s;
        background: #fff;

        &-gray {
            color: #8a8883;
        }

        &:nth-child(1) {
            background: transparent;
        }

        &:not(.company__mainLi-gray) {
            &:hover {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                    0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
            }
        }
    }

    &__mainLiInner {
        display: flex;
        width: 100%;
    }

    &__mainId {
        width: 8%;
        padding: 20px 0 20px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainCompanyName {
        width: 26%;
        padding: 20px 0 20px 24px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainName {
        width: 16%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainTel {
        width: 16%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainStatus {
        width: 22%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        span {
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                flex-grow: 0;
                margin-right: 12px;
            }
        }

        &-ac {
            &::before {
                border: 1.5px solid #52d276;
                background: #52d276;
            }
        }

        &-pause {
            &::before {
                border: 1.5px solid #b8b7b4;
                background: #b8b7b4;
            }
        }

        &-archive {
            &::before {
                border: 1.5px solid #8a8883;
                background: transparent;
            }
        }

        &-col {
            flex-flow: column;
            justify-content: center;
        }

        span {
            &.member__mainStatus-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;

                &::before {
                    display: none;
                }
            }
        }

        span {
            &.member__mainStatus-invite {
                &::before {
                    width: 17px;
                    height: 13px;
                    border-radius: 0;
                    background-image: url(../../img/mail.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        span {
            &.member__mainStatus-expired {
                color: #cd1818;

                &::before {
                    width: 18px;
                    height: 18px;
                    border-radius: 0;
                    background-image: url(../../img/mail2.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__mainCertification {
        width: 12%;
        padding: 20px 24px 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainLoginBtn {
        width: 12%;
        padding: 20px 24px 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainDotArea {
        width: 48px;
        padding: 20px 16px 20px 0;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
    }

    &__mainDot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            background: #fff4d1;
        }

        &.active {
            background: #fff4d1;
        }
    }

    &__mainDotText {
        width: 168px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // padding: 16px;
        border-radius: 8px;
        box-shadow: $shadow4;
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 3;
        transform: translate(calc(-50% + 16px), 16px);
        margin: auto;
        white-space: nowrap;
        cursor: pointer;
        display: none;
    }

    &__mainDotBg {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        // background: #000;
        z-index: 2;
        display: none;
    }

    &-dpb {
        display: block;
    }

    &-pdb0 {
        padding-bottom: 8px;
    }

    &-pdt4 {
        padding-top: 4px;
    }
}

.log {
    &__mainWrap {
        width: 1104px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
        margin-top: 8px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        justify-content: space-between;
        color: #151208;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        transition-duration: 0.2s;
        background: #fff;

        &-gray {
            color: #8a8883;
        }

        &:nth-child(1) {
            background: transparent;
        }
    }

    &__mainLiInner {
        display: flex;
        width: 100%;
    }

    &__mainDate {
        width: 16%;
        padding: 20px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainCompanyName {
        width: 20%;
        padding: 20px 24px 20px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        box-sizing: border-box;

        span {
            &.log__mainCompanyName-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;
            }
        }
    }

    &__mainCategory {
        width: 8%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainAccount {
        width: 12%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;

        span {
            display: flex;
            align-items: center;

            &.log__mainAccount-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;
            }
        }
    }

    &__mainContent {
        width: 16%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainRecruit {
        width: 28%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;

        span {
            display: flex;
            align-items: center;

            &.log__mainRecruit-txt {
                a {
                    color: #d7a600;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.log__mainRecruit-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;
            }
        }
    }

    &-dpb {
        display: block;
    }

    &-pdb0 {
        padding-bottom: 8px;
    }

    &-pdt4 {
        padding-top: 4px;
    }
}

@media screen and (max-width: 768px) {
    .log {
        &__mainLi {
            flex-flow: column;
            border-radius: 0;
            margin-bottom: 2px;
            margin: 0 auto 1px auto;
            padding: 16px 4%;
            margin-top: 12px;

            &:nth-child(1) {
                display: none;
                margin-top: 0;
            }
        }

        &__mainTitleArea {
            padding: 0 16px 0 16px;
        }

        &__headerTitle {
            color: #8a8883;
            width: 10em;
            padding-bottom: 8px;
        }

        &__mainCompanyName-gray2,
        &__mainAccount-gray2,
        &__mainRecruit-gray2 {
            color: #8a8883;
            font-weight: 400;
        }

        &__mainRecruit-txt {
            a {
                color: #d7a600;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__mainDate,
        &__mainContent {
            display: block;
        }

        &__mainDate,
        &__mainCompanyName,
        &__mainCategory,
        &__mainAccount,
        &__mainContent,
        &__mainRecruit {
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            align-items: flex-start;
        }

        &__pdb2 {
            padding-bottom: 2px;
        }

        &__mainBoxContainer {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__boxHeader {
            width: 9em;
            padding-right: 10px;
        }

        &__boxDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 60%;
            height: auto;
        }
    }
}

.adminEvaluation {
    &__mainWrap {
        width: 1104px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        padding: 16px 0;
        box-sizing: border-box;
        margin-top: 8px;

        .evaluation__leftEvaHeaderLink:not(.evaluation__leftEvaHeaderLink-active) {
            background: #fff;

            &:hover {
                color: #000;
                background: #ffcc1d;
                text-decoration: none;
            }
        }

        .evaluation__leftEvaHeaderLink-active {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;
        }
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 15px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        justify-content: space-between;
        color: #151208;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        transition-duration: 0.2s;
        background: #fff;

        &-gray {
            color: #8a8883;
            background: #f5f4ef;
            border-radius: 0 !important;
            position: sticky;
            top: 56px;

            .adminEvaluation__mainDate {
                font-family: 'Noto Sans JP', sans-serif;
            }
        }
    }

    &__mainLiInner {
        display: flex;
        width: 100%;
    }

    &__mainDate {
        width: 16%;
        padding: 20px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainValuer {
        width: 20%;
        padding: 20px 24px 20px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        box-sizing: border-box;

        span {
            &.adminEvaluation__mainValuer-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;
            }
        }
    }

    &__mainEvaluated {
        width: 20%;
        padding: 20px 24px 20px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        box-sizing: border-box;

        span {
            &.adminEvaluation__mainEvaluated-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;
            }
        }
    }

    &__mainValue {
        width: 12%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;

        &-bad,
        &-good {
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }
    }

    &__mainMutual {
        width: 10%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &-no {
            color: #cd1818;
        }
    }

    &__mainApprovalStatus {
        width: 10%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &-no {
            color: #cd1818;
        }
    }

    &__mainDetailBtn {
        width: 12%;
        padding: 20px 24px 20px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &-txt {
            color: #d7a600;
            margin-right: 8px;
        }
    }

    &-dpb {
        display: block;
    }

    &-pdb0 {
        padding-bottom: 8px;
    }

    &-pdt4 {
        padding-top: 4px;
    }
}

@media screen and (max-width: 768px) {
    .adminEvaluation {
        &__mainTitleArea {
            padding: 0 16px;
        }

        &__mainLi {
            flex-flow: column;
            border-radius: 0;
            margin-bottom: 2px;
            margin: 0 auto 1px auto;
            padding: 16px 4%;
            margin-top: 12px;

            &:nth-child(1) {
                display: none;
                margin-top: 0;
            }
        }

        &__mainDate {
            margin-bottom: 0 !important;
        }

        &__mainDate,
        &__mainValuer,
        &__mainValue,
        &__mainMutual,
        &__mainApprovalStatus,
        &__mainDetailBtn {
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            align-items: flex-start;
        }

        &__mainDate,
        &__mainMutual,
        &__mainApprovalStatus,
        &__mainDetailBtn {
            display: block;
        }

        &__pdb2 {
            padding-bottom: 2px;
        }

        &__mainBoxContainer {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__headerTitle {
            color: #8a8883;
            width: 10em;
            padding-bottom: 8px;
        }

        &__boxHeader {
            width: 9em;
            padding-right: 10px;
        }

        &__boxDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 60%;
            height: auto;
        }
    }
}

// ===================== //
// selectionModal
// ===================== //

.adminEvaluationModal {
    line-height: 24px;

    &__contWrap {
        padding: 0 24px 24px;
    }

    &__ttlArea {
        font-size: 16px;
        padding: 20px 0;
    }

    &__ttlAreaTitle {
        font-weight: 500;
        color: #8a8883;
    }

    &__valueDetail {
        padding: 20px 16px;
        border: 1px solid #e8e8e8;
        border-radius: 4px;

        & + .adminEvaluationModal__valueDetail {
            margin-top: 12px;
        }
    }

    &__valueDetailTitle {
        font-size: 14px;
        font-weight: 500;
        color: #8a8883;
        margin-bottom: 10px;
    }

    &__valueDetailPtoP {
        font-size: 14px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-gray {
            font-weight: 400;
            color: #8a8883;
            display: block;
        }
    }

    &__valueDetailPtoPList {
        width: calc((100% - 56px) / 2);
        list-style-type: none;

        &-arrow {
            width: 56px;
            text-align: center;
            list-style-type: none;
        }
    }

    &__valueDetailValue {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
        }
    }

    &__valueDetailTxt {
        font-size: 14px;
        font-weight: 400;
        padding-right: 98px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
        }
    }

    &__linkBack {
        padding: 10px 0 0;
        margin-bottom: 15px;

        a {
            font-size: 14px;
            font-weight: 500;
            color: #d7a600;
            text-decoration: none;
            transition-duration: 0.2s;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    &__statusDetail {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        border-radius: 4px;

        &.status-ok {
            color: #3db19f;
            background: #e0f7f3;

            &::before {
                content: url(../../img/approval.svg);
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        &.status-no {
            color: #cd1818;
            background: #f7e0e0;

            &::before {
                content: url(../../img/approval-no.svg);
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }
    }

    &__noReason {
        background: #f8f7f5;
        padding: 12px 16px;
        margin-top: 12px;
        border-radius: 4px;

        &Title {
            font-size: 14px;
            font-weight: 500;
            color: #8a8883;
            margin-bottom: 4px;
        }

        &Txt {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .btn {
        &__approval {
            font-size: 14px;
            color: #fff;
            background: #069fcf;
            padding: 10px 25px;
            display: inline-block;
            border: none;
            border-radius: 30px;
            cursor: pointer;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                0px 1px 12px rgba(0, 0, 0, 0.06);
            letter-spacing: 0.014em;
            transition-duration: 0.2s;

            &:hover {
                background: #0787af;
                box-shadow: none;
            }

            &-no {
                font-size: 14px;
                color: #fff;
                background: #cd1818;
                padding: 10px 25px;
                margin-left: 12px;
                display: inline-block;
                border: none;
                border-radius: 30px;
                cursor: pointer;
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                    0px 1px 12px rgba(0, 0, 0, 0.06);
                letter-spacing: 0.014em;
                transition-duration: 0.2s;

                &:hover {
                    background: #981111;
                    box-shadow: none;
                }
            }
        }
    }
}

.modal {
    &__adminEvaluation {
        max-width: 100%;
        width: 20%;
    }

    &__choiceContInner:not(.choiceModal) {
        height: auto;
        padding: 28px 28px 100px 28px;
    }
}

.calendar {
    &__icon {
        margin-right: 10px;
    }

    &__connectTxt {
        margin: 0 10px;
    }

    &__group {
        display: flex;
        align-items: center;

        .form__customSelectWrap {
            width: 144px;

            .form__customSelect {
                width: 100%;
            }
        }
    }
}

.adminWrap {
    flex-flow: column;
    background: #f5f4ef;
    .w1024 {
        width: 1024px;
    }
}

.invited {
    justify-content: center;
    padding: 24px 0;
    background: #f5f4ef;

    &__main {
        width: 824px;
        max-width: 100%;
        background: #fff;
        margin: 0 auto;
        border-radius: 8px;
    }

    &__mainTabs {
        display: flex;
        list-style: none;
        padding: 12px 8px 0;
        box-sizing: border-box;
    }

    &__mainTab {
        width: calc(100% / 2);
        margin: 0 12px;
    }

    &__mainTabLink {
        display: flex;
        justify-content: center;
        padding: 8px 24px;
        border-radius: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.011em;
        transition-duration: 0.2s;
        text-decoration: none;

        &:visited {
            color: #8a8883;
        }

        &:hover {
            color: #151208;
            background: #ffcc1d;
        }
    }

    &__mainTabLink-active {
        background: #ffcc1d;

        &:visited {
            color: #151208;
        }
    }
}

.x2 {
    gap: 0 40px;
    display: flex;
    &__inner {
        width: calc((100% - 40px) / 2);
    }
}

.outline {
    &__mainWrap {
        width: 1024px;
        max-width: 100%;
        padding: 25px 0 0 0;
        box-sizing: border-box;
        margin: 0 auto;
        border-radius: 8px;
    }

    &__header {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
        position: relative;
    }

    &__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.001em;
        margin-top: 10px;
    }

    &__headerId {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        margin-top: 10px;
        letter-spacing: 0.005em;
    }

    &__subTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-top: 16px;
        letter-spacing: 0.008em;
    }

    &__dl {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;

        dt {
            width: 13%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #8a8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-bottom: 5px;
            text-align: left;
        }

        dd {
            width: 87%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #151208;
            line-height: 24px;
            padding-left: 20px;
            letter-spacing: 0.014em;
            text-align: left;
            margin-bottom: 5px;
            box-sizing: border-box;
        }
    }

    &__mainDotArea {
        width: 48px;
        padding: 20px 16px 20px 0;
        box-sizing: border-box;
        position: absolute;
        top: 30px;
        right: 24px;

        .member__mainDotText {
            width: 168px;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            box-sizing: border-box;
            // padding: 16px;
            border-radius: 8px;
            box-shadow: $shadow4;
            background: #fff;
            padding: 4px 0;
            font-size: 14px;
            font-weight: 400;
            color: #151208;
            line-height: 24px;
            letter-spacing: 0.014em;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 3;
            transform: translate(calc(-50% + 16px), 16px);
            margin: auto;
            white-space: nowrap;
            cursor: pointer;
            display: none;

            li {
                width: 100%;
                list-style-type: none;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #e8e8e8;
                }

                a {
                    box-sizing: border-box;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #151208;
                    line-height: 24px;
                    letter-spacing: 0.014em;
                    text-decoration: none;
                    display: block;
                    padding: 12px 16px;
                    white-space: nowrap;
                    transition-duration: 0.2s;

                    &:hover {
                        font-weight: 500;
                        background: #ffcc1d;
                    }
                }
            }
        }

        .member__mainDotBg {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            // background: #000;
            z-index: 2;
            display: none;
        }
    }

    &__mainDot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            background: #fff4d1;
        }

        &.active {
            background: #fff4d1;
        }
    }
}

@media screen and (max-width: 768px) {
    .outline {
        &__header {
            width: 100vw;
            border-radius: 0;
            padding: 0 0 16px 0;
        }

        &__spHeader {
            display: block;
            width: 100%;
            background: #fff;
            height: 55px;
            z-index: 10;
            border-bottom: 1px solid #e8e8e8;
        }

        &__rightDt {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #8a8883;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-top: 13px;
        }

        &__rightDd {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-top: 5px;
        }
        &__dl {
            dt {
                width: 100%;
            }
            dd {
                width: 100%;
            }
        }

        &__acc {
            &-mgmt {
                margin-top: 31px;
            }

            &-header {
                padding-left: 10px;
            }

            &-modal {
                height: 98%;
            }

            &-dropDown {
                display: block;
            }

            &-options {
                padding: 0 0 56px 0 !important;
            }
        }

        &__footerTab {
            z-index: 16;
        }
    }
}

@media screen and (max-width: 768px) {
    .member {
        &__custom-main-status {
            display: block !important;
            width: 100% !important;
            border-bottom: 1px solid #e8e8e8;
        }
    }
}

.form__inputText-w600px,
.form__supArea-w600px {
    width: 600px;
    box-sizing: border-box;
}

.form__inputText-w344px {
    width: 344px;
    box-sizing: border-box;
}

.form__customSelectWrap {
    &.form__select-w256px {
        width: 256px;
        box-sizing: border-box;
    }
}
