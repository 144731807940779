// member
$shadow4: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 1px 16px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(0, 0, 0, 0.08);

.company {
    &__mainWrap {
        width: 864px;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        // padding: 25px 0 15px 0;
        box-sizing: border-box;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &__main {
        width: 100%;
    }

    &__mainTitleArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
        margin-top: 8px;
    }

    &__mainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;

        &-num {
            display: inline-block;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin-left: 15px;
        }

        &-unit {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.014em;
        }
    }

    &__mainUl {
        width: 100%;
        list-style: none;
        // margin-top: 13px;
    }

    &__mainLi {
        display: flex;
        justify-content: space-between;
        color: #151208;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        border-radius: 8px;
        margin-bottom: 8px;
        transition-duration: 0.2s;
        background: #fff;

        &-gray {
            color: #8a8883;
        }

        &:nth-child(1) {
            background: transparent;
        }

        &:not(.company__mainLi-gray) {
            &:hover {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 6px 12px rgba(0, 0, 0, 0.08),
                    0px 1px 16px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08);
            }
        }
    }

    &__mainLiInner {
        display: flex;
        width: 100%;
    }

    &__mainId {
        width: 8%;
        padding: 20px 0 20px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainCompanyName {
        width: 26%;
        padding: 20px 0 20px 24px;
        display: flex;
        box-sizing: border-box;
        align-items: center;

        &-ais {
            align-items: flex-start;
        }
    }

    &__mainName {
        width: 16%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        justify-content: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainTel {
        width: 16%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    &__mainStatus {
        width: 22%;
        padding: 20px 0 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        span {
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                flex-grow: 0;
                margin-right: 12px;
            }
        }

        &-ac {
            &::before {
                border: 1.5px solid #52d276;
                background: #52d276;
            }
        }

        &-pause {
            &::before {
                border: 1.5px solid #b8b7b4;
                background: #b8b7b4;
            }
        }

        &-archive {
            &::before {
                border: 1.5px solid #8a8883;
                background: transparent;
            }
        }

        &-col {
            flex-flow: column;
            justify-content: center;
        }

        // &-gray{
        //         display: block;
        //         color: #8A8883;
        //         &::before{
        //             display: none;
        //         }

        // }

        span {
            &.member__mainStatus-gray {
                display: block;
                color: #8a8883;
                font-weight: 400;

                &::before {
                    display: none;
                }
            }
        }

        span {
            &.member__mainStatus-invite {
                &::before {
                    width: 17px;
                    height: 13px;
                    border-radius: 0;
                    background-image: url(../../img/mail.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        span {
            &.member__mainStatus-expired {
                color: #cd1818;

                &::before {
                    width: 18px;
                    height: 18px;
                    border-radius: 0;
                    background-image: url(../../img/mail2.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__mainCertification {
        width: 12%;
        padding: 20px 24px 20px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    &__mainDotArea {
        width: 48px;
        padding: 20px 16px 20px 0;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
    }

    &__mainDot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover {
            background: #fff4d1;
        }

        &.active {
            background: #fff4d1;
        }
    }

    &__mainDotText {
        width: 168px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // padding: 16px;
        border-radius: 8px;
        box-shadow: $shadow4;
        background: #fff;
        font-size: 14px;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 3;
        transform: translate(calc(-50% + 16px), 16px);
        margin: auto;
        white-space: nowrap;
        cursor: pointer;
        display: none;
    }

    &__mainDotBg {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        // background: #000;
        z-index: 2;
        display: none;
    }

    &-dpb {
        display: block;
    }

    &-pdb0 {
        padding-bottom: 8px;
    }

    &-pdt4 {
        padding-top: 4px;
    }
}

@media screen and (max-width: 768px) {
    .company {
        &__mainLi {
            flex-flow: column;
            border-radius: 0;
            margin-bottom: 2px;
            margin: 0 auto 1px auto;
            padding: 16px 4%;
            margin-top: 12px;

            &:nth-child(1) {
                display: none;
                margin-top: 0;
            }
        }

        &__mainTitleArea {
            padding: 0 16px 0 16px;
        }

        &__mainName {
            flex-flow: row;
            justify-content: flex-start;
        }

        &__mainId,
        &__mainCompanyName,
        &__mainName,
        &__mainTel,
        &__mainStatus,
        &__mainCertification {
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            align-items: flex-start;
            &::before {
                content: attr(data-title);
                color: #8a8883;
                width: 6em;
            }
        }

        &__mainTitle {
            font-size: 15px;
        }
    }
}
