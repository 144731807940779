// evaluation

$shadow1: 5px 5px black !default;
$shadow4: 5px 5px black !default;

.evaluation {
    width: 100%;
    background: #f5f4ef;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 100px;

    &__inner {
        width: 848px;
        max-width: 75%;

        padding: 24px 0 24px 24px;
        box-sizing: border-box;
    }

    &__leftCont {
        width: 824px;
        max-width: 100%;
    }

    &__leftHeaderDd {
        display: none;
    }

    &__leftHeader {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
    }

    &__headerId {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
    }

    &__headerBlueTags {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__headerBlueTag {
        background: #cdf4fd;
        border-radius: 4px;

        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        color: #368c9f;
    }

    &__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.001em;
        margin-top: 10px;
    }

    &__headerDlWrap {
        display: flex;
        margin-top: 10px;
    }

    &__headerDl {
        display: flex;
        flex-flow: row wrap;
    }

    &__headerDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 12px;
        margin-top: 5px;
    }

    &__headerDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-right: 30px;
        margin-top: 5px;
    }

    &__headerTagArea {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin-top: 10px;
    }

    &__headerTag {
        margin-top: 5px;
        margin-right: 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #fff;
        line-height: 24px;
        letter-spacing: 0.014em;
        background: #b28e14;
        border-radius: 4px;
        padding: 0 8px;
        box-sizing: border-box;
    }

    &__supArea {
        width: 100%;
        margin-top: 15px;
        padding: 20px 0 0 0;
        box-sizing: border-box;
        border-top: 1px solid #e8e8e8;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    &__supCorp {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;

        &-bunrui {
            display: block;
            color: #8a8883;
        }
    }

    &__supTimeArea {
        display: flex;
    }

    &__supTime {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
        margin-left: 17px;
    }

    &__leftMain {
        width: 100%;
        padding: 12px 24px 24px 24px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 16px;
        margin-top: 15px;
        position: relative;
    }

    &__separateLine {
        position: absolute;
        width: calc(100% - 48px);
        height: 1px;
        background: #e8e8e8;
    }

    &__leftMainHeader {
        display: flex;
        list-style: none;
        padding-bottom: 10px;
        position: relative;

        &::after {
            content: "";
            display: block;
            width: calc(100% + 48px);
            height: 1px;
            background: #E8E8E8;
            position: absolute;
            bottom: 0;
            left: -24px;
        }
    }

    &__leftMainHeaderItem {
        margin-right: 12px;

        &-disable {
            pointer-events: none;
        }
    }

    &__leftMainHeaderLink {
        display: block;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 1;
        padding: 0 20px;
        box-sizing: border-box;
        letter-spacing: 0.011em;
        border-radius: 20px;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;

            .evaluation__leftMainHeaderLink-num {
                color: #fff;
            }
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;

            .evaluation__leftMainHeaderLink-num {
                color: #fff;
            }
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            .evaluation__leftMainHeaderLink-num {
                color: #fff;
                background: #000;
            }
        }

        &:active {
            color: #8a8883;
            text-decoration: none;

            .evaluation__leftMainHeaderLink-num {
                color: #fff;
            }
        }

        &-num {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #8a8883;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #fff;
            line-height: 14px;
            letter-spacing: 0.011em;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            .evaluation__leftMainHeaderLink-num {
                color: #fff;
                background: #000;
            }

            &.evaluation__leftMainHeaderLink:link {
                color: #000;
            }

            &.evaluation__leftMainHeaderLink:visited {
                color: #000;
            }

            &.evaluation__leftMainHeaderLink:hover {
                color: #000;
            }

            &.evaluation__leftMainHeaderLink:active {
                color: #000;
            }
        }
    }

    &__leftMainCont {
    }

    &__leftMainFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 19px 0 24px 0;
    }

    &__leftMainTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
        margin-top: 25px;

        &-jobVacancyMainTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-num {
            margin-left: 1em;
        }

        &-mgt0 {
            margin-top: 0;
        }
    }

    &__leftMainTitleSup {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 10px;
    }

    &__leftEva {
        width: 100%;
        margin-top: 30px;
    }

    &__leftEvaHeader {
        display: flex;
        list-style: none;
        padding-bottom: 10px;
        position: relative;
    }

    &__leftEvaHeaderItem {
        margin-right: 12px;
    }

    &__leftEvaHeaderLink {
        display: block;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.011em;

        padding: 0 20px;
        box-sizing: border-box;
        letter-spacing: 0.011em;
        border-radius: 20px;
        transition-duration: 0.2s;

        &:link {
            color: #000;
            text-decoration: none;
        }

        &:visited {
            color: #000;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            .evaluation__leftEvaHeaderLinkImg-1 {
                fill: #000;
            }
            .evaluation__leftEvaHeaderLinkImg-2 {
                fill: #000;
            }
        }

        &:active {
            fill: #000;
        }

        &-before {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.014em;
            margin-right: 8px;
            display: flex;
            align-items: center;
        }

        &-mini {
            font-size: 14px;
            font-weight: normal;
        }

        &-active {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;

            .evaluation__leftEvaHeaderLinkImg-1 {
                fill: #000;
            }

            .evaluation__leftEvaHeaderLinkImg-2 {
                fill: #000;
            }
        }
    }

    &__leftEvaHeaderLinkImg {
        width: 20px;
        height: auto;
        fill-rule: evenodd;

        &-1 {
            fill: #069fcf;
        }

        &-2 {
            fill: #cd1818;
            fill-rule: evenodd;
        }
    }

    &__leftEvaUl {
        list-style: none;
        width: 609px;
        max-width: 90%;
    }

    &__leftEvaLi {
        width: 100%;
        background: #f8f7f5;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        margin-top: 12px;
        border-radius: 8px;
    }

    &__leftEvaLiIconArea {
        width: 64px;
        display: flex;
        flex-flow: column;

        &::before {
            content: '';
            display: block;
            width: 64px;
            height: 64px;
            background-color: #fff;
            border-radius: 50%;
            background-size: 33px 33px;
            background-position: center center;
            background-repeat: no-repeat;
            margin-bottom: 4px;
        }

        p {
            width: 65px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: #fff;
            line-height: 24px;
            letter-spacing: 0.014em;
            text-align: center;
            border-radius: 4px;
        }

        &-satis {
            p {
                background: #069fcf;
            }

            &::before {
                background-image: url(../../img/satisfaction-icon.svg);
            }
        }

        &-curious {
            p {
                background: #cd1818;
            }

            &::before {
                background-image: url(../../img/curious-icon.svg);
            }
        }
    }

    &__leftEvaLiTextArea {
        width: calc(100% - 64px);
        padding-left: 16px;
        box-sizing: border-box;
    }

    &__leftEvaLiText {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__leftEvaLiSupArea {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: 13px;
    }

    &__leftEvaLiSup {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__pagenation {
        width: 610px;
        max-width: 90%;
        margin-top: 23px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    &__pagenationPrev {
        width: 8.5px;
        height: 8.5px;
        border-top: 1px solid #b8b7b4;
        border-right: 1px solid #b8b7b4;
        transform: rotate(-135deg);
        margin-right: 14px;
    }

    &__pagenationNext {
        width: 8.5px;
        height: 8.5px;
        border-top: 1px solid #b8b7b4;
        border-right: 1px solid #b8b7b4;
        transform: rotate(45deg);
        margin-left: 7px;
    }

    &__pagenationNum {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 7px;
        transition-duration: 0.2s;

        &:link {
            color: #8a8883;
            text-decoration: none;
        }

        &:visited {
            color: #8a8883;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;
        }

        &:active {
            color: #8a8883;
            text-decoration: none;
        }

        &.current {
            color: #000;
            background: #ffcc1d;
            text-decoration: none;
        }
    }

    &__rightCont {
        width: 300px;
        max-width: 25%;
        padding: 24px;
        box-sizing: border-box;
    }

    &__rightContInner {
        width: 100%;
        padding: 23px;
        box-sizing: border-box;
        border-radius: 16px;
        background: #fff;
        position: sticky;
        top: 82px;
        left: 0;

        &-mgb16 {
            margin-bottom: 16px;
        }

        &-static {
            position: static;
        }
    }

    &__rightTitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
    }

    &__rightTitle2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.008em;
    }

    &__rightTitleSp {
        display: none;
    }

    &__rightStatus-underRecruitement {
        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #52d276;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-closed {
        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #b8b7b4;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-draft {
        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #ffcc1d;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-archived {
        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #52d276;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-closed {
        margin-top: 16px;

        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #b8b7b4;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-draft {
        margin-top: 16px;

        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #ffcc1d;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-archive {
        margin-top: 16px;

        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #8a8883;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus-max {
        margin-top: 16px;

        &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: #ff611d;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    &__rightStatus {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: flex;
        align-items: center;
        margin-top: 16px;
    }

    &__rightTitleSp {
    }

    &__rightDl {
    }

    &__rightDt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 13px;
    }

    &__rightDd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 5px;
    }

    &__jobPostingDl {
        display: flex;
        flex-flow: row wrap;
        margin-top: 15px;
        padding-bottom: 32px;
    }

    &__jobPostingDt {
        width: 13em;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 15px;
    }

    &__jobPostingDd {
        width: calc(100% - 13em);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 15px;
    }

    &__mediaInfoArea {
        width: 100%;
        max-width: 593px;
        padding: 24px;
        background: #f8f7f5;
        border-radius: 8px;
        margin-top: 24px;
        box-sizing: border-box;
        margin-bottom: 24px;
    }

    &__mediaInfoHead {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 16px;
    }

    &__headerInfo {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f4ef;
        padding-top: 16px;
    }

    &__headerInfoIconArea {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #b28e14;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__navSpanIcon {
        padding-right: 8px;
    }
}

// other job
.otherjob {
    &__ul {
        width: 610px;
        max-width: 90%;
        list-style: none;
        margin-top: 25px;
    }

    &__li {
        margin-top: 15px;
    }

    &__liLink {
        display: block;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        transition-duration: 0.2s;

        &:link {
            color: #151208;
            text-decoration: none;
        }

        &:visited {
            color: #151208;
            text-decoration: none;
        }

        &:hover {
            color: #151208;
            text-decoration: none;
            box-shadow: $shadow4;
        }

        &:active {
            color: #151208;
            text-decoration: none;
        }
    }

    &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.011em;
    }

    &__positionArea {
        margin-top: 7px;
        display: flex;
    }

    &__position {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 0 12px;
        box-sizing: border-box;
        background: #ffcc1d;
        border-radius: 16px;
    }

    &__dl {
        width: 100%;
        display: flex;
        margin-top: 10px;
    }

    &__dt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 5px;
        margin-right: 14px;
    }

    &__dd {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-top: 5px;
        margin-right: 29px;
    }

    &__tableWrap {
        padding: 15px 10px;
        box-sizing: border-box;
        background: #f8f7f5;
        margin-top: 15px;
    }

    &__table {
    }

    &__tr {
        &:nth-last-child(1) {
            .otherjob__th,
            .otherjob__td {
                padding-bottom: 0;
            }
        }
    }

    &__th,
    &__td {
        padding-bottom: 10px;
    }

    &__th {
        padding-right: 10px;
        //styleName: Jp - 14 Medium;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__td {
        padding-right: 30px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__pagination {
        margin-top: 23px;
    }
}

// corporateinfo

.corporateinfo {
    &__dl {
        padding: 26px 0 8px 0;
    }

    &__dt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 10px;
    }

    &__dd {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 25px;
        word-wrap: break-word;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }

    &__ddLink {
        transition-duration: 0.2s;
        word-wrap: break-word;

        &:link {
            color: #d7a600;
            text-decoration: none;
        }

        &:visited {
            color: #d7a600;
            text-decoration: none;
        }

        &:hover {
            color: #d7a600;
            text-decoration: none;
            opacity: 0.8;
        }

        &:active {
            color: #d7a600;
            text-decoration: none;
        }
    }
}

// Q&A
.qa {
    &__btn1 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        background: #ffcc1d;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: 18px;
        padding: 6px 24px 6px 20px;
        display: flex;
        align-items: center;
        border: none;
        transition-duration: 0.2s;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            background-image: url(../../img/plus-icon-bk.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }

        &:hover {
            box-shadow: none;
            background: #d7a600;
            // border: 1px solid #B28E14;
        }

        &:disabled {
            border: 1px solid #f8f7f5;
            background: #f8f7f5;
            box-sizing: border-box;
            color: #b8b7b4;
            cursor: default;
            box-shadow: none;
        }
    }

    &__cont {
        display: flex;
        justify-content: space-between;
    }

    &__side {
        width: 155px;
        max-width: 20%;
    }

    &__main {
        width: 610px;
        max-width: 80%;
    }

    &__sideInner {
        position: sticky;
        top: 82px;
        left: 0;
    }

    &__sideTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 20px;
    }

    &__ul {
        width: 100%;
        list-style: none;
    }

    &__li {
        width: 100%;
    }

    &__liBox {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        background: #f8f7f5;
        border-radius: 8px;
        margin-bottom: 12px;

        &-blue {
            background: #e0f6f3;
        }
    }

    &__liBoxHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    &__liBoxNameArea {
        display: flex;
        align-items: center;
    }

    &__liBoxImgArea {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #e1e1e1;
        overflow: hidden;
        margin-right: 12px;
        flex-shrink: 0;

        &-nbd {
            border: none;

            .qa__liBoxImg {
                object-fit: contain;
            }
        }
    }

    &__liBoxImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__liBoxName {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__liBoxIconArea {
        display: flex;
        align-items: center;
    }

    &__liBoxIconWrap {
        margin-left: 22px;
        cursor: pointer;
    }

    &__liBoxIcon {
        max-width: 100%;
        height: auto;
    }

    &__liBoxCatArea {
    }

    &__liBoxCatArea {
        display: flex;
        margin-bottom: 12px;
    }

    &__liBoxCat {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        padding: 0px 12px;
        background: #ffcc1d;
        border-radius: 12px;
        margin-right: 8px;
    }

    &__liBoxTextArea {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.014em;
        margin-bottom: 12px;
    }

    &__liBoxText {
        word-break: break-all;
    }

    &__liBoxSupArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__liBoxDate {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__liBoxFooter {
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid #e8e8e8;
        display: flex;
        align-items: center;

        &-space-between {
            justify-content: space-between;
        }
    }

    &__liBoxFooterRes {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #8a8883;
        line-height: 24px;
        letter-spacing: 0.014em;
    }

    &__childUlWrap {
        width: 100%;
        overflow: hidden;
        transition-duration: 0.2s;
    }

    &__childUl {
        width: 100%;
        list-style: none;
        padding-left: 20px;
        box-sizing: border-box;
    }

    &__childLi {
        width: 100%;
    }

    &__liBoxReply {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #151208;
        line-height: 24px;
        letter-spacing: 0.014em;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 17px;
            height: 17px;
            background-image: url(../../img/reply-icon.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }
    }

    &__childLiForm {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__childDropMenuWrap {
        text-align: center;
        margin: 20px auto;
    }

    &__childDropMenu {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #d7a600;
        line-height: 24px;
        letter-spacing: 0.014em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #d7a600;
            border-right: 1px solid #d7a600;
            transform: rotate(135deg);
            margin-right: 10px;
        }

        &.open {
            &::before {
                transform: rotate(-45deg);
                margin-top: 3px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .evaluation {
        flex-flow: column-reverse;
        padding-bottom: 52px;

        &__rightCont {
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            padding: 0;

            &-spReve {
                display: flex;
                flex-flow: column-reverse;
            }
        }

        &__rightContInner {
            width: 100%;
            padding: 0 4%;
            border-radius: 0;
            top: 0;
            border-bottom: 1px solid #E8E8E8;

            &-status {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-mgb16 {
                margin-bottom: 0;
            }

            &-mgb48 {
                margin-bottom: 0;
            }
        }

        &__rightStatus {
            margin-top: 0;
            white-space: nowrap;
        }

        &__inner {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }

        &__leftCont {
            width: 100%;
        }

        &__leftHeader {
            width: 100vw;
            border-radius: 0;
            padding: 16px;
        }

        &__leftMain {
            border-radius: 0;
            padding: 0;
        }

        &__leftMainHeader {
            // width: 100%;
            overflow-x: auto;
            padding: 10px 4% 11px 4%;
            position: sticky;
            top: 56px;
            left: 0;
            background: #fff;
        }

        &__mediaInfoArea {
            padding: 16px;
        }

        &__leftMainCont {
            width: 100vw;
            max-width: unset;
            padding: 0 16px 16px;
            box-sizing: border-box;

            &-spPdb {
                padding-bottom: 56px;
            }
        }

        &__leftMainHeaderItem {
            white-space: nowrap;
        }

        &__title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.008em;
        }

        &__supArea {
            flex-flow: row wrap;
        }

        &__supCorp {
            width: 100%;
        }

        &__supCorp-bunrui {
            display: inline-block;
            margin-right: 24px;
        }

        &__supTime {
            margin-left: 0;
            margin-right: 24px;
        }

        &__leftMainTitleSup {
            display: none;
        }

        &__jobPostingDl {
            display: block;
        }

        &__jobPostingDl {
            width: 100%;
        }

        &__jobPostingDt {
            width: 100%;
        }

        &__jobPostingDd {
            width: 100%;
            margin-top: 8px;
        }

        &__rightCont {
            // border-bottom: 1px solid #e8e8e8;
            order: 3;
        }

        &__rightTitle {
            display: none;
        }

        &__rightTitle2 {
            width: 100%;
            height: 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            background: #fff;
            // padding: 0 4%;
            box-sizing: border-box;
        }

        &__rightTitleSp {
            width: 100%;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.014em;
            background: #fff;
            // padding: 0 4%;
            box-sizing: border-box;

            &::after {
                content: "";
                width: 7px;
                height: 7px;
                border-top: 1px solid #151208;
                border-right: 1px solid #151208;
                transform: rotate(135deg);
                margin-top: 0;

                transition-duration: .2s;
            }

            &-open {
                &::after {
                    transform: rotate(-45deg);
                    margin-top: 5px;

                }
            }
        }

        &__rightDl {
            max-height: 0;
            overflow: hidden;
            transition-duration: .2s;
            padding: 0;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            &-open {
                padding: 0 0 24px 0;
                max-height: 1000px;
            }
        }

        &__fixBtn {
            position: fixed;
            bottom: 68px;
            right: 16px;
        }
    }
}

#evaluation {
    display: none;
    width: 100%;
    height: 100%;
    background-color: #f8f7f5;
    position: fixed;
    inset: 0;
    z-index: 11;
    overflow: auto;

    &.active {
        display: block;
    }

    .ev1 {
        max-width: 664px;
        min-height: 100vh;
        background-color: #ffffff;
        position: relative;

        &.evInput {
            display: none;
        }

        &.evComfirm {
            display: none;
            margin: 0 auto;
            max-width: unset;

            .ev {
                &__contArea {
                    max-width: 632px;
                    margin: 0 auto;
                }
            }
        }

        .ev {
            &__header {
                min-width: 100vw;
                padding: 12px 24px;
                border-bottom: 1px solid #e8e8e8;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 0;

                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
            }

            &__headerWrap {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            &__arrowImg {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    vertical-align: middle;
                }
            }

            &__crossImg {
                width: 32px;
                height: 32px;
                justify-content: center;
                align-items: center;
                display: none;
            }

            &__headerTitle {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.011em;
                color: #151208;
                margin-left: 12px;
            }

            &__contArea {
                padding-top: 58px;
            }

            &__contHeader {
                padding: 32px 32px 16px 32px;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.008em;
                color: #000000;
            }

            &__type {
                padding: 16px 32px 0 32px;
            }

            &__typeHead {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #151208;

                &-grey {
                    color: #8a8883;
                }
            }

            &__typeUl {
                margin-top: 16px;
            }

            &__typeLi {
                margin-top: 8px;
                display: flex;
                align-items: center;
            }

            &__typeInput {
                &[type='radio'] {
                    display: none;

                    &:checked + .ev__typeLabel::before {
                        border: 1px solid #d7a600;
                    }

                    &:checked + .ev__typeLabel::after {
                        opacity: 1;
                    }
                }
            }

            &__typeLabel {
                padding-left: 41px;
                position: relative;
                display: flex;
                align-items: center;

                &:before {
                    background: #fff;
                    border: 1px solid #8a8883;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    height: 16px;
                    left: 6px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                }

                &:after {
                    background: #d7a600;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    height: 10px;
                    left: 10px;
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 10px;
                }
            }

            &__typeWrap {
                display: flex;
                align-items: center;
            }

            &__typeText {
                margin-left: 8px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #151208;
            }

            &__comment {
                margin-top: 24px;
                padding: 0 32px 0 32px;
            }

            &__commentHead {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #151208;

                &-grey {
                    color: #8a8883;
                }
            }

            &__commentArea {
                margin-top: 16px;
                position: relative;
            }

            &__commentTextarea {
                max-width: 100%;
                min-width: 100%;
                min-height: 256px;
                padding: 6px 12px;
                border: 1px solid transparent;
                box-sizing: border-box;
                border-radius: 4px;
                background-color: #f5f4ef;

                &::placeholder {
                    color: #151208;
                }
            }

            &__commentTextareaP {
                word-break: break-all;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #151208;
                margin-top: 8px;
            }

            &__commentCount {
                display: flex;
                justify-content: flex-end;

                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: right;
                letter-spacing: 0.017em;
                color: #8a8883;
            }

            &__commentHint {
                position: absolute;
                top: 0;
                right: calc(-100% - 32px);
                transform: translateX(-50%);
                max-width: 332px;

                background-color: #50b8a8;
                padding: 24px;
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                    0px 1px 12px rgba(0, 0, 0, 0.06);
                border-radius: 8px;

                &-hide {
                    display: none;
                }
            }

            &__commentHintHead {
                display: flex;
                align-items: center;
            }

            &__commentHintHeadText {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #ffffff;
                margin-left: 8px;
            }

            &__commentHintBody {
                margin-top: 12px;
            }

            &__commentHintP {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #ffffff;
            }

            &__footer {
                display: flex;
                justify-content: flex-end;
                padding: 12px 32px;
                border-top: 1px solid #e8e8e8;
                background-color: #fff;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                box-sizing: border-box;
            }

            &__footerWrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 632px;
                margin: 0 auto;
                padding: 0 32px;
            }

            &__footerText {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #000000;
            }

            &__btns {
                display: flex;
            }

            &__btn {
                padding: 6px 24px;
                background-color: #ffcc1d;
                border: none;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.014em;
                color: #151208;

                display: flex;
                justify-content: center;
                align-items: center;

                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.06),
                    0px 1px 12px rgba(0, 0, 0, 0.06);
                border-radius: 9999px;
                margin-left: 12px;

                &:first-child {
                    margin-left: 0;
                }

                &:hover {
                    color: #000;
                    box-shadow: none;
                    background: #d7a600;
                }

                &:disabled {
                    color: #b8b7b4;
                    background: #f8f7f5;
                    box-shadow: none;
                }

                &-edit {
                    color: #d7a600;
                    background-color: #fff;
                    border: 1px solid #e1e1e1;
                    box-shadow: none;

                    &:hover {
                        color: #151208;
                        background-color: #ffcc1d;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 786px) {
        .ev1 {
            .ev {
                &__header {
                    box-sizing: border-box;
                    padding: 12px 8px;
                    position: fixed;
                }

                &__crossImg {
                    display: flex;
                }

                &__contHeader {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.014em;
                    color: #000000;
                    padding: 16px;
                }

                &__type {
                    padding: 8px 16px 0;
                }

                &__comment {
                    margin-top: 16px;
                    padding: 0 16px 77px;
                }

                &__footer {
                    padding: 12px 16px;
                    position: fixed;
                }

                &__commentHint {
                    padding: 16px;
                    right: revert;
                    left: 50%;
                    max-width: unset;
                    width: calc(100% - 0px);
                    box-sizing: border-box;
                    position: static;
                    transform: unset;
                    margin-top: 8px;
                }

                &__commentCount {
                    margin-top: 4px;
                }

                &__commentHintHead {
                    display: none;
                }

                &__commentHintBody {
                    margin-top: 0;
                }

                &__footerWrap {
                    padding: 0;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                &__footerText {
                    margin-bottom: 12px;
                }

                &__btns {
                    width: 100%;
                    justify-content: flex-end;
                }

                &__btn {
                    width: calc(50% - 8px);
                    box-sizing: border-box;
                }
            }
            
        }
    }
}
